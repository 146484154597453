import React from 'react';

const PageLoading = props => (
  <div
    style={{
      position: 'fixed',
      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      zIndex: 9999,
      justifyContent: 'center'
    }}>
    <div className={'container'}>
      <div className={'col-12'}>
        <div className={'row d-flex justify-content-center'}>
          <img
            src={'/images/ui/Rolling.svg'}
            alt={'loading deals'}
            style={{ width: 75, height: 75 }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default PageLoading;
