import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { Alert } from 'reactstrap';
import Button from '../../../../components/Stuff/Button';

import RenderField from '../../../../components/Fields/renderField';
import RenderSelect from '../../../../components/Fields/renderSelect';

import * as validations from '../../../../Validation';

const Page2 = props => {
  const { handleSubmit, prevPage } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="address1"
          type="text"
          component={RenderField}
          label="Address Line 1"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="address2"
          type="text"
          component={RenderField}
          label="Address Line 2"
        />
      </div>
      <div className="form-group">
        <Field
          name="city"
          type="text"
          component={RenderField}
          label="City"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="postcode"
          type="text"
          component={RenderField}
          label="Postcode"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="state-province-region"
          type="text"
          component={RenderField}
          label="County / Province"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="country"
          type="text"
          component={RenderField}
          label="Country"
          validate={validations.required}
        />
      </div>
      <div
        className="form-group button-container"
        style={{ justifyContent: 'space-between', display: 'flex' }}>
        <Button
          width={280}
          height={60}
          onClick={() => props.prevPage()}
          btnColor="rgba(0, 0, 0, 0.2)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="rgba(0,0,0,0.6)"
          fontSize={14}
          style={{ marginRight: 10 }}>
          <i className="fas fa-chevron-left" /> BACK
        </Button>
        <Button
          width={280}
          height={60}
          btnColor="rgba(77, 199, 117, 1)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="white"
          fontSize={14}
          style={{ marginLeft: 10 }}>
          NEXT <i className="fas fa-chevron-right" />
        </Button>
      </div>
      {!props.valid && props.submitFailed && (
        <Alert color="danger" className="form-errors">
          There are errors in your form. Please check you have completed all
          required fields.
        </Alert>
      )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page2);
