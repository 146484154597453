import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (
  <Switch>
    <Route path="/" />
    <Route path="/application-form" />
    <Route path="/faq" />
    <Route path="/about" />
    <Route path="/courses" />
    <Route path="/courses/:category" />
    <Route path={'/chartered-manager'} />
    <Route path={'/ba-hons'} />
    <Route path={'/elcas-scheme'} />
    <Route path={'/terms-and-conditions'} />
    <Route path="/courses/:category/:course" />
    <Route path="/courses/:category/:course/apply" />
    <Route path="/courses/:category/:course/unit/:unit" />
    <Route path="/success" />
    <Route path={'/application-success'} />
    <Route path="/contact-success" />
    <Route path="/testimonials" />
    <Route path="/fees-and-funding" />
    <Route path="/news" />
    <Route path="/news/:category" />
    <Route path="/news/article/:article" />
    <Route path="/contact" />
    <Route path="/privacy-policy" />

    <Route path="/login" />
    <Route path="/login/:hash" />

    <Route path="/dashboard" />
    <Route path={'/*'} />
  </Switch>
);
