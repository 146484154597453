import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { push } from 'connected-react-router';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Http from '../../constants/Http';
import ScriptTag from 'react-script-tag';

import Bubble from '../../components/Bubble';

import Button from '../../components/Stuff/Button';
import Accordion from '../../components/Stuff/Accordion';
import QandA from '../../components/QandA';
import BottomCircleBlue from '../../components/Circles/bottomCircleBlue';
import BottomContact from '../../components/BottomContact';
import Meta from '../../components/Meta';
import Apply from './Apply';
import Enquire from './Enquire';

import {
  handleCourseCategory,
  updateCourse,
  storeCourseNameApply
} from '../../modules/courses';

import './index.scss';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import PageLoading from '../../components/Loading/pageLoading';
import Breadcrumbs from '../../components/Breadcrumbs';
import SideInfo from './SideInfo';
import { RESET } from '../../modules/apply';

class Course extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      applicationOpen: false,
      enquireOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEnquire = this.toggleEnquire.bind(this);
  }

  componentWillMount() {
    this.getCourse();
  }

  toggle() {
    this.props.dispatch({
      type: RESET
    });
    this.setState({
      applicationOpen: !this.state.applicationOpen
    });
  }

  toggleEnquire = () => {
    this.props.dispatch({
      type: RESET
    });
    this.setState({
      enquireOpen: !this.state.enquireOpen
    });
  };

  setData = () => {
    document.getElementById('CourseDescriptionCourse').innerHTML =
      this.state.data.description;
    document.getElementById('KeyBenefitsCourse').innerHTML =
      this.state.data.key_benefits;
    document.getElementById('CourseAssessmentCourse').innerHTML =
      this.state.data.course_assessment;
    document.getElementById('StudySupportCourse').innerHTML =
      this.state.data.study_support;
    document.getElementById('CourseDeliveryCourse').innerHTML =
      this.state.data.course_delivery;
    document.getElementById('StudyTimeCourse').innerHTML =
      this.state.data.study_time;
  };
  getCourse = () => {
    Http.get(`/course/${this.props.match.params.course}`)
      .then(res => {
        this.setState({
          data: res.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        console.error(err);
        // window.location.replace('/courses');
      });
  };

  apply = () => {
    this.props.dispatch(storeCourseNameApply(this.state.data.course_name));
    this.props.dispatch(
      push(
        `/courses/${this.props.match.params.category}/${this.props.match.params.course}/apply`
      )
    );
  };

  render() {
    const { props } = this;

    if (this.state.isLoading) {
      return <PageLoading />;
    }

    return (
      <Page classnames="course-page">
        <Meta
          type={'website'}
          title={`${
            this.state.data.meta_title
              ? this.state.data.meta_title
              : `${this.state.data.course_name} | ${this.state.data.course_type.name}`
          }`}
          description={`${
            this.state.data.meta_description
              ? this.state.data.meta_description
              : `${this.state.data.sub_title}`
          }`}
        />
        <PageHeader>
          <Breadcrumbs
            sectors={[
              {
                name: 'Courses',
                link: '/courses'
              },
              {
                name: this.state.data
                  ? this.state.data.course_type.name.toUpperCase()
                  : '',
                link: '/courses/' + props.match.params.category
              },
              {
                name: this.state.data
                  ? this.state.data.course_name.toUpperCase()
                  : '',
                link: ''
              }
            ]}
          />

          <h1
            style={{
              marginBottom:
                this.state.data.sub_title !== null &&
                this.state.data.sub_title.length > 1
                  ? 9
                  : 39
            }}>
            {this.state.data.course_name}
          </h1>
          {this.state.data.sub_title !== null &&
            this.state.data.sub_title.length > 1 && (
              <h2>{this.state.data.sub_title}</h2>
            )}

          {/*<img*/}
          {/*src="/images/circles/topRightBlue.svg"*/}
          {/*height={550}*/}
          {/*className="main"*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
        </PageHeader>
        <section className="course-detail container">
          <div className="row">
            <SideInfo
              data={this.state.data}
              apply={this.toggle}
              enquire={this.toggleEnquire}
              category={this.props.match.params.category}
            />

            <div className="col order-lg-2" />
            <section className="col-12 col-lg-7 order-lg-1 course-main-content">
              <div className="content-block">
                <div className="title">Course Description</div>
                <div
                  id={'CourseDescriptionCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.description
                  }}
                />
                {this.props.match.params.course ===
                  'pearson-hnd-in-business' && (
                  <a
                    className="course-link"
                    href="/courses/ba-hons-top-up/ba-hons-business-top-up-online">
                    <span>
                      <i className="fas fa-eye" />
                    </span>{' '}
                    BA (Hons) Business Top-Up
                  </a>
                )}
                {this.props.match.params.course ===
                  'pearson-hnd-business-management' && (
                  <a
                    className="course-link"
                    href="/courses/ba-hons-top-up/ba-hons-business-top-up-online">
                    <span>
                      <i className="fas fa-eye" />
                    </span>{' '}
                    BA (Hons) Business Top-Up
                  </a>
                )}
                {this.props.match.params.course ===
                  'pearson-hnd-in-business-and-human-resources' && (
                  <a
                    className="course-link"
                    href="/courses/ba-hons-top-up/ba-hons-business-human-resource-management-top-up-online">
                    <span>
                      <i className="fas fa-eye" />
                    </span>{' '}
                    BA (Hons) Business (Human Resource Management) Top-Up
                  </a>
                )}
                {this.props.match.params.course ===
                  'pearson-hnd-in-business-accounting-and-finance' && (
                  <a
                    className="course-link"
                    href="/courses/ba-hons-top-up/ba-hons-business-finance-top-up-online">
                    <span>
                      <i className="fas fa-eye" />
                    </span>{' '}
                    BA (Hons) Business (Finance) Top-Up
                  </a>
                )}
                {this.props.match.params.course ===
                  'pearson-hnd-in-business-and-marketing' && (
                  <a
                    className="course-link"
                    href="/courses/ba-hons-top-up/ba-hons-business-marketing-top-up-online">
                    <span>
                      <i className="fas fa-eye" />
                    </span>{' '}
                    BA (Hons) Business (Marketing) Top-Up
                  </a>
                )}
              </div>
              <div className="content-block">
                <div className="title">Key Benefits</div>
                <div
                  id={'KeyBenefitsCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.key_benefits
                  }}
                />
              </div>
              <>
                {this.state.data.course_type.name === 'BA Hons Top-Up' ? (
                  <></>
                ) : (
                  <div>
                    <ScriptTag
                      isHydrating={true}
                      type="text/javascript"
                      src="https://bot.leadoo.com/bot/inpage.js?code=wb99O9DZ#seamless,noscroll"
                    />
                  </div>
                )}
              </>
              <div className="content-block">
                <div className="title">Module Overview</div>
                {window.location.pathname ===
                  '/courses/project-management/advanced-project-management/' && (
                  <p>
                    The advanced project management course is just one{' '}
                    <a href={'/courses/project-management/'}>
                      online project management course
                    </a>{' '}
                    of a range available with Brighton SBM. No matter whether
                    you are just starting our your project management career or
                    looking to enhance your skills we are sure to have a course
                    that will help you become an expert in your field.
                  </p>
                )}
                <Accordion
                  data={this.state.data}
                  match={this.props.match}
                  category={this.props.match.params.category}
                />
              </div>
              <div className="content-block">
                <div className="title">Course Assessment - All Levels</div>
                <div
                  id={'CourseAssessmentCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.course_assessment
                  }}
                />
              </div>

              <div className="content-block">
                <div className="title">Study Support - All Levels</div>
                <div
                  id={'StudySupportCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.study_support
                  }}
                />
              </div>

              <div className="content-block">
                <div className="title">Course Delivery - All Levels</div>
                <div
                  id={'CourseDeliveryCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.course_delivery
                  }}
                />
              </div>

              <div className="content-block">
                <div className="title">Required Study Time - All Levels</div>
                <div
                  id={'StudyTimeCourse'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.study_time
                  }}
                />
              </div>
            </section>
          </div>
        </section>
        <BottomCircleBlue>
          <QandA data={this.state.data && this.state.data.faqs} />
        </BottomCircleBlue>
        <BottomContact />
        <Modal
          isOpen={this.state.enquireOpen}
          toggle={this.toggleEnquire}
          centered
          size={'lg'}
          className={this.props.className}>
          <ModalBody>
            <Enquire
              courseName={this.state.data.course_name}
              courseSlug={this.props.match.params.course}
              hasSubmitted={this.props.apply.success}
              isSubmitting={this.props.apply.submitting}
              hasError={this.props.apply.error}
              awardTypes={this.state.data.award_types}
              toggle={this.toggleEnquire}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.applicationOpen}
          toggle={this.toggle}
          centered
          size="lg"
          className={this.props.className}>
          <ModalBody>
            <Apply
              courseName={this.state.data.course_name}
              courseSlug={this.props.match.params.course}
              hasSubmitted={this.props.apply.success}
              isSubmitting={this.props.apply.submitting}
              hasError={this.props.apply.error}
              toggle={this.toggle}
              awardTypes={this.state.data.award_types}
            />
          </ModalBody>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.courses.courseCategory,
    apply: state.apply
  };
};

export default connect(mapStateToProps)(Course);
