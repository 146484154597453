import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import CourseBucket from './CourseBucket';
import moment from 'moment';
import AutoSuggest from '../../components/Fields/autosuggest';
import Button from '../../components/Stuff/Button';
import QandA from '../../components/QandA';
import Meta from '../../components/Meta';
import Http from '../../constants/Http';
import { Collapse } from 'reactstrap';
import { Grid, Flex } from '@builtbypixel/plasma';
import dayjs from 'dayjs';
import { getCourses } from '../../modules/courses';

import './Home.scss';
import Bubble from '../../components/Bubble';

import isBetween from 'dayjs/plugin/isBetween';
import QandAHomepage from '../../components/QandAHomepage';
dayjs.extend(isBetween);

const showDiscount = () => {
  if (dayjs().isBetween('2023-11-13', dayjs('2023-12-21'))) {
    return true;
  } else {
    return false;
  }
};

class Home extends React.Component {
  state = {
    seoCourses: null,
    isLoading: false,
    accordionIsOpen: false
  };
  componentWillMount() {
    this.getSEOCourseData();
  }
  componentDidMount() {
    this.props.dispatch(getCourses());
  }

  toggleAccordion = () => {
    this.setState({
      accordionIsOpen: !this.state.accordionIsOpen
    });
  };

  getSEOCourseData = () => {
    this.setState({
      isLoading: true
    });
    Http.get(`/seo-links`)
      .then(res => {
        this.setState({
          seoCourses: res.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    return (
      <Page classnames="homepage">
        <Meta
          type={'website'}
          title="Business Courses Online | Distance Learning Business & Management Courses | UK"
          description="Brighten Your Future With Online Distance Learning Courses at Brighton School of Business and Management. To apply or to find out more about our online distance learning courses, including degrees, browse our website or complete our online enquiry form to request further information."
        />
        <PageHeader>
          <h1>Business Courses Online</h1>
          <h2>Improve your professional qualifications.</h2>
          <h2>
            Management, Leadership and Business online distance learning
            courses.
          </h2>
          <AutoSuggest suggestions={this.props.courses} />
          {showDiscount() && (
            <p>
              Quote <strong>Winter23</strong> to receive 10% off course fees for
              enrolments completed before 21st December 2023
            </p>
          )}
          <a className="allCoursesButton" href="/courses">
            or <span style={{ fontWeight: 700 }}>browse all courses</span>
          </a>

          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
          {moment().isBefore('2024-08-30') &&
            moment().isAfter('2024-07-15') && (
              <p style={{ fontSize: 22, marginTop: 20, fontWeight: 'bold' }}>
                Quote SUMMER24 to receive 10% off course fees
              </p>
            )}
          {moment().isBefore('2020-12-22') &&
            moment().isAfter('2020-11-16') && (
              <p style={{ fontSize: 22, marginTop: 20, fontWeight: 'bold' }}>
                10% off all course fees* – quote WINTER20 (*excludes
                registration fees)
              </p>
            )}
          {moment().isBefore('2021-01-04') &&
            moment().isAfter('2020-12-22') && (
              <p style={{ fontSize: 22, marginTop: 20, fontWeight: 'bold' }}>
                We are closed from 23rd December 2020 till 4th January 2021
              </p>
            )}
          {moment().isBefore('2022-12-19') &&
            moment().isAfter('2022-11-22') && (
              <p style={{ fontSize: 22, marginTop: 20 }}>
                Quote <span style={{ fontWeight: 'bold' }}>Winter22</span> to
                receive 10% off course fees for enrolments completed before 19th
                December 2022
              </p>
            )}
        </PageHeader>
        <div
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="5f32a8e6f15a1a0001626989"
          data-style-height="48px"
          data-style-width="100%"
          data-theme="light"
          data-min-review-count="10"
          data-style-alignment="center">
          <a
            href="https://uk.trustpilot.com/review/brightonsbm.com"
            target="_blank"
            rel="noopener">
            Trustpilot
          </a>
        </div>
        <div className="page-section">
          <div className="container">
            <h3 className="section-title">Course Categories</h3>
          </div>
          <div className="course-buckets container">
            <div className="row">
              <Grid
                w="100%"
                templateColumns={[
                  '1fr',
                  '1fr 1fr',
                  '1fr 1fr 1fr',
                  '1fr 1fr 1fr'
                ]}>
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Management &amp; Leadership Courses"
                  link="/courses/management-leadership"
                />
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Coaching & Mentoring Courses"
                  link="/courses/coaching-mentoring"
                />
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Professional Management Courses"
                  link="/courses/professional-management"
                />
                <CourseBucket
                  image="/images/logos/CMI_assessment_center.jpg"
                  name="Become a Chartered Manager"
                  link="/chartered-manager"
                />
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Professional Consultancy Courses"
                  link="/courses/professional-consultancy"
                />
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Project Management Courses"
                  link="/courses/project-management"
                />
                <CourseBucket
                  image="/images/courses/Pearson.svg"
                  name="HNC / HND Business Courses"
                  link="/courses/hnc-hnd"
                />
                <CourseBucket
                  image="/images/courses/arden.png"
                  name="BA (Hons) Top-Up"
                  link="/ba-hons"
                />
                <CourseBucket
                  image="/images/courses/ELCAS.png"
                  name="ELCAS Learning Provider"
                  link="/elcas-scheme"
                />
              </Grid>
            </div>
            <div className="button-container ">
              <a href="/courses">
                <Button
                  width={190}
                  height={60}
                  btnColor="rgba(77, 199, 117, 1)"
                  boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                  textColor="white"
                  fontSize={14}>
                  SEE ALL COURSES
                </Button>
              </a>
            </div>
          </div>
          {/*<img*/}
          {/*src="/images/circles/leftLightBlue.svg"*/}
          {/*height={830}*/}
          {/*style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <span className={'homepage left blue-15 clockwise-8'} />
        </div>

        <div className="page-section first-why">
          <div className="container">
            <h3 className="section-title" data-aos="fade-up">
              Why study with the Brighton School of Business and Management?
            </h3>
            <div className="row why-study">
              <div className="col-12 col-lg-5">
                <h5 className="section-heading">Study in your own time</h5>
                <p>Study in your own time and place, even whilst you work.</p>
                <h5 className="section-heading">Online materials</h5>
                <p>
                  Learning materials are available online anytime through either
                  Moodle, our Virtual Learning Environment (VLE), Management
                  Direct (CMI courses) or HN Global (HNC HND courses)
                </p>
                <h5 className="section-heading">Personal tutor</h5>
                <p>
                  You will be supported by a personal tutor who will give you
                  guidance about your course and assignments.
                </p>
                <h5 className="section-heading">Courses start every Monday</h5>
                <p>
                  Our courses are taken via online distance learning so you can
                  start your course immediately.
                </p>
              </div>
              <div className="col-lg-1 d-none d-lg-block" />
              <div className="col-lg-6" data-aos="fade-up">
                <img
                  src="./images/homepage/two.jpg"
                  alt="Brighton woman at pc"
                  className="img-fluid bubble-img"
                />
              </div>
            </div>
          </div>

          {/*<img*/}
          {/*src="/images/circles/rightLightBlue.svg"*/}
          {/*height={880}*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <span className={'homepage right blue-30 clockwise-8'} />
        </div>

        <div className="page-section second-why">
          <div className="container">
            <div className="row why-study">
              <div data-aos="fade-up" className="col d-none d-lg-block">
                <img
                  src="./images/homepage/one.jpg"
                  alt="Bright School of Business"
                  className="img-fluid bubble-img"
                />
              </div>
              <div className="col-lg-1 d-none d-lg-block" />
              <div className="col-12 col-lg-5">
                <p className="section-heading">Save money</p>
                <p>
                  With our courses you will save money on traditional college
                  and university fees.
                </p>
                <p className="section-heading">Payment plans available</p>
                <p>Take advantage of our flexible payment plans.</p>
                <h5 className="section-heading">Accredited qualifications</h5>
                <p>
                  Many of our courses lead to UK accredited qualifications that
                  have the same status as those studied at a college or
                  university.
                </p>
              </div>
            </div>
          </div>

          {/*<img*/}
          {/*src="/images/circles/leftLightBlue.svg"*/}
          {/*height={880}*/}
          {/*style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <span className={'homepage left blue-15 clockwise-8'} />
        </div>

        <div className="page-section join-over">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-lg-5">
                <h3 className="section-title text-left" data-aos="fade-up">
                  Join over 8000 successful students
                </h3>
                <p>
                  Over 8000 students have completed an online distance learning
                  course through Brighton School of Business and Management
                </p>
              </div>
              <div className="col d-none " />
              <div className="col-lg-6 d-lg-block d-none" data-aos="fade-up">
                <img
                  src="./images/homepage/test.jpg"
                  className="img-fluid"
                  alt="Brighton woman on bed"
                />
              </div>
            </div>
          </div>

          {/*<img*/}
          {/*src="/images/circles/rightDarkBlue.svg"*/}
          {/*height={700}*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <span className={'homepage right blue-30 clockwise-8'} />
        </div>
        <div className="page-section q-and-a">
          <QandAHomepage />
          <div className="container button-container">
            <a href={'/faq'}>
              <Button
                width={190}
                height={60}
                btnColor="rgba(77, 199, 117, 1)"
                boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                textColor="white"
                fontSize={14}>
                SEE MORE
              </Button>
            </a>

            <div className="col text-center spacer-100" data-aos="fade-up">
              <a
                className={`${props.dark ? 'cant-find' : 'text-white'}`}
                href="/contact"
                style={{ fontSize: 24 }}>
                Can’t find what you are looking for?{' '}
                <span style={{ fontWeight: 700 }}>Get in Touch</span>
              </a>
              <p
                className={`${props.dark ? 'cant-find' : 'text-white'}`}
                style={{ fontSize: 24 }}>
                Or give us a call on{' '}
                <span style={{ fontWeight: 700 }}>+44 (0)1273 704 463</span>
              </p>
            </div>
          </div>
          <img
            src="/images/circles/test.svg"
            style={{ position: 'absolute', top: 0, left: '50%', zIndex: -1 }}
          />
        </div>
        <div className="col text-center popular-courses" data-aos="fade-up">
          <strong onClick={this.toggleAccordion}>
            Our Popular Online Business Courses
          </strong>{' '}
          <i class={this.state.accordionIsOpen ? 'arrow up' : 'arrow down'}></i>
          <Collapse isOpen={this.state.accordionIsOpen}>
            {this.state.seoCourses &&
              this.state.seoCourses.map(parent => (
                <div className="course-group">
                  <h2 className="popular-heading">
                    <a href={`/courses/${parent.slug}`}>{parent.name}</a>
                  </h2>
                  {parent.courses &&
                    parent.courses.map(course => (
                      <h2 className="popular-child">
                        <a href={`/courses/${parent.slug}/${course.slug}`}>
                          {course.name}
                        </a>
                      </h2>
                    ))}
                </div>
              ))}
          </Collapse>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses.courses
});

export default connect(mapStateToProps)(Home);
