import React, { Component } from 'react';

import './index.scss';

class Bubble extends Component {
  render() {
    const { props } = this;
    const { speed, right, delay, backgroundColor, animations } = props;
    return (
      <div className={"bubble"} style={{ ...animations, "animationDuration": speed, right, 'animationDelay': delay, backgroundColor }}>
        &nbsp;
      </div>
    );
  }
}

export default Bubble;