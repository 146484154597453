import React, { PropTypes } from 'react';

import './Breadcrumb.scss';

const Link = props => (
  <a href={props.crumb.link} className="crumb">
    {props.crumb.name}
  </a>
);

const NonLink = props => <span className="crumb">{props.crumb.name}</span>;

const Breadcrumbs = props => (
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      {props.sectors.map((crumb, index) => (
        <li class="breadcrumb-item" aria-current="page">
          {crumb.link ? <Link crumb={crumb} /> : <NonLink crumb={crumb} />}
        </li>
      ))}
    </ol>
  </nav>
);

export default Breadcrumbs;
