import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field, initialize } from 'redux-form';
import { Button, Form, FormGroup } from 'reactstrap';
import moment from 'moment';
import { push } from 'connected-react-router';

import Http from '../../../constants/Http';

import PersonalDetails from './tabs/PersonalDetails';
import Address from './tabs/Address';
import Contact from './tabs/Contact';
import PaymentDetails from './tabs/PaymentDetails';
import Files from './tabs/Files';

class MyApplications extends React.Component {
  state = {
    value: 0,
    data: null
  };

  handleChange = value => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  
  onSubmit = values => {
    Http.put(`/account/applications/${this.props.match.params.id}`, values)
      .then(res => {
      
      })
      .catch(err => {
        console.error(err);
      })
  };
  
  render() {
    const { handleSubmit, paymentMethod } = this.props;
    return (
      <div className={'my-courses pb-spacer-100'}>
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb"
            style={{ backgroundColor: 'transparent', paddingLeft: 0 }}>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={'/dashboard/my-applications'}>My Applications</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              CMI Level 2 Team Leading
            </li>
          </ol>
        </nav>
        <h3 className={'page-heading'}>CMI Level 2 Team Leading</h3>
        <div className={'big-white-box pl-0 pr-0 pt-0 no-overflow'}>
          <ul className="nav nav-tabs nav-fill">
            <li className={'nav-item'}>
              <a
                className={`nav-link ${this.state.value === 0 && 'active'}`}
                onClick={() => this.handleChange(0)}>
                Personal Details
              </a>
            </li>
            <li className={'nav-item'}>
              <a
                className={`nav-link ${this.state.value === 1 && 'active'}`}
                onClick={() => this.handleChange(1)}>
                Address
              </a>
            </li>
            <li className={'nav-item'}>
              <a
                className={`nav-link ${this.state.value === 2 && 'active'}`}
                onClick={() => this.handleChange(2)}>
                Contact Details
              </a>
            </li>
            <li className={'nav-item'}>
              <a
                className={`nav-link ${this.state.value === 3 && 'active'}`}
                onClick={() => this.handleChange(3)}>
                Payment Details
              </a>
            </li>
            <li className={'nav-item'}>
              <a
                className={`nav-link ${this.state.value === 4 && 'active'}`}
                onClick={() => this.handleChange(4)}>
                Files
              </a>
            </li>
          </ul>
          <div className={'d-flex justify-content-center align-items-center'}>
            <div className={'col-8'}>
              <Form onSubmit={handleSubmit(this.onSubmit)}>
                {this.state.value === 0 && <PersonalDetails /> }
                {this.state.value === 1 && <Address /> }
                {this.state.value === 2 && <Contact />}
                {this.state.value === 3 && <PaymentDetails paymentMethod={paymentMethod} /> }
                {this.state.value === 4 && <Files /> }
              </Form>
            </div>
          </div>
        </div>
        <p
          className={'pt-3'}
          style={{ fontSize: 14, color: '#093E5F', fontWeight: 300 }}>
          Questions? We’re only an email away: support@brightonsbm.com
        </p>
      </div>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'applicationDetailsForm',
    destroyOnUnmount: false,
  })(MyApplications)
);
