import React from 'react';
import { reduxForm, Form, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import Button from '../../../../components/Stuff/Button';
import RenderField from '../../../../components/Fields/renderField';
import * as validations from '../../../../Validation';

const Page6 = props => {
  const { handleSubmit, prevPage, paymentMethods, payment_method, employer } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        {paymentMethods.length > 0 &&
          paymentMethods.map((m, i) => (
            <label className={`radio-wrapper ${payment_method === m.id && 'active'}`}>
              <div className="radio-containter">
                <Field
                  name="payment_method"
                  component="input"
                  type="radio"
                  value={`${m.id}`}
                  className="radio"
                  validate={validations.required}
                />
              </div>
              <div className="text">{m.name}</div>
            </label>
          ))}
      </div>
      <div className="form-group">
        <p>
          Please choose one option to specify who will be paying for the course*
        </p>

        <label className={`radio-wrapper ${employer === 'me' && 'active'}`}>
          <div className="radio-containter">
            <Field
              name="who-is-paying"
              component="input"
              type="radio"
              value="me"
              className="radio"
              validate={validations.required}
            />
          </div>
          <div className="text">I will be paying for my course</div>
        </label>
        <label className={`radio-wrapper ${employer === 'employer' && 'active'}`}>
          <div className="radio-containter">
            <Field
              name="who-is-paying"
              component="input"
              type="radio"
              value="employer"
              className="radio"
              validate={validations.required}
            />
          </div>
          <div className="text">My Employer will be paying</div>
        </label>
        <label className={`radio-wrapper ${employer === 'sponsor' && 'active'}`}>
          <div className="radio-containter">
            <Field
              name="who-is-paying"
              component="input"
              type="radio"
              value="sponsor"
              className="radio"
              validate={validations.required}
            />
          </div>
          <div className="text">My Sponsor will be paying</div>
        </label>
      </div>
  
      {props.employer !== undefined && props.employer !== 'me' ? (
        <div>
          <div className="form-group">
            <Field
              name={'sponsor.first_name'}
              component={RenderField}
              label={'First Name'}
              type={'text'}
              validate={validations.required}
            />
          </div>
          <div className="form-group">
            <Field
              name={'sponsor.last_name'}
              component={RenderField}
              label={'Last Name'}
              type={'text'}
              validate={validations.required}
            />
          </div>
          <div className="form-group">
            <Field
              name={'sponsor.sponsor_email'}
              component={RenderField}
              label={'Email'}
              type={'text'}
              validate={validations.required}
            />
          </div>
          {props.employer === 'employer' &&
          <>
            <div className="form-group">
              <Field
                name={'sponsor.company_name'}
                component={RenderField}
                label={'Company Name'}
                type={'text'}
                validate={validations.required}
              />
            </div>
            <div className="form-group">
              <Field
                name={'sponsor.authorising_name'}
                component={RenderField}
                label={'Authorising Name'}
                type={'text'}
                validate={validations.required}
              />
            </div>
            <div className="form-group">
              <Field
                name={'sponsor.company_address'}
                component={RenderField}
                label={'Company Address'}
                type={'text'}
                validate={validations.required}
              />
            </div>
            <div className="form-group">
              <Field
                name={'sponsor.company_postcode'}
                component={RenderField}
                label={'Company Postcode'}
                type={'text'}
                validate={validations.required}
              />
            </div>
          </>
          }
          <div className="form-group">
            <Field
              name={'sponsor.relationship'}
              component={RenderField}
              label={'Relationship'}
              type={'text'}
              validate={validations.required}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="form-group">
        <Field
          name="promo_code"
          type="text"
          component={RenderField}
          label="Promo Code"
        />
      </div>
      <div
        className="form-group"
        style={{ justifyContent: 'space-between', display: 'flex' }}>
        <Button
          width={280}
          height={60}
          onClick={() => props.prevPage()}
          btnColor="rgba(0, 0, 0, 0.2)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="rgba(0,0,0,0.6)"
          fontSize={14}>
          <i className="fas fa-chevron-left" /> BACK
        </Button>
        <Button
          width={280}
          height={60}
          btnColor="rgba(77, 199, 117, 1)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="white"
          fontSize={14}>
          NEXT <i className="fas fa-chevron-right" />
        </Button>
      </div>
      {!props.valid && props.submitFailed && (
        <Alert color="danger" className="form-errors">
          There are errors in your form. Please check you have completed all
          required fields.
        </Alert>
      )}
    </Form>
  );
};

const selector = formValueSelector('wizard');

const mapStateToProps = state => ({
  employer: selector(state, 'who-is-paying'),
  payment_method: selector(state, 'payment_method')
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Page6)
);
