import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Button } from 'reactstrap';

import renderField from '../../../../components/Fields/renderField';

const renderSelect = props => (
  <div>
    <select {...props.input}>{props.children}</select>
    {props.touched && props.error && <div className="error">{props.error}</div>}
  </div>
);

const Contact = props => (
  <div className={'mt-5'}>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Phone</Label>
      <Field name={'phone'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Mobile</Label>
      <Field name={'mobile'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Email</Label>
      <Field name={'email'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={"col-8 mt-4"}>
      <Button className={'btn btn-dashboard float-left mb-4'}>Update</Button>
    </FormGroup>
  </div>
);

export default Contact;
