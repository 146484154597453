import React from 'react';
import { Card } from 'reactstrap';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import Meta from '../../components/Meta';

import Button from '../../components/Stuff/Button';

import './index.scss';

class Testimonials extends React.Component {
  render() {
    return (
      <Page classnames="testimonials-page">
        <Meta
          title="Customer Testimonials"
          description="Read testimonials from our previous customers who have taken our online distance learning courses."
          type={"website"}
        />
        <PageHeader>
          <h1>Learn new skills, advance your career</h1>
        </PageHeader>
        <img
          src="/images/circles/topRightBlue.svg"
          height={400}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}
        />

        <section
          style={{
            position: 'relative',
            paddingBottom: 50,
            paddingTop: 50,
            height: 'auto',
            overflow: 'hidden'
          }}>
          <div className="container">
            <div className="row  justify-content-center align-items-center" />
            <div className="row">
              <div className="col-12 spacer-50">
                <div className="row">
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        The Tutor was very quick at coming back to me and the
                        feedback they gave on my assignment drafts was always
                        very useful. All guidance given on the assignment was
                        great and was beneficial to those who haven’t written an
                        assignment in a while. All of the information you need
                        to access the online systems such as Moodle was provided
                        straight away which meant you could just get stuck
                        straight in – I never had any problems with accessing
                        the resources I needed
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Natasha, UK</h5>
                      <h5>
                        Course: CMI Level 4 Certificate in Management and
                        Leadership
                      </h5>
                    </Card>
                  </div>
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        Made me focus on areas that I would probably not have
                        considered. It has broadened my knowledge base and
                        increased my critical thinking and evaluation. It has
                        helped by getting me to appreciate different
                        perspectives, theory and practical applications
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Jamie Ross, UK</h5>
                      <h5>
                        Course: CMI Level 7 Diploma in Professional Consulting
                      </h5>
                    </Card>
                  </div>
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        The logical sequence of the modules is helpful, as you
                        can refer to previous learning throughout the course.
                        This then all comes together for the final Project
                        Management module. I liked the way the course material
                        outcomes were numbered in the same sequence as to the
                        information you were required to provide in the brief –
                        very helpful.
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Lorraine, UK.</h5>
                      <h5>Course: Pearson HNC Business RQF</h5>
                    </Card>
                  </div>
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        The course itself and the area it covered was very
                        interesting and the knowledge gained during the studying
                        process will be definitely used within my organization.
                        I strongly believe that completion of this course and
                        eventually becoming a chartered manager will enable me
                        to be a better leader and will contribute to the overall
                        growth of the business. It is also worth mentioning that
                        the Tutor was very responsive in providing the feedback
                        following the submission of the assignment.
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Kinga, UK.</h5>
                      <h5>
                        Course: CMI Level 7 Diploma in Strategic Management and
                        Leadership
                      </h5>
                    </Card>
                  </div>
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        Overall the course was excellent for the knowledge I
                        needed to gain within my job role. I enjoyed my time
                        studying and may study another course in the future with
                        Brighton School of Business and Management. The
                        strengths of the course are: Study material, well
                        written and laid out making it easier to understand what
                        is expected in each assignment. Course tutor very
                        supportive too. Overall I have enjoyed my studies and
                        would highly recommend to others wishing to pursue a
                        course in this field.
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Nina, UK.</h5>
                      <h5>Course: HND Business and Marketing</h5>
                    </Card>
                  </div>
                  <div className="col-12 mt-4">
                    <Card body style={{ height: 'auto', padding: 40 }}>
                      <p style={{ fontSize: 16 }}>
                        The course allowed me to take classes regardless of
                        where I live or what my schedules was. Subject covered
                        are applicable to my work and allowed me to implement
                        some of the methodology learnt. ): I was a father during
                        the studies but I had an amazing support from the school
                        which I would not get it in other type of schools
                      </p>
                      <h5 style={{ fontWeight: 900 }}>Bruno, Spain.</h5>
                      <h5>
                        Course: Level 7 Extended Diploma in Strategic Management
                        and Leadership.
                      </h5>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/images/circles/test.svg"
            className="large-blue-circle"
            style={{
              position: 'absolute',
              bottom: '0px',
              left: '50%',
              zIndex: -1
            }}
          />
        </section>
      </Page>
    );
  }
}

export default Testimonials;
