import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import courses from './courses';
import contact from './contact';
import apply from './apply';
import auth from './auth';

export default combineReducers({
  form,
  courses,
  contact,
  apply,
  auth,
});
