import React, { Component } from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import './index.css';

class NavAbout extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  state = {
    isOpen: false,
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { isTop, desktop } = this.props;
    return (
      <Navbar expand="lg" className={`fixed-top ${isTop ? 'nav-solid' : desktop ? '' : 'nav-solid'}`}>
        <NavbarBrand><Link to="/"><img src="/images/Logo.svg" alt="" /></Link></NavbarBrand>
      </Navbar>
    );
  }
}


export default NavAbout;
