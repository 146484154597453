import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Button } from 'reactstrap';

import renderField from '../../../../components/Fields/renderField';

const renderSelect = props => (
  <div>
    <select {...props.input}>{props.children}</select>
    {props.touched && props.error && <div className="error">{props.error}</div>}
  </div>
);

const Address = props => (
  <div className={'mt-5'}>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Address 1</Label>
      <Field name={'address.line_one'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Address 2</Label>
      <Field name={'address.line_two'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Country</Label>
      <Field name={''} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>City</Label>
      <Field name={'address.city.address'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Postcode</Label>
      <Field name={'address.postcode'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={"col-8 mt-4"}>
      <Button className={'btn btn-dashboard float-left mb-4'}>Update</Button>
    </FormGroup>
  </div>
);

export default Address;
