import React from 'react';
import { Card, UncontrolledCollapse } from 'reactstrap';

import NavAbout from '../../components/Navbar/navAbout';
import BottomBlueCircle from '../../components/Circles/bottomCircleBlue';
import Button from '../../components/Stuff/Button';

import QandA from '../../components/QandA';
import { Link } from 'react-router-dom';
import NavMobile from '../../components/Navbar/navMobile';

class FAQ extends React.Component {
  state = {
    isTop: true,
    desktop: true
  };

  componentWillMount() {
    this.checkScroll();
    this.checkSize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this));
    window.addEventListener('scroll', this.checkScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize());
    window.removeEventListener('scroll', this.checkScroll());
  }

  checkScroll = () => {
    const scroll = window.scrollY > 100;
    if (scroll !== this.state.isTop) {
      this.setState({
        isTop: scroll
      });
    }
  };

  checkSize = () => {
    const width = window.innerWidth > 992;
    if (width !== this.state.desktop) {
      this.setState({
        desktop: width
      });
    }
  };
  render() {
    const { isTop, desktop } = this.state;
    return (
      <div className="aboutWrapper pageWrapper">
        {!!!desktop ? (
          <NavMobile isTop={isTop} desktop={desktop} />
        ) : (
            <NavAbout isTop={isTop} desktop={desktop} />
          )}
        <div className="container hero">
          <div className="row h-100 justify-content-center">
            <div className="col-8">
              <div className="d-flex text-center spacer-200">
                <h3>Learn new skills, advance your career</h3>
              </div>
            </div>
          </div>
        </div>
        <BottomBlueCircle>
          <div className="container h-100" style={{ marginTop: -100 }}>
            <div className="pt-5 q-a">
              <div className="row pt-5 justify-content-center spacer-100">
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage2"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          How are courses assessed?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage2">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            There are no examinations as all courses are
                            assessed by completion of an assignment per unit.
                            Upon enrolment each student is provided with a
                            timetable listing the dates for the expected
                            completion of each assignment.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage3"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          How do I determine which level of course I can apply
                          for?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage3">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            All course levels have different eligibility
                            criteria. Please refer to the individual course
                            information on the website to check whether you are
                            eligible for the course. Should you wish to discuss
                            this before applying then contact the enquiries team
                            via email at{' '}
                            <a href="mailto:enquiries@brightonsbm.com">
                              enquiries@brightonsbm.com
                            </a>{' '}
                            or call 01273 704463.
                          </p>
                          <p
                            className={'pl-3'}
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            If you complete an online application form, we will
                            contact you via email to confirm that you are
                            eligible for the course you have selected based upon
                            the details of your previous experience and
                            qualifications you have supplied.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage4"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          How do I decide what level and choice of units for a
                          CMI course?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage4">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            The course outlines describe the eligibility
                            criteria, the units that are appropriate for the
                            chosen level and also the number of credits required
                            to complete the CMI qualification. Please contact
                            the enquiries team via email at{' '}
                            <a href="mailto:enquiries@brightonsbm.com">
                              enquiries@brightonsbm.com
                            </a>{' '}
                            if you require any help in deciding on your unit
                            combination.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage5"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          How do I enrol with Brighton School of Business and
                          Management?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage5">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            To enrol, complete the online application form.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage6"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          What information do we require and how is this stored?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage6">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            BSBM requires you to provide personal information to
                            support your application and course administration.
                            This information is stored securely within GDPR
                            guidelines and not shared with any third party
                            except the accrediting body for your qualification
                            and your tutor.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage7"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          What are my payment options?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage7">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            You can purchase the course in full or by the interest free instalments option.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                {/*<div className="col-12 col-md-10 ml-auto mr-auto mt-4">*/}
                {/*<Card body>*/}
                {/*<div*/}
                {/*className="row align-items-center"*/}
                {/*id="faqpage8"*/}
                {/*style={{ cursor: 'pointer' }}>*/}
                {/*<div className="col-1">*/}
                {/*<p*/}
                {/*style={{*/}
                {/*fontSize: 32,*/}
                {/*fontWeight: 900,*/}
                {/*opacity: 0.25,*/}
                {/*marginBottom: 0,*/}
                {/*textAlign: 'right'*/}
                {/*}}>*/}
                {/*Q*/}
                {/*</p>*/}
                {/*</div>*/}
                {/*<div className="col">*/}
                {/*<h5*/}
                {/*className="text-left section-heading pl-3"*/}
                {/*style={{ fontSize: 16, marginBottom: 0 }}>*/}
                {/*Which organisation funds the loan?*/}
                {/*</h5>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<UncontrolledCollapse toggler="#faqpage8">*/}
                {/*<div className="row">*/}
                {/*<div className="col-1">*/}
                {/*<p*/}
                {/*style={{*/}
                {/*fontSize: 32,*/}
                {/*fontWeight: 900,*/}
                {/*opacity: 0.25,*/}
                {/*marginBottom: 0,*/}
                {/*textAlign: 'right'*/}
                {/*}}>*/}
                {/*A*/}
                {/*</p>*/}
                {/*</div>*/}
                {/*<div className="col">*/}
                {/*<p*/}
                {/*className="pl-3"*/}
                {/*style={{ fontSize: 16, marginBottom: 0 }}>*/}
                {/*Brighton School of Business and Management Ltd, in*/}
                {/*partnership with Deko, are able to offer you*/}
                {/*interest free loans as an alternative method of*/}
                {/*payment for all our courses and qualifications.*/}
                {/*Founded in 2008,*/}
                {/*</p>*/}
                {/*<p*/}
                {/*className="pl-3"*/}
                {/*style={{ fontSize: 16, marginBottom: 0 }}>*/}
                {/*Deko is the UK’s fastest growing provider of point*/}
                {/*of sale consumer finance and authorised and*/}
                {/*regulated by the Financial Conduct Authority (Firm*/}
                {/*Reference Number: 728646). Registered offices are at*/}
                {/*33 St Mary Axe, London, EC3A 8AG. For more*/}
                {/*information please refer to*/}
                {/*<a*/}
                {/*href="https://www.dekopay.com/support/"*/}
                {/*target="_blank">*/}
                {/*https://www.dekopay.com/support/*/}
                {/*</a>*/}
                {/*.*/}
                {/*</p>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</UncontrolledCollapse>*/}
                {/*</Card>*/}
                {/*</div>*/}
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage9"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          How do I pay my study fees?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage9">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            If applying for a course via the online application
                            form, you will be asked to select your chosen
                            payment method and be emailed an invoice. You can
                            pay by credit / debit card or bank transfer but we
                            do not accept personal cheques. Only company /
                            corporate cheques will be accepted.
                          </p>
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            If you choose to pay by instalments, you will need
                            to provide credit/debit card information so we can
                            set this up through our payment system. This will
                            need to be done before you can enrol and payments
                            will come out on 1st of each month, starting the
                            following month after enrolment
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                  <Card body>
                    <div
                      className="row align-items-center"
                      id="faqpage13"
                      style={{ cursor: 'pointer' }}>
                      <div className="col-1">
                        <p
                          style={{
                            fontSize: 32,
                            fontWeight: 900,
                            opacity: 0.25,
                            marginBottom: 0,
                            textAlign: 'right'
                          }}>
                          Q
                        </p>
                      </div>
                      <div className="col">
                        <h5
                          className="text-left section-heading pl-3"
                          style={{ fontSize: 16, marginBottom: 0 }}>
                          What support will I receive with my course?
                        </h5>
                      </div>
                    </div>
                    <UncontrolledCollapse toggler="#faqpage13">
                      <div className="row">
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: 32,
                              fontWeight: 900,
                              opacity: 0.25,
                              marginBottom: 0,
                              textAlign: 'right'
                            }}>
                            A
                          </p>
                        </div>
                        <div className="col">
                          <p
                            className="pl-3"
                            style={{ fontSize: 16, marginBottom: 0 }}>
                            Each student is allocated a personal tutor for the
                            duration of the course. Tutors can be contacted via
                            Moodle (Virtual Learning Environment) and telephone.
                            All students have 24-hour online access to Moodle.
                            CMI students have 24-hour online access to the CMI
                            learning resource, Management Direct. Student
                            Services can be contacted via email and telephone if
                            students have any problems, issues or concerns about
                            their course.
                          </p>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                </div>
                <div className="col-12 text-center spacer-150">
                  <Link
                    className={`${
                      this.props.dark ? 'cant-find' : 'text-white'
                      }`}
                    to="/contact"
                    style={{ fontSize: 24 }}>
                    Can’t find what you are looking for? Get in Touch
                  </Link>
                  <p
                    className={`${
                      this.props.dark ? 'cant-find' : 'text-white'
                      }`}
                    style={{ fontSize: 24 }}>
                    Or give us a call on 01273 704463
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BottomBlueCircle>
      </div>
    );
  }
}

export default FAQ;
