import React from 'react';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

const RenderSelect = (props) => {
  const { input, options, meta: { touched, error }, loadOptions } = props;
  return (
    <div className="input-row">
      <Select
        {...props}
        loadOptions={loadOptions}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        options={options}
      />
      {touched && error && <span className="error">{error}</span>}
    </div>
  );
};

export default RenderSelect;
