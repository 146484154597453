import React from 'react';
import { reduxForm, Form, Field, Fields } from 'redux-form';
import { Alert } from 'reactstrap';
import Button from '../../../components/Stuff/Button';

import RenderField from '../../../components/Fields/renderField';
import RenderSelect from '../../../components/Fields/renderSelect';
import RenderAllErrors from '../../../components/RenderAllErrors';
import * as validations from '../../../Validation';

const countryOptions = [];

const Page3 = props => {
  const { handleSubmit, prevPage } = props;

  const fieldNames = ['phone', 'mobile', 'email'];
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="phone"
          type="phone"
          component={RenderField}
          label="Phone Number *"
        />
      </div>
      <div className="form-group">
        <Field
          name="mobile"
          type="phone"
          component={RenderField}
          label="Mobile Phone *"
          validate={[validations.required, validations.phoneNumber]}
        />
      </div>
      <div className="form-group">
        <Field
          name="email"
          type="email"
          component={RenderField}
          label="Email *"
          validate={[validations.required, validations.email]}
        />
      </div>
      <div className="row">
        <div className="order-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            onClick={() => props.prevPage()}
            btnColor="rgba(0, 0, 0, 0.2)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="rgba(0,0,0,0.6)"
            fontSize={14}>
            <i className="fas fa-chevron-left" /> BACK
          </Button>
        </div>
        <div className="order-1 order-md-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={14}>
            NEXT <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
      {!props.valid && props.submitFailed && (
        <Fields names={fieldNames} component={RenderAllErrors} />
      )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page3);
