import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { push } from 'connected-react-router';
import moment from 'moment';

import Http from '../../../constants/Http';

class MyApplications extends React.Component {
  state = {
    data: null
  };
  
  componentDidMount() {
    Http.get(`/account/applications`)
      .then(res => {
        this.setState({
          data: res.data.data
        })
      })
      .catch(err => {
        console.error(err);
      })
  }
  
  render() {
    return (
      <div className={'my-courses pb-spacer-100'}>
        <h3 className={'page-heading'}>My Applications</h3>
        <div className={'big-white-box'}>
          <div
            className={'row justify-content-center table-brighton'}
            style={{ marginLeft: -30, marginRight: 0 }}>
            <div className={'col-12'}>
              <div className={'row pb-2 border-bottom'}>
                <div className={'col-4 col-md-3 table-heading'}>
                  Course Name
                </div>
                <div className={'col-3 col-md-2 table-heading'}>
                  Date Applied
                </div>
                <div className={'col col-md-2 table-heading'}>Status</div>
                <div className={'col-3 col-md-4 table-heading'}>&nbsp;</div>
              </div>
              {this.state.data !== null &&
                this.state.data.map((app, index) => (
                  <div className={`row table-item ${index % 2 === 0 && "dark"}`}>
                    <div className={'col-4 col-md-3'}>
                      <p>{app.course.course_name}</p>
                    </div>
                    <div className={'col-3 col-md-2'}>
                      <p>{moment(app.created_at.date).format('DD MMM YYYY')}</p>
                    </div>
                    <div className={'col col-md-2'}>
                      <div className={`${app.status.name === 'Approved' ? "pillSuccess" : 'pillPending'}`}>{app.status.name}</div>
                    </div>
                    <div className={'col-3 col-md-4'}>
                      <div className={"row"}>
                        {app.status.name === 'New' &&
                          <div className={"col"}>
                            <Button
                              onClick={() =>
                                this.props.dispatch(
                                  push(
                                    `/dashboard/my-applications/${app.id}`
                                  )
                                )
                              }
                              className={'btn btn-dashboard'}>
                              View
                            </Button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <p
          className={'pt-3'}
          style={{ fontSize: 14, color: '#093E5F', fontWeight: 300 }}>
          Questions? We’re only an email away: support@brightonsbm.com
        </p>
      </div>
    );
  }
}

export default connect()(MyApplications);
