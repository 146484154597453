import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { reduxForm, Field, initialize } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import Http from '../../../constants/Http';
import swal from 'sweetalert';

const inlineField = ({
                       label,
                       input,
                       type,
                       placeholder,
                       meta: { touched, error }
                     }) => (
  <div className={'row justify-content-center align-items-center'}>
    <div className={'col-12 col-md-4 mb-md-0 mb-3'}>
      <Label>{label}</Label>
    </div>
    <div className={'col-12 col-md-8'}>
      <input {...input} placeholder={placeholder} type={type} />
    </div>
    {touched && error && <span>{error}</span>}
  </div>
);


class MyAccount extends React.Component {
  state = {
    data: null
  };
  
  onSubmit = values => {
    Http.put(`/account/my-account/${values.id}`, values)
      .then(res => {
        swal({
          title: "Success",
          text: " Successfully updated your account",
          icon: "success"
        })
      })
      .catch(err => {
        console.error(err);
      })
  };
  
  componentDidMount() {
    Http.get('/account/my-account')
      .then(res => {
        this.setState({
          data: res.data.data.full_name
        });
        this.props.dispatch(initialize('myAccountForm', {
          id: res.data.data.id,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          email: res.data.data.email
        }))
      })
      .catch(err => {
      
      });
  }
  
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className={'my-account pb-spacer-100'}>
        <h3 className={'page-heading'}>My Account</h3>
        <div className={'big-white-box'}>
          <div className={'row justify-content-center'}>
            <div className={'col-5 text-center mb-4'}>
              {this.state.data !== null &&
                <Avatar name={this.state.data} size={75} round/>
              }
            </div>
            <div className={'col-8'}>
              <Form onSubmit={handleSubmit(this.onSubmit)}>
                <div className={"row justify-content-center"}>
                  <FormGroup className={"col-12"}>
                    <Field name={'first_name'} component={inlineField} type={"text"} label={"First Name"} />
                  </FormGroup>
                  <FormGroup className={"col-12"}>
                    <Field name={'last_name'} component={inlineField} type={"text"} label={"Last Name"} />
                  </FormGroup>
                  <FormGroup className={"col-12"}>
                    <Field name={'email'} component={inlineField} type={"email"} label={"Email"} />
                  </FormGroup>
                  <FormGroup className={"col-4 text-center"}>
                    <Button className={"btn btn-dashboard btn-block float-left"}>Update</Button>
                  </FormGroup>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <p className={"pt-3"} style={{ fontSize: 14, color: '#093E5F', fontWeight: 300 }}>Questions? We’re only an email away: support@brightonsbm.com</p>
        <p style={{ fontSize: 14, color: 'red', fontWeight: 300, float: 'right', marginTop: -40, cursor: 'pointer' }}>Close Your Account</p>
      </div>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'myAccountForm'
  })(MyAccount)
);
