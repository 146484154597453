import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Paypal from 'paypal-checkout';
import Page from '../../components/Page';
import EdgeCircle from '../../components/Circles/edgeCircle';

class PaymentPage extends Component {
  
  state = {
    amount: 100,
    env: window.location.hostname === 'localhost' ? "sandbox" : "production",
    client: {
      sandbox: 'ASEO2GrrATUry9xNy-VG8ycAiHaKtidAezPEk7AFCBPJLicPP79lVMUx1D8lJcG6c0Er88eVUmnNFH2P',
      production: 'AVHBrBvbBOyYE1SZx1gOaBthRI-_qipsxrHsbq1z4u8xfUNu_2428XV0MHtnumLu5G7eE4v8wZJ5dshm'
    },
    commit: true
  };
  
  payment = (data, actions) => {
    return actions.payment.create({
      transactions: [
        {
          amount: { total: this.state.amount, currency: 'GBP' }
        }
      ]
    });
  };
  
  onAuthorize = (data, actions) => {
    return actions.payment.execute().then(function(paymentData) {
      // Show a success page to the buyer
      console.log(paymentData);
      if (paymentData.state === "approved") {
        window.location.href = '/payment-success';
      }
    });
  };
  
  render() {
    let PayPalButton = Paypal.Button.driver('react', { React, ReactDOM });
    return (
      <Page classnames="payment-page">
        <img
          src="/images/circles/topRightBlue.svg"
          height={250}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
        />
        <img
          src="/images/circles/leftLightBlue.svg"
          height={300}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
        />
        <div className="container mt-5 pt-5">
          <div className="row d-flex justify-content-center mt-5 pt-5">
            <div className="col-12 text-center">
              <h3>Pay Brighton</h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12 col-md-6">
              <div className="row d-flex justify-content-center" style={{
                background: 'rgba(9,62,95,0.06)'
              }}>
                <div className="col-10 pt-5">
                  <label>Amount To Pay ( £ )</label>
                  <input type={"number"} className={"form-control"} value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                </div>
                <div className="col-12 justify-content-center d-flex pt-4 pb-5">
                  <PayPalButton
                    commit={this.state.commit}
                    env={this.state.env}
                    client={this.state.client}
                    payment={(data, actions) => this.payment(data, actions)}
                    onAuthorize={(data, actions) => this.onAuthorize(data, actions)}
                  />
                </div>
              </div>
              <div className="row d-flex-justify-content-center mt-4 text-center">
                <div className="col-12 text-center">
                  <i className={"fas fa-lock mr-2 pt-1"} /> Secured by PayPal
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default PaymentPage;
