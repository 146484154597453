import React, { PropTypes } from 'react';
import { Helmet } from 'react-helmet';

const Meta = props => (
  <Helmet titleTemplate="%s">
    <meta charSet="utf-8" />
    <title>{props.title}</title>
    <meta name="description" content={props.description} />
    <link
      rel="canonical"
      href={`https://www.brightonsbm.com${window.location.pathname}`}
    />
    <meta
      property="og:url"
      content={`https://www.brightonsbm.com${window.location.pathname}`}
    />
    <meta property="og:type" content={props.type} />
    <meta name="twitter:card" content={'summary'} />
    <meta property="og:title" content={props.title} />
    <meta name="twitter:title" content={props.title} />
    <meta property="og:description" content={props.description} />
    <meta name="twitter:description" content={props.description} />
  </Helmet>
);

export default Meta;
