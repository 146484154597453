import React from 'react';

const BottomCircleBlue = props => (
  <div
    className="h-100"
    style={{
    background: 'url("/images/circles/test.svg") no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundPositionY: 0,
    paddingBottom: 125,
}}>
    {props.children}
  </div>
);

export default BottomCircleBlue;
