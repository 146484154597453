import { push } from 'react-router-redux';
import { reset } from 'redux-form'
import Http from '../constants/Http';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
};

export const submitContact = values => (dispatch) => {
  Http.post('/contact', values)
    .then(res => {
      dispatch(push('/contact-success'));
      dispatch(reset('contactForm'))
    })
    .catch(err => {
      console.error(err);
    });
};

export const emailSignup = values => (dispatch) => {
  console.log(values);
  Http.post('https://gbscorporate.us20.list-manage.com/subscribe/post?u=83dedf655d85b38f7cfdc3184&amp;id=d2ec9efda5', {
    status: "subscribed",
    email: values.email
  })
};
