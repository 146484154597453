import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, Form } from 'redux-form';
import { push } from 'react-router-redux';
import { submitContact } from '../../modules/contact';

import './index.scss';
import * as validations from '../../Validation';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Meta from '../../components/Meta';
import Bubble from '../../components/Bubble';
import RenderSelect from '../../components/Fields/renderSelect';

const sourceOptions = [
  {
    value: 1,
    label: 'Reed'
  },
  {
    value: 4,
    label: 'Twitter'
  },
  {
    value: 6,
    label: 'Google'
  },
  {
    value: 3,
    label: 'Facebook'
  },
  {
    value: 10,
    label: 'CoursesOnline'
  },
  {
    value: 5,
    label: 'LinkedIn'
  },
  {
    value: 7,
    label: 'Friend referral'
  },
  {
    value: 8,
    label: 'GBS blog'
  },
  {
    value: 9,
    label: 'Other'
  }
];

const renderTextArea = field => (
  <div className={'input-row'}>
    <textarea
      {...field.input}
      placeholder={field.placeholder}
      className={field.meta.touched && field.meta.error && 'error-input'}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

const renderField = field => (
  <div className="input-row">
    <input
      {...field.input}
      type="text"
      placeholder={field.placeholder}
      className={field.meta.touched && field.meta.error && 'error-input'}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

const renderSelect = props => (
  (
    <div>
      <select {...props.input} className={'contactReason'}>
        {props.children}
      </select>
      {props.touched && props.error && (
        <div className="error">{props.error}</div>
      )}
    </div>
  )
);

class Contact extends React.Component {
  state = {
    loading: false,
  };

  submit = values => {
    this.setState({
      loading: true
    });
    this.props.dispatch(submitContact(values));
  };

  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    return (
      (
        <Page classnames="contact-page">
          <Meta type={"website"} title="Contact Us | Brighton School of Business Management" description="Science Park Square, University of Sussex Campus, Brighton, East Sussex, BN1 9SB, United Kingdom Call: +44 (0)1273 704 463 Email: enquiries@brightonsbm.com" />
          <PageHeader>
            <h1>How can we help you?</h1>
            <h2>Choose from the following options</h2>

            {/*<img*/}
            {/*src="/images/circles/topRightBlue.svg"*/}
            {/*height={700}*/}
            {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
            {/*/>*/}
            <div className={"d-none d-lg-inline"}>
              <Bubble speed={"17s"} right={100} widthHeight={500} delay={"-8s"} backgroundColor={"#0887E0"} animations={{
                'WebkitAnimation': 'move-up 15s linear infinite',
                'MozAnimation': 'move-up 15s linear infinite',
                'msAnimation': 'move-up 15s linear infinite',
                'OAnimation': 'move-up 15s linear infinite',
                'animation': 'move-up 15s linear infinite'
              }} />
              <Bubble speed={"8s"} right={150} widthHeight={500} delay={"-10s"} backgroundColor={"#93CCFD"} animations={{
                'WebkitAnimation': 'move-up 15s linear infinite',
                'MozAnimation': 'move-up 15s linear infinite',
                'msAnimation': 'move-up 15s linear infinite',
                'OAnimation': 'move-up 15s linear infinite',
                'animation': 'move-up 15s linear infinite'
              }} />
              <Bubble speed={"17s"} right={200} widthHeight={300} delay={"-13s"} backgroundColor={"#50DCF6"} animations={{
                'WebkitAnimation': 'move-up-right 15s linear infinite',
                'MozAnimation': 'move-up-right 15s linear infinite',
                'msAnimation': 'move-up-right 15s linear infinite',
                'OAnimation': 'move-up-right 15s linear infinite',
                'animation': 'move-up-right 15s linear infinite'
              }} />
              <Bubble speed={"17s"} right={100} widthHeight={200} delay={"-3s"} backgroundColor={"#4097F8"} animations={{
                'WebkitAnimation': 'move-up-left 15s linear infinite',
                'MozAnimation': 'move-up-left 15s linear infinite',
                'msAnimation': 'move-up-left 15s linear infinite',
                'OAnimation': 'move-up-left 15s linear infinite',
                'animation': 'move-up-left 15s linear infinite'
              }} />
            </div>
            <div className={"d-lg-none"}>
              <span className={"homepage topRight blue-15 twoThirds"} />
            </div>
          </PageHeader>
          <span className={"homepage left blue-15 clockwise-8 topThird d-none d-md-inline"} />
          <div className={'container'}>
            <div className="row justify-content-center align-items-center">
              <div className={'col-12'}>
                <Form onSubmit={handleSubmit(this.submit)}>
                  <div
                    className={'row justify-content-center'}>
                    <div className="col-12 col-md-6 text-center">
                      <Field
                        name="contactReason"
                        className={'contactReason'}
                        component={renderSelect}
                        type={'select'}
                        validate={validations.required}>
                        <option value={10}>General enquiry</option>
                        <option value={0}>
                          Do I need to attend any classes as part of my course?
                        </option>
                        <option value={1}>How are courses assessed?</option>
                        <option value={2}>
                          How do I determine which level of course I can apply
                          for?
                        </option>
                        <option value={3}>
                          How do I decide what level I need for a CMI course?
                        </option>
                        <option value={4}>
                          How do I enrol with Brighton School of Business and
                          Management?
                        </option>
                        <option value={5}>
                          What information do we require and how is this stored?
                        </option>
                        <option value={6}>What are my payment options?</option>
                        <option value={7}>How do I pay my study fees?</option>
                        <option value={8}>
                          What support will I receive with my course?
                        </option>
                        <option value={9}>When can I start?</option>
                      </Field>
                    </div>
                    {this.props.contactReason === '0' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          No classroom attendance is required. All our courses
                          are online self-study distance learning courses. All
                          you need is a computer and internet connection.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '1' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          There are no examinations as all courses are assessed
                          by completion of an assignment per unit. Upon
                          enrolment each student is provided with a timetable
                          listing the dates for the expected completion of each
                          assignment and which are also listed in Moodle
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '2' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          All course levels have different eligibility criteria.
                          Please refer to the individual course information on
                          the website to check whether you are eligible for the
                          course. Should you wish to discuss this before
                          applying then contact the enquiries team via email at&nbsp;
                          <a href="mailto:enquiries@brightonsbm.com">
                            enquiries@brightonsbm.com
                          </a>{' '}
                          or call 01273 704463.
                        </p>
                        <p>
                          If you complete an online application form, we will
                          contact you via email to confirm that you are eligible
                          for the course you have selected based upon the
                          details of your previous experience and qualifications
                          you have supplied.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '3' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          The course outlines describe the eligibility criteria, the
                          units that are appropriate for the chosen level and also the number of credits required to complete the CMI
                          qualification. Please contact the enquiries team via email at {' '}
                          <a href="mailto:enquiries@brightonsbm.com">
                            enquiries@brightonsbm.com
                          </a>{' '} if you require any
                          help in deciding which level you need.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '4' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          To enrol, complete the online application form and
                          upload the identification information via our request
                          email
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '5' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          BSBM requires you to provide personal information to
                          support your application and course administration.
                          This information is stored securely within GDPR
                          guidelines and not shared with any third party except
                          the accrediting body for your qualification and your
                          Tutor.
                        </p>
                        <p>
                          You can review the information we hold at any time by
                          emailing us requesting this information.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '6' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          You can purchase the course in full or by interest free instalments option.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '7' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          If applying for a course via the online application form, you will be asked to select your chosen payment
                          method and be emailed an invoice. You can pay by credit / debit card or bank transfer but we do not accept
                          personal cheques. Only company / corporate cheques will be accepted.
                        </p>
                        <p>
                          If you choose to pay by instalments, you will need to provide credit/debit card information so we can set this
                          up through our payment system. This will need to be completed before you enrol and payments will come out
                          on 1st of each month.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '8' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          Each student is allocated a personal Tutor for the
                          duration of the course. Tutors can be contacted via
                          Moodle (Virtual Learning Environment) and telephone.
                        </p>
                        <p>Students have 24-hour online access to Moodle</p>
                        <p>
                          CMI Students have 24-hour online access to the CMI
                          learning resource, Management Direct
                        </p>
                        <p>
                          Student Services can be contacted via email, Moodle,
                          telephone and social media if students have any
                          problems, issues or concerns about their course.
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '9' && (
                      <div className={'col-12 col-md-8'}>
                        <p>
                          You can start your online course on any Monday
                          throughout the year (excluding Bank Holidays).
                        </p>
                      </div>
                    )}

                    {this.props.contactReason === '10' && (
                      <React.Fragment>
                        <div className={'col-12'}>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 ">
                              <p>Thanks for stopping by</p>
                            </div>
                          </div>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 spacer-50">
                              <Field
                                name="first_name"
                                component={renderField}
                                type="text"
                                placeholder="First Name"
                                validate={validations.required}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 spacer-25">
                              <Field
                                name="last_name"
                                component={renderField}
                                type="text"
                                placeholder="Last Name"
                                validate={validations.required}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 spacer-25">
                              <Field
                                name="phone"
                                component={renderField}
                                type="phone"
                                placeholder="Phone Number"
                                validate={[validations.required, validations.phoneNumber]}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 spacer-25">
                              <Field
                                name="email"
                                component={renderField}
                                type="email"
                                placeholder="Email"
                                validate={[
                                  validations.email,
                                  validations.required
                                ]}
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <div className={"col-12 col-md-6 spacer-25 react-select-contact"}>
                              <Field
                                name="source"
                                label="Where did you hear about us?"
                                placeholder={"Where did you hear about us?"}
                                component={RenderSelect}
                                options={sourceOptions}
                                validate={validations.required}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              'row justify-content-center align-items-center'
                            }>
                            <div className="col-12 col-md-6 spacer-25">
                              <Field
                                name="additional_info"
                                component={renderTextArea}
                                placeholder={"Write your enquiry here"}
                                type="textarea"
                                validate={validations.required}
                              />
                            </div>
                          </div>
                        </div>
                        {error && (
                          <strong className="text-danger">{error}</strong>
                        )}
                        <div className={'col-12'}>
                          <button
                            className="spacer-50"
                            style={{
                              width: 280,
                              height: 60,
                              letterSpacing: 0,
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 3,
                              border: 'none',
                              backgroundColor: 'rgba(77, 199, 117, 1)',
                              fontSize: 14,
                              boxShadow: '0 20px 24px 0 rgba(77,199,117,0.20)',
                              color: 'white'
                            }}
                            type="submit"
                            disabled={pristine || submitting}>
                            {this.state.loading ? 'Submitting...' : 'SUBMIT'}
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                    <div className={"mb-5 mt-5 pt-5 pb-5"}>&nbsp;</div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Page>
      )
    );
  }
}

const selector = formValueSelector('contactForm');

const mapStateToProps = state => ({
  contactReason: selector(state, 'contactReason'),
  initialValues: {
    contactReason: '10'
  }
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'contactForm'
  })(Contact)
);
