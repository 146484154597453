import { history } from '../store';
import Http from '../constants/Http';

export const UPDATE_COURSE_CATEGORY = 'courses/UPDATE_COURSE_CATEGORY';
export const UPDATE_COURSE_LEVEL = 'courses/UPDATE_COURSE_LEVEL';
export const GET_COURSES = 'courses/GET_COURSES';
export const GET_COURSES_IN_CATEGORY = 'courses/GET_COURSES_IN_CATEGORY';
export const GET_COURSE_CATEGORYS = 'courses/GET_COURSE_CATEGORYS';
export const GET_COURSE_LEVELS = 'courses/GET_COURSE_LEVELS';
export const UPDATE_COURSES_TO_SHOW = 'courses/UPDATE_COURSES_TO_SHOW';
export const STORE_COURSE_NAME = 'courses/STORE_COURSE_NAME';
export const START_LOADING = 'courses/START_LOADING';
export const FINISH_LOADING = 'courses/FINISH_LOADING';
export const UPDATE_AWARD_TYPES = 'courses/UPDATE_AWARD_TYPES';

const initialState = {
  courseCategory: null,
  courseLevel: null,
  courses: null,
  coursesToShow: null,
  courseLevels: [],
  courseCategorys: [],
  storedCourseName: null,
  isLoading: false,
  awardType: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AWARD_TYPES: {
      return {
        ...state,
        awardType: action.payload
      }
    }
    case START_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FINISH_LOADING: {
      return {
        ...state,
        isLoading: false
      };
    }
    case STORE_COURSE_NAME: {
      return {
        ...state,
        storedCourseName: action.payload
      };
    }
    case UPDATE_COURSES_TO_SHOW: {
      return {
        ...state,
        coursesToShow: {
          ...state.coursesToShow,
          data: action.payload
        }
      };
    }
    case GET_COURSE_CATEGORYS: {
      return {
        ...state,
        courseCategorys: action.payload
      };
    }
    case GET_COURSE_LEVELS: {
      return {
        ...state,
        courseLevels: action.payload
      };
    }
    case GET_COURSES: {
      return {
        ...state,
        courses: action.payload,
        coursesToShow: action.payload
      };
    }
    case GET_COURSES_IN_CATEGORY: {
      return {
        ...state,
        courses: action.payload,
        coursesToShow: action.payload,
        isLoading: false
      };
    }
    case UPDATE_COURSE_CATEGORY:
      return {
        ...state,
        courseCategory: action.payload,
        courseLevels: action.levels
      };
    case UPDATE_COURSE_LEVEL:
      return {
        ...state,
        courseLevel: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export const storeCourseNameApply = value => dispatch => {
  dispatch({
    type: STORE_COURSE_NAME,
    payload: value
  });
};

export const updateCourse = value => dispatch => {
  dispatch({
    type: UPDATE_COURSE_CATEGORY,
    payload: value
  });
  history.push('/courses');
  window.scrollTo(0, 0);
};

export const coursesToDisplay = category => (dispatch, getState) => {
  const allCourses = getState().courses.courses;

  if (category === undefined || category === null) {
    category = getState().courses.courseCategory;
  }

  const level = getState().courses.courseLevel;

  const filteredCourses = allCourses.data.filter((c, i) => {
    if (level !== null && category !== null) {
      return (
        c.course_level.name === level.name &&
        c.course_type.name === category.name
      );
    }
    if (level !== null && category === null) {
      return c.course_level.name === level.name;
    }
    if (level === null && category !== null) {
      return c.course_type.name === category.name;
    }
    if (level === null && category === null) {
      return 'No Courses Found Matching That Criteria';
    }
  });

  dispatch({
    type: UPDATE_COURSES_TO_SHOW,
    payload: filteredCourses
  });
};

export const handleCourseCategory = value => dispatch => {
  if (value !== null) {
    Http.post('/categories/search', {
      course_type: {
        id: value.id
      }
    })
      .then(res => {
        dispatch({
          type: UPDATE_COURSE_CATEGORY,
          payload: value,
          levels: res.data.data
        });
        dispatch({
          type: UPDATE_COURSE_LEVEL,
          payload: null
        });
        dispatch(coursesToDisplay(value));
      })
      .catch(err => {
        console.error(err);
      });
  } else {
    dispatch({
      type: UPDATE_COURSE_CATEGORY,
      payload: null
    });
  }
};

export const handleCourseLevel = value => dispatch => {
  if (value !== null) {
    dispatch({
      type: UPDATE_COURSE_LEVEL,
      payload: value
    });
  } else {
    dispatch({
      type: UPDATE_COURSE_LEVEL,
      payload: null
    });
  }
  dispatch(coursesToDisplay());
};

export const getCourses = () => dispatch => new Promise((resolve) => {
  Http.get('/courses')
    .then(res => {
      dispatch({
        type: GET_COURSES,
        payload: res.data
      });
      window.setTimeout(() => {
        window.prerenderReady = true;
      }, 10000);
      resolve(res.data)
    })
    .catch(err => {
      console.error(err);
    });
});

export const getCoursesInCategory = category => dispatch => {
  dispatch({
    type: START_LOADING
  });
  Http.get('/courses/' + category)
    .then(res => {
      dispatch({
        type: GET_COURSES_IN_CATEGORY,
        payload: res.data
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const getCategorys = () => dispatch => {
  Http.get('/course-types')
    .then(res => {
      dispatch({
        type: GET_COURSE_CATEGORYS,
        payload: res.data.data
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const getLevels = () => dispatch => {
  Http.get('/course-levels')
    .then(res => {
      dispatch({
        type: GET_COURSE_LEVELS,
        payload: res.data.data
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const getAwardType = (slug) => dispatch => {
  Http.get(`/${slug}/award-types`)
    .then(res => {
      console.log("thisd?: ", res);
      dispatch({
        type: UPDATE_AWARD_TYPES,
        payload: res.data.data
      })
    })
    .catch(err => {
      console.log(err);
    })
};
