import React from 'react';

const AboutCircle = props => (
  <div
    className="h-100"
    style={{
        background: '',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundPositionY: 0,
        backgroundRepeat: 'no-repeat',
        paddingBottom: 1,
    }}
  >
    {props.children}
  </div>
);

export default AboutCircle;

