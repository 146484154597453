import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import './index.scss';
import Button from '../../Stuff/Button';

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = (suggestion) => {
  return (
    <a href={`/courses/${suggestion.course_type.slug}/${suggestion.slug}`}>
      <div>{suggestion.course_name}</div>
    </a>
  );
};

class AutoSuggest extends Component {
  state = {
    value: '',
    suggestions: []
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;


    let suggestions = [];

    if (this.props.suggestions !== null) {
      this.props.suggestions.data.forEach((item, i) => {
        if (item.course_name.toLowerCase().includes(value.toLowerCase())) {
          suggestions.push(item);
        }
      });
    }

    return suggestions;

    // return inputLength === 0 ? [] : this.props.suggestions.includes(val =>
    //   val.name.toLowerCase().slice(0, inputLength) === inputValue);
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search online courses',
      value,
      onChange: this.onChange
    };

    return (
      <div className="autoSuggestWrapper mt-4 mb-1">
        <Autosuggest
          suggestions={suggestions.slice(0, 10)}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <Link to={'/courses'}>
          <Button
            className="searchBtn"
            width={80}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            textColor="white"
            fontSize={20}>
            <i className="fas fa-search" />
          </Button>
        </Link>
      </div>
    );
  }
}

export default connect()(AutoSuggest);
