import React, { PropTypes } from 'react';

import { Button } from 'reactstrap';

const Course = props => (
  <a
    href={`/courses/${props.course.course_type.slug}/${props.course.slug}`}
    className={'nounderline col-xs-12'}
    title={props.course.course_type.name}>
    <div style={{ backgroundColor: '#F2F4F6' }} className="course">
      <div className="course-image">
        <img src={props.course.exam_boards.logo} alt={`${props.course.exam_boards.name} logo`} />
      </div>
      <div className="course-detail">
        <div
          className="course-title"
          style={{ fontSize: 32, textAlign: 'left' }}>
          {props.course.course_name}
        </div>
        <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 700 }}>
          {props.course.course_type.name}
        </p>
        <p
          className="pt-0 CourseDescription"
          dangerouslySetInnerHTML={{
            __html: `${props.course.summary !== null ? props.course.summary : props.course.description.slice(0, 200)}`
          }}
        />
      </div>
      <div className="button-container">
        <Button>
          View Course <i className="fas fa-chevron-right" />
        </Button>
      </div>
    </div>
  </a>
);

export default Course;
