import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AboutCircle from '../../components/Circles/aboutCircle';
import Page from '../../components/Page';
import PageContent from '../../components/Page/PageContent';
import PageHeader from '../../components/PageHeader';
import BottomContact from '../../components/BottomContact';
import Meta from '../../components/Meta';

import './index.scss';
import Bubble from '../../components/Bubble';

class About extends React.Component {
  render() {
    return (
      <Page classnames="about-page">
        <Meta
          title="About Us | Brighton School of Business Management"
          description="Learn about Brighton School of Business and Management, originally founded in 1990 as Business Management training company for UK public and private sector"
          type={'website'}
        />
        <PageHeader style={{ marginTop: 50 }}>
          <h1>International online distance learning centre</h1>
          {/*<img*/}
          {/*src="/images/circles/topRightBlue.svg"*/}
          {/*height={500}*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
        </PageHeader>
        <span
          className={
            'homepage left blue-15 clockwise-8 topHalf d-none d-md-inline'
          }
        />
        <AboutCircle>
          <div className="container h-100">
            <div className="row h-100 pb-spacer-50 justify-content-center align-items-center">
              <div className="col-12 col-md-8 spacer-50">
                <p>
                  Brighton School of Business and Management (BSBM), founded in 1990, has supported over 8500 students in 110 countries and has built up an enviable reputation for delivering high quality, distance learning Leadership and Management courses.
                </p>
                <p>
                BSBM offer a broad range of professional qualifications from the UK’s leading awarding bodies; the Chartered Management Institute (CMI) and Pearson.
                </p>
                <p>
                As an international online distance learning provider, we offer a range of UK accredited and internationally recognised Management and Business qualifications at both undergraduate and postgraduate levels. These include a range of Strategic Leadership qualifications at levels 7 and 8 from the Chartered Management Institute (CMI).
                </p>
                <p>
                BSBM is proud to be a Strategic Partner to the Chartered Management Institute (CMI), offering clients a transition to obtaining Chartered Manager status. Our strong support and guidance results in high pass rates contributing to the successful career progression of our valued students.
                </p>
              </div>
            </div>
          </div>
        </AboutCircle>
        <div className="container">
          <div
            className="row text-center h-100 spacer-50 spacer-bottom-90 justify-content-center align-items-center"
            data-aos="fade-up">
            <div className="col-12 col-md-8">
              <h4 style={{ fontSize: 32 }}>Our Approved Partners</h4>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <p>
              All of our qualification courses are UK nationally accredited, and delivered by BSBM as an Approved Centre for our awarding body partners.
              </p>
            </div>
            <div className="col-12 col-md-8 spacer-50 spacer-top-20 spacer-bottom-30">
              <div className="row justify-content-center align-items-center logos">
                <div className="col-12 col-md-4">
                  <a href={'https://www.managers.org.uk/'} target={'_blank'}>
                    <img
                      src="/images/logos/CMI_Strategic_partner.png"
                      className="img-fluid"
                      alt="Brighton CMI logo"
                      style={{
                        width: '150px',
                        transform: 'scale(1.1)'
                      }}
                    />
                  </a>
                </div>
                <div className="col-12 col-md-4">
                  <a href={'https://www.pearson.com/'} target={'_blank'}>
                    <img
                      src="/images/about/pearson_logo.svg"
                      className="img-fluid"
                      alt="Brighton Pearson logo"
                      style={{
                        width: '250px',
                        marginTop: '20px',
                        marginLeft: '-30px',
                        transform: 'scale(1.2)'
                      }}
                      // style={{
                      //   marginTop: '40px',
                      //   marginLeft: '-30px',
                      //   transform: 'scale(1.7)'
                      // }}
                    />
                  </a>
                </div>
                {/* <div className="col-12 col-md-3">
                  <a href={'https://www.quality.org/'} target={'_blank'}>
                    <img
                      src="/images/about/CQI_logo.svg"
                      className="img-fluid"
                      alt="Brighton CQI logo"
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row text-center h-100 spacer-50 spacer-bottom-50 justify-content-center align-items-center"
            data-aos="fade-up">
            <div className="col-12 col-md-8">
              <h4 style={{ fontSize: 32 }}>Support for the Armed Forces</h4>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <p>
                As an approved learning provider to the Enhanced Learning Credits scheme, we are pleased to be able to support current members of the armed forces, 
                or those who have recently left, with a range of courses at levels 3 to 8 and award to diploma. 
              </p>
              <p>So, whether you are preparing for a new career, or would like to broaden your skills and knowledge base, 
                our courses are the ideal choice.
              </p>
              <p>
                For more information on this option, please visit the ELCAS website by clicking <a href="https://www.enhancedlearningcredits.com/learning-provider/provider-details?providerid=12526">here</a>.
              </p>
            </div>
            <div className="col-12 col-md-8 spacer-50">
              <div className="row justify-content-center align-items-center logos">
                <div className="col-12 col-md-4" />
                <div className="col-12 col-md-4">
                  <a href={'https://www.enhancedlearningcredits.com/'} target={'_blank'}>
                    <img
                      src="/images/courses/ELCAS.png"
                      className="img-fluid"
                      alt="ELCAS Logo"
                      style={{
                        width: '170px',
                        transform: 'scale(1.1)'
                      }}
                    />
                  </a>
                </div>
                <div className="col-12 col-md-4" />
              </div>
            </div>
            <BottomContact />
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses.courses
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(About);
