import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AboutCircle from '../../components/Circles/aboutCircle';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import CourseBucket from '../Home/CourseBucket';
import Meta from '../../components/Meta';

import './index.scss';
import Bubble from '../../components/Bubble';

class OnlineDistanceLearning extends React.Component {
  render() {
    return (
      <Page classnames="OnlineDistanceLearning-page">
        <Meta
          title="Online Distance Learning | E-Learning, Online Learning Courses &amp; Training | UK"
          description="Brighton SBM are one of the leading providers of online distance learning courses in the UK. We have a wide range of courses to chose from to help kick start your career or take it to the next level. View our range of courses online and enrol today."
          type={'website'}
        />
        <PageHeader style={{ marginTop: 50 }}>
          <div className="row h-100 pb-spacer-50 justify-content-center align-items-center">
            <div className="col-12 col-md-8">
              <p>Brighton SBM are one of the leading providers of online distance learning courses in the UK. We have a wide range of courses to chose from to help kick start your career or take it to the next level. View our range of courses online and enrol today.</p>
              <h1 className="text-center m-auto">Online Distance Learning</h1>
            </div>
          </div>
          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
        </PageHeader>
        <span
          className={
            'homepage left blue-15 clockwise-8 topHalf d-none d-md-inline'
          }
        />
        <AboutCircle>
          <div className="container h-100">
            <div className="row h-100 pb-spacer-50 justify-content-center align-items-center">
              <div className="col-12 col-md-8 spacer-50">
                <p>
                  Distance learning courses are becoming an increasingly popular way to gain qualifications
                  without having to go to college or university full time, meaning they can be completed
                  around other commitments. Brighton SBM have a number of fantastic online courses available
                  in a wide range of subjects and topics to help you in getting your first foot on the career
                  ladder or keep progressing up it. From HNC and HND courses to Project Management courses we
                  are sure to have a course that aligns with your career aspirations. The benefits of distance
                  learning include being able to learn at your own pace and fitting the course in around your
                  current job and other priorities. Take a look at our most popular distance learning courses
                  below or browse our website to see the full range.
                </p>
              </div>
            </div>
          </div>
        </AboutCircle>
        <div className="container">
          <div
            className="row h-100 spacer-100 spacer-bottom-100 justify-content-center align-items-center">
            <div className="col-12 col-md-8">
              <h2 className="text-center">Our Popular distance learning courses</h2>
            </div>
            <div className="course-buckets container spacer-50">
              <div className="row">
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Management &amp; Leadership Courses"
                  link="/courses/management-leadership"
                />
                <CourseBucket
                  image="/images/courses/Pearson.svg"
                  name="HNC / HND Business Courses"
                  link="/courses/hnc-hnd"
                />
                <CourseBucket
                  image="/images/courses/CMI.svg"
                  name="Project Management Courses"
                  link="/courses/project-management"
                />
              </div>
            </div>
          </div>
        </div>
        <AboutCircle>
          <div className="container page-content">
            <div className="row spacer-bottom-100 justify-content-center align-items-center">
              <div className="col-12 col-md-8">
                <h2 className="text-center">Online Distance Learning FAQs</h2>
              </div>
              <div className="col-12 col-md-8 spacer-50">
                <h3 className="page-summary">
                  What is online distance learning?
                </h3>
              </div>
              <div className="col-12 col-md-8">
                <p>
                  Distance learning is also referred to as e-learning, online learning and distance education
                  and generally refers to a form of learning where the tutor and students are physically
                  separated. Various forms of technology are used in order for the two to communicate in order
                  for the student to be able to learn what is required to pass the course they are taking.
                </p>
              </div>
              <div className="col-12 col-md-8 spacer-50">
                <h3 className="page-summary">
                  Are distance learning degrees recognised?
                </h3>
              </div>
              <div className="col-12 col-md-8">
                <p>
                  You should choose accredited and nationally recognised distance learning courses to ensure
                  they are accepted by a potential employer in the future. All courses provided by Brighton
                  SMB are accredited.
                </p>
              </div>
              <div className="col-12 col-md-8 spacer-50">
                <h3 className="page-summary">
                  What are the disadvantages of distance learning
                </h3>
              </div>
              <div className="col-12 col-md-8">
                <p>
                  There are many advantages to distance learning such as the ability to be flexible around
                  other commitments, however, keeping yourself motivated is one of the key difficulties you
                  may face as a distance learning student. Our courses are designed to keep you engaged and
                  encourage you to complete the course as quickly as you are able to get you on the road to
                  a successful career.
                </p>
              </div>
            </div>
          </div>
        </AboutCircle>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses.courses
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnlineDistanceLearning);
