import React from 'react';
import { getCourses } from '../../modules/courses';
import Page from '../../components/Page';
import PageContent from '../../components/Page/PageContent';
import PageBlock from '../../components/Page/PageBlock';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import Meta from '../../components/Meta';
import './index.scss';
import Bubble from '../../components/Bubble';

class Elcas extends React.Component {
  render() {
    return (
      <Page classnames="bahons-page">
        <Meta
          type={'website'}
          title="ELCAS Courses Online | ELCAS Approved Training Provider | UK"
          description="We provide approved online ELCAS courses as part of the MoD’s Enhanced Learning Credits scheme. View our training courses online and get started today."
        />
        <section className={'page-header'}>
          <div className={'container'}>
            <PageHeader>
              <div className={'row d-flex justify-content-center'}>
                <div className={'col-12 col-md-12 pl-0'}>
                  <h1>ELCAS Courses Scheme</h1>
                  <h2>
                    Supporting the Armed Forces with Enhanced Learning Credits
                  </h2>
                  <img
                    src={'/images/courses/ELCAS.png'}
                    style={{
                      width: 250,
                      marginLeft: 0,
                      marginBottom: 30,
                      marginTop: 30
                    }}
                  />
                </div>
              </div>
            </PageHeader>
            <div className={'d-none d-lg-inline'}>
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={500}
                delay={'-8s'}
                backgroundColor={'#0887E0'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'8s'}
                right={150}
                widthHeight={500}
                delay={'-10s'}
                backgroundColor={'#93CCFD'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={200}
                widthHeight={300}
                delay={'-13s'}
                backgroundColor={'#50DCF6'}
                animations={{
                  WebkitAnimation: 'move-up-right 15s linear infinite',
                  MozAnimation: 'move-up-right 15s linear infinite',
                  msAnimation: 'move-up-right 15s linear infinite',
                  OAnimation: 'move-up-right 15s linear infinite',
                  animation: 'move-up-right 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={200}
                delay={'-3s'}
                backgroundColor={'#4097F8'}
                animations={{
                  WebkitAnimation: 'move-up-left 15s linear infinite',
                  MozAnimation: 'move-up-left 15s linear infinite',
                  msAnimation: 'move-up-left 15s linear infinite',
                  OAnimation: 'move-up-left 15s linear infinite',
                  animation: 'move-up-left 15s linear infinite'
                }}
              />
            </div>
            <div className={'d-lg-none'}>
              <span className={'homepage topRight blue-15 twoThirds'} />
            </div>
          </div>
        </section>
        <PageContent>
          <div className={'row d-flex'}>
            <div className={'col-12 col-md-12'}>
              <PageBlock>
                <p>
                  As an approved learning provider for the MoD’s Enhanced
                  Learning Credits scheme, Brighton School of Business and
                  Management offer a range of courses which enable armed forces
                  personnel to retrain in order to support their resettlement
                  and broaden their career pathways. Our accredited courses are
                  ideal for those who are in active service, or have recently
                  left, as they support the many transferable skills gained
                  whilst working in this field. The ELC scheme provides
                  financial support for all courses which result in the award of
                  a nationally recognised qualification at level 3 and above.
                  For support on which course is right for you, please get in
                  touch with our student services by visiting our{' '}
                  <a href="/contact">contact page</a>. Or, for the full suite of
                  courses which are eligible for this scheme, please{' '}
                  <a href="https://www.enhancedlearningcredits.com/learning-provider/provider-details?providerid=12526">
                    click here
                  </a>
                  .
                </p>
              </PageBlock>
              <PageBlock>
                <h4>Getting Started with ELCAS Funded Courses</h4>
                <p>
                  Student services are on hand to advise on which course is
                  right for you, but to get you started, we have listed the
                  steps involved in the process.
                </p>
                <img
                  src={'/images/courses/ProcessInfographic.png'}
                  style={{
                    width: '60%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginbottom: '20px'
                  }}
                />
                <p>
                  In order to apply for this financial aid scheme, you must
                  first be registered as a Scheme Member and have been employed
                  in your role for six years
                </p>
                <p>
                  For those still serving, we recommend that you speak with your
                  Education Staff for more details on how to join this scheme.
                  Service leavers must contact their Single Service
                  Representative (SSR).{' '}
                </p>
                <p>
                  Don’t forget to complete your Course Evaluation form when
                  requested, as this impacts upon whether further claims can be
                  processed.{' '}
                </p>
                <p>
                  To view the full suite of courses available through the ELC
                  Scheme, please{' '}
                  <a href="https://www.enhancedlearningcredits.com/learning-provider/provider-details?providerid=12526">
                    click here.
                  </a>
                </p>
              </PageBlock>
              <PageBlock>
                <h4>Support With Choosing the Right ELCAS Approved Course</h4>
                <p>
                  For support on choosing the right course, please send an email
                  to{' '}
                  <a href="mailto:studentservices@brightonsbm.com">
                    studentservices@brightonsbm.com.
                  </a>
                </p>
              </PageBlock>
            </div>
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default Elcas;
