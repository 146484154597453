import React, { PropTypes } from 'react';

import './BottomContact.scss';

const BottomContact = props => (
  <div className="col-12 spacer-100 spacer-bottom-30 text-center bottom-contact">
    <a className={'cant-find'} href="/contact" style={{ fontSize: 24 }}>
      Can’t find what you are looking for?{' '}
      <span style={{ fontWeight: 700 }}>Get in Touch</span>
    </a>
    <p className={'cant-find'} style={{ fontSize: 24 }}>
      Or give us a call on{' '}
      <span style={{ fontWeight: 700 }}>+44 (0)1273 704 463</span>
    </p>
  </div>
);

export default BottomContact;
