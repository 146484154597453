import React from 'react';
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';
import { reduxForm, formValueSelector } from 'redux-form';

import { courseApply } from '../../../modules/apply';
import ReactGA from 'react-ga';

import Http from '../../../constants/Http';
import Loading from '../../../components/Loading';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import Page5 from './pages/Page5';
import Page6 from './pages/Page6';
import Page7 from './pages/Page7';

import './index.scss';

class Apply extends React.Component {
  state = {
    page: 1,
    progress: 100 / 7,
    paymentMethods: []
  };

  componentWillMount() {
    this.getPaymentMethods();
  }

  getPaymentMethods = () => {
    Http.get('/payment-methods')
      .then(res => {
        this.setState({
          paymentMethods: res.data.data
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
    this.setState({ progress: (100 / 7) * (this.state.page + 1) });
  };

  prevPage = () => {
    this.setState({ page: this.state.page - 1 });
    this.setState({ progress: this.state.progress - 100 / 7 });
  };

  submit = values => {
    this.props.dispatch(courseApply(values, this.props.courseSlug));
  };

  render() {
    const { page, progress } = this.state;
    const { onSubmit } = this.props;

    if (this.props.isSubmitting) {
      return (
        <div style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}>
          <Loading />
        </div>
      );
    }

    if (this.props.hasSubmitted) {
      return (
        <div
          style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}
          className="apply-submission-status">
          <i className="fas fa-checkmark-circle" />
          <div className="title">All Done!</div>
          <p>
            Your application has been received and we will be in touch shortly.
          </p>
          <div className="close" onClick={() => this.props.toggle()}>
            Close Window
          </div>
          <img
            src="/images/circles/topRightBlue.svg"
            height={250}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
          />
          <img
            src="/images/circles/leftLightBlue.svg"
            height={300}
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
          />
        </div>
      );
    }

    if (this.props.hasError) {
      return (
        <div
          style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}
          className="apply-submission-status">
          <i className="fas fa-times-circle" />
          <div className="title">Something went wrong!</div>
          <p>There has been a problem submitting your application.</p>
          <p>
            Please call a member of our team on +44 (0)1273 704 463 for
            assistance.
          </p>
          <div className="close" onClick={() => this.props.toggle()}>
            Close Window
          </div>
          <img
            src="/images/circles/topRightBlue.svg"
            height={250}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
          />
          <img
            src="/images/circles/leftLightBlue.svg"
            height={300}
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
          />
        </div>
      );
    }

    return (
      <div className="apply-wrapper applyWrapper">
        <div className="wrapper">
          <div className="form-header">
            <div className="apply-tag">Application Form</div>
            <div className="course-title">{this.props.courseName}</div>
            <div className="form-stage">
              <div className="row">
                <div className="col-12">
                  {page === 1 && <h5>Personal Details</h5>}
                  {page === 2 && <h5>Address Details</h5>}
                  {page === 3 && <h5>Contact Details</h5>}
                  {page === 4 && <h5>Education</h5>}
                  {page === 5 && <h5>Work Experience</h5>}
                  {page === 6 && <h5>Choice of payment method</h5>}
                  {page === 7 && <h5>Acceptance of terms</h5>}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 spacer-bottom-50">
                  {page !== 8 && <Progress value={this.state.progress} />}
                </div>
              </div>
            </div>
          </div>
          <div className="container" />
          {page === 1 && (
            <Page1
              onSubmit={this.nextPage}
              awardTypes={this.props.awardTypes}
            />
          )}
          {page === 2 && (
            <Page2 prevPage={this.prevPage} onSubmit={this.nextPage} />
          )}
          {page === 3 && (
            <Page3 prevPage={this.prevPage} onSubmit={this.nextPage} />
          )}
          {page === 4 && (
            <Page4 prevPage={this.prevPage} onSubmit={this.nextPage} />
          )}
          {page === 5 && (
            <Page5 prevPage={this.prevPage} onSubmit={this.nextPage} />
          )}
          {page === 6 && (
            <Page6
              prevPage={this.prevPage}
              paymentMethods={this.state.paymentMethods}
              onSubmit={this.nextPage}
            />
          )}
          {page === 7 && (
            <Page7 prevPage={this.prevPage} onSubmit={this.submit} />
          )}
        </div>
        <i
          className="fas fa-times"
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
            fontSize: 18,
            zIndex: 9999,
            cursor: 'pointer'
          }}
          onClick={() => this.props.toggle()}
        />

        <img
          alt="top right"
          src="/images/circles/topRightBlue.svg"
          height={250}
          className="topRight"
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
        />
        <img
          alt="left"
          src="/images/circles/leftLightBlue.svg"
          height={300}
          className="topLeft"
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    award_type: ownProps.awardTypes.length === 1 ? ownProps.awardTypes[0] : null
  }
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Apply)
);
