import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Button } from 'reactstrap';

import renderField from '../../../../components/Fields/renderField';
import RenderSelect from '../../../../components/Fields/renderSelect';
import * as validations from '../../../../Validation';
import DatePicker from 'react-datepicker';

const renderSelect = props => (
  <div>
    <select {...props.input}>{props.children}</select>
    {props.touched && props.error && <div className="error">{props.error}</div>}
  </div>
);

const DatePickerField = props => {
  return (
    <DatePicker selected={props.input.value} showYearDropdown dateFormat={"DD / MM / YYYY"} onChange={props.input.onChange} />
  );
};

const disabilityStatuses = [
  { value: 1, label: 'Not Disabled' },
  { value: 2, label: 'Disabled But Not Registered' },
  { value: 3, label: 'Registered Disabled' }
];

const sexOptions = [
  {
    value: 1,
    name: 'Male',
  },
  {
    value: 2,
    name: 'Female',
  },
  {
    value: 3,
    name: 'Other',
  },
];

const PersonalDetails = props => (
  <div className={'mt-5'}>
    <FormGroup className={'col-8 mt-4'}>
      <Label>First Name</Label>
      <Field name={'user.first_name'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Last Name</Label>
      <Field name={'user.last_name'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Date of Birth</Label>
      <Field name={'user.date_of_birth'} component={DatePickerField} noLabel type={'date'} />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Disability Status</Label>
      <Field
        name={'user.disability_status'}
        label={'Disability *'}
        component={RenderSelect}
        options={disabilityStatuses}
        validate={validations.required}
      />
    </FormGroup>
    <FormGroup className={"col-8 mt-4"}>
      <Label>Sex</Label>
      <Field
        name={'user.gender'}
        label={'Disability *'}
        component={RenderSelect}
        options={sexOptions}
        labelKey={"name"}
        validate={validations.required}
      />
    </FormGroup>
    <FormGroup className={"col-8 mt-4"}>
      <Button className={'btn btn-dashboard float-left mb-4'}>Update</Button>
    </FormGroup>
  </div>
);

export default PersonalDetails;
