import React, { PropTypes } from 'react';

import './PageHeader.scss';

const PageHeader = props => (
  <div className="page-header" style={props.style}>
    <div className="container">
      <div className="title-wrapper">{props.children}</div>
    </div>
  </div>
);

export default PageHeader;
