import React from 'react';
import { getCourses } from '../../modules/courses';
import Page from '../../components/Page';
import PageContent from '../../components/Page/PageContent';
import PageBlock from '../../components/Page/PageBlock';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import Meta from '../../components/Meta';
import Bubble from '../../components/Bubble';
import './index.scss';

class CharteredManager extends React.Component {
  render() {
    return (
      <Page classnames="apprentice-page">
        <Meta
          title="Apprenticeship Training Courses Online | ILM apprenticeship Course Providers | UK"
          description="Level 3 Team Leader/Supervisor Apprenticeship | Level 5 Operations/Departmental Manager Apprenticeship"
          type={"website"}
        />
        <section className={"page-header"}>
          <div className={"container"}>
        <PageHeader>
          <div className={"row d-flex justify-content-center"}>
            <div className={"col-12 col-md-10"}>
          <h1>Apprenticeship Training Courses</h1>
          <h2>
            TAKE YOUR EMPLOYER PARTNERSHIP TO THE NEXT LEVEL WITH MANAGEMENT APPRENTICE
          </h2>

          <img
            src={'/images/courses/apprenticeships.svg'}
            style={{ width: 200, marginLeft: -15 }}
          />
            </div>
          </div>
        </PageHeader>

            <div className={"d-none d-lg-inline"}>
              <Bubble speed={"17s"} right={100} widthHeight={500} delay={"-8s"} backgroundColor={"#0887E0"} animations={{ 'WebkitAnimation': 'move-up 15s linear infinite',
                'MozAnimation': 'move-up 15s linear infinite',
                'msAnimation': 'move-up 15s linear infinite',
                'OAnimation': 'move-up 15s linear infinite',
                'animation': 'move-up 15s linear infinite' }}  />
              <Bubble speed={"8s"} right={150} widthHeight={500} delay={"-10s"} backgroundColor={"#93CCFD"} animations={{ 'WebkitAnimation': 'move-up 15s linear infinite',
                'MozAnimation': 'move-up 15s linear infinite',
                'msAnimation': 'move-up 15s linear infinite',
                'OAnimation': 'move-up 15s linear infinite',
                'animation': 'move-up 15s linear infinite' }}  />
              <Bubble speed={"17s"} right={200} widthHeight={300} delay={"-13s"} backgroundColor={"#50DCF6"} animations={{ 'WebkitAnimation': 'move-up-right 15s linear infinite',
                'MozAnimation': 'move-up-right 15s linear infinite',
                'msAnimation': 'move-up-right 15s linear infinite',
                'OAnimation': 'move-up-right 15s linear infinite',
                'animation': 'move-up-right 15s linear infinite' }} />
              <Bubble speed={"17s"} right={100} widthHeight={200} delay={"-3s"} backgroundColor={"#4097F8"} animations={{ 'WebkitAnimation': 'move-up-left 15s linear infinite',
                'MozAnimation': 'move-up-left 15s linear infinite',
                'msAnimation': 'move-up-left 15s linear infinite',
                'OAnimation': 'move-up-left 15s linear infinite',
                'animation': 'move-up-left 15s linear infinite' }} />
            </div>
            <div className={"d-lg-none"}>
              <span className={"homepage topRight blue-15 twoThirds"} />
            </div>
      </div>
      </section>

        <PageContent>
          <div className={"row d-flex pl-2"}>
            <div className={"col-12 col-md-8 offset-md-1"}>
          <PageBlock>
            <h3 className="page-summary">
              <b>
                Working directly with employers, Management Apprentice can offer a programme that meets the specific needs of your organisation.
              </b>
            </h3>
            <p>
              We pride ourselves on providing the most appropriate solution to meets our clients’ needs, which can
              be different to what you might find with other, traditional Apprenticeship training providers.
            </p>
          </PageBlock>
          <PageBlock>
            <p>
              With 25 years in the Leadership and Management development training arena we also
              offer management level apprenticeships at both Level 3 and Level 5 Diplomas
            </p>
            <p>
              As an intrinsic part of a group of successful organisations provide the best of all worlds in the Private,
              Public and Third Sectors;
            </p>
            <p>
              We use this wide experience and expertise to support organisations in developing their own
              management apprenticeship programmes, which are aligned to their exact business needs, ensuring
              they gain the best value from their levy contributions.
            </p>
            <p>
              Confusion still exists in the training marketplace and so we help to dispel the myths around
              Management Apprenticeships to capture enthusiasm from your management teams. Apprenticeships
              aren’t what you traditionally remember them to be. As far as we are concerned, an apprenticeship is
              essentially a ‘wrapper’ for a specific training programme which can be offered for anyone, at any time
              in their life – irrespective of their age, background or career level.
            </p>
          </PageBlock>
          <PageBlock>
            <h4>BENEFITS OF APPRENTICESHIPS</h4>
            <p>
              An apprenticeship is first and foremost a job with substantial training and the development of
              knowledge and skills, including transferable skills. Apprentices must already be in paid employment
              for the duration of their apprenticeship and combine working with studying. Apprenticeships are a way
              for people of all ages to ‘earn while they learn’, gaining a qualification and a real future which
              benefit employers and apprentices. An apprenticeship can also be an excellent alternative to going into higher education.
            </p>
            <ul>
              <li>
                Apprentices acquire the skills they need to work and progress within their career
              </li>
              <li>
                Apprentices will be employed, whilst they gain a full apprenticeship and Diploma which
                provides immediate benefits for the learner and employer
              </li>
              <li>
                Apprenticeships are a tried and tested way for employers to recruit new staff, re-train or up-
                skill existing staff
              </li>
              <li>
                Productivity is increased over time
              </li>
              <li>
                Training costs are Government funded from the Employer Levy
              </li>
            </ul>
          </PageBlock>
          <PageBlock>
            <h4>TYPICAL APPRENTICESHIP TRAINING COURSE CONTENT</h4>
            <p>
              The delivery of our training courses is flexible and will be designed with you to create content aligned to the appropriate
              syllabus adapted to reflect your requirements, values, and objectives, and suitably integrates with
              existing training and development. Full course plans and delivery outlines, detailing comprehensive
              course content, breakdown of module coverage; learning objectives and delivery methods against will
              be provided by your training provider. The exact ratio of taught and assignment elements will be determined on an individual
              basis based on initial assessment and ongoing progress reviews during Skills Development Progress
              Reviews
            </p>
          </PageBlock>
          <PageBlock>
            <p className="header-4">SUPPORT</p>
            <p>
              Both the apprentice and employer will be supported by a team of Programme Advisors and Skills
              Coaches who will ensure the participant completes the Knowledge, Skills and Behaviours required
              and is ready to sit the final assessment (End Point Assessment).
            </p>
            <p>
              We combine online learning, classroom training and on-the-job experience to deliver a flexible, world-
              class development programme geared to your success. Our programme helps the delegate to easily
              combine learning around their day job and, with access to our online learning platform – learning can
              be accessed anytime, anywhere and on any device, so you get high-quality content on the go.
            </p>
          </PageBlock>
          <PageBlock>
            <p className="header-4">QUALIFICATION?</p>
            <p>
              Apprentices will achieve either a Level 3 or 5 Diploma from the ILM
            </p>
            <p>
              Participants without level 2 English and Maths will need to achieve this level prior to taking
              the end-point assessment and this will also be fully Government funded.
            </p>
          </PageBlock>

          <PageBlock>
            <p className="header-4">FEES</p>
            <p>
              Apprenticeship Levy tax can be used to pay full programme costs.
            </p>
            <p>
              If you would like more information on these Apprenticeships please email us at{" "}
              <a href="mailto:admissions@brightonsbm.com">admissions@brightonsbm.com</a> and we will ensure that our Management Apprentice advisor will
              contact you without delay.
            </p>
          </PageBlock>

          {/* <PageBlock>
            <h4>Courses Available</h4>
            <a
              className="course-link"
              href="/courses/management-leadership/cmi-level-3-diploma-team-leadersupervisor-apprenticeship-standard">
              <span>
                <i className="fas fa-eye" />
              </span>{' '}
              Level 3 Team Leader/Supervisor Apprenticeship</a>
            <a
              className="course-link"
              href="/courses/management-leadership/cmi-level-5-diploma-in-operationsdepartmental-manager-apprenticeship-standard">
              <span>
                <i className="fas fa-eye" />
              </span>{' '}
              Level 5 Operations/Departmental Manager Apprenticeship</a>
          </PageBlock> */}
          </div>
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default CharteredManager;
