import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import Button from '../../Stuff/Button';

import './index.css';

class NavAbout extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  state = {
    isOpen: false,
    isTop: true,
  };

  componentWillMount() {
    this.checkScroll();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll());
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  checkScroll = () => {
    const scroll = window.scrollY > 100;
    if (scroll !== this.state.isTop) {
      this.setState({
        isTop: scroll
      });
    }
  };

  render() {
    const { desktop, loggedIn } = this.props;
    const { isTop } = this.state;
    return (
      <Navbar
        expand="lg"
        className={`fixed-top ${
          isTop ? 'nav-solid' : desktop ? '' : 'nav-solid'
          }`}>
        <NavbarBrand>
          <Link to="/">
            <img src="/images/Logo.svg" alt="" />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} className="aboutToggle" />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar className="ml-5">
            <NavItem className="link">
              <NavLink>
                <Link to="/courses">COURSES</Link>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <Link to="/about">ABOUT</Link>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <Link to="/contact">CONTACT</Link>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <Link to="/news">NEWS</Link>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className="ml-auto ml-xs-5 nav-white text-darkBlue">
            <NavItem className={'nav-number'}>
              <NavLink>CALL: +44 (0)1273 704 463</NavLink>
            </NavItem>
            {/*<NavItem>*/}
            {/*<NavLink className="navBtn">*/}
            {/*<Button width={90} height={35} btnColor="rgba(9, 62, 95, 0.09)">*/}
            {/*LOG IN*/}
            {/*</Button>*/}
            {/*</NavLink>*/}
            {/*<NavLink className="loginText">LOG IN</NavLink>*/}
            {/*</NavItem>*/}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn
  };
};

export default connect(mapStateToProps)(NavAbout);
