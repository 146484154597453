import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import { browserHistory } from 'react-router';
import ScriptTag from 'react-script-tag';

// import 'react-select/dist/react-select.css';

import {
  handleCourseCategory,
  handleCourseLevel,
  getCourses,
  getCoursesInCategory,
  getLevels,
  UPDATE_COURSE_LEVEL
} from '../../modules/courses';

import Http from '../../constants/Http';

import './index.scss';
import Button from '../../components/Stuff/Button';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import PageLoading from '../../components/Loading/pageLoading';
import BottomContact from '../../components/BottomContact';
import Course from './Course';
import Meta from '../../components/Meta';
import Bubble from '../../components/Bubble';
import QandA from '../../components/QandA';
import { Card, UncontrolledCollapse } from 'reactstrap';

class Courses extends React.Component {
  state = {
    courseLevel: null,
    courseCategory: null,
    courses: null,
    script_url: ''
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.courses !== nextProps.courses) {
      this.setState({
        courses: nextProps.courses
      });
    }
    this.setSelectedCategory(this.props.match.params.category);
  }

  componentWillMount() {
    //this.props.dispatch(getCategorys());
  }

  componentDidMount() {
    // Let's check to see if the URL is asking for a course category
    const category = this.props.match.params.category;
    // If it has a category, then call the relevant category of courses, if not, show them all.
    if (category) {
      this.props.dispatch(getCoursesInCategory(category));
    } else {
      this.props.dispatch(getCourses());
    }
  }

  setSelectedCategory = category => {
    const categories = [...this.props.courseCategorys];
    const matchCategory = categories.find(cat => cat.slug === category);
    this.setState({
      courseCategory: matchCategory
    });
  };

  handleCourseCategory = values => {
    if (values !== undefined) {
      window.location.href = '/courses/' + values.slug;
    }
  };

  handleCourseLevel = values => {
    this.props.dispatch(handleCourseLevel(values));
    this.setState({
      courseLevel: values
    });
  };

  render() {
    if (this.props.match.params.category === 'apprenticeships') {
      return <Redirect to="/apprenticeships" />;
    }
    if (this.props.isLoading) {
      return <PageLoading />;
    }

    return (
      <Page classnames="courses-page">
        <div class="ld-slot" data-name="Courses"></div>
        <PageHeader>
          {this.state.courseCategory == null ? (
            <>
              <Meta
                title="Online Business Management Courses | Certified Management Training | UK"
                description="All Online Business & Management Courses here. To enquire for more details of any course please call +44 (0)1273 704 463 or email enquiries@brightonsbm.com"
                type={'website'}
              />
              <h1>Our Online Business Management Courses</h1>
            </>
          ) : (
            <>
              <Meta
                type={'website'}
                title={
                  this.state.courseCategory.name === 'Management & Leadership'
                    ? 'Online Leadership & Management Courses | CMI Leadership Training| UK'
                    : this.state.courseCategory.name === 'HNC & HND'
                    ? 'HNC & HND Courses Online | Distance Learning Higher National Diploma & Certificate Courses| UK'
                    : this.state.courseCategory.name === 'BA Hons Top-Up'
                    ? 'Distance Learning Business Degree Courses'
                    : this.state.courseCategory.name === 'Coaching & Mentoring'
                    ? 'Coaching and Mentoring Courses | Online Coaching Qualifications Training | UK'
                    : this.state.courseCategory.name ===
                      'Professional Consultancy'
                    ? 'Management Consultant Training Courses | Distance Learning'
                    : this.state.courseCategory.name === 'Project Management'
                    ? 'Project Management Courses Online | CMI AccreditedProject Manager Qualifications | UK'
                    : this.state.courseCategory.name ===
                      'Professional Management'
                    ? 'Professional Management Distance Learning Courses | CMI'
                    : 'Apprenticeships | Tailored to your Business'
                }
                description={
                  this.state.courseCategory.name === 'Management & Leadership'
                    ? 'Brighton School of Business and Management is a leading provider of online leadership and management courses in the UK. With many CMI accredited courses from level 2 to level 8 you can learn in your own time and advance your skill set and your career.'
                    : this.state.courseCategory.name === 'HNC & HND'
                    ? 'If you are looking to further your qualifications with HNC or HND Courses, then Brighton School of Business and Management can help. We have a wide range of distance learning Higher National Diploma and Higher National Certificate Courses to help you advance your education and your career.'
                    : this.state.courseCategory.name === 'Coaching & Mentoring'
                    ? 'Our range of online coaching and mentoring courses are perfect for distance learning. With Brighton School of Business and Management you can learn at your own pace and gain qualifications to further your career.'
                    : this.state.courseCategory.name === 'Project Management'
                    ? 'Our CMI accredited project  management courses are ideal for a project manager to further their project management skills. \nView our range of courses online to see how we can help advance your career.'
                    : 'Select from our ' +
                      this.state.courseCategory.name +
                      ' Courses.  Online distance learning courses | Brighton SBM'
                }
              />
              <h1>Online {this.state.courseCategory.name} Courses</h1>
            </>
          )}
          <h2>
            {this.state.courseCategory !== undefined &&
            this.state.courseCategory !== null
              ? this.state.courseCategory.name === 'Management & Leadership'
                ? 'Find the right CMI course for you'
                : this.state.courseCategory.name === 'HNC & HND'
                ? 'Find the right HNC/HND course for you'
                : this.state.courseCategory.name === 'Coaching & Mentoring'
                ? 'Find the right Coaching and Mentoring course for you'
                : this.state.courseCategory.name === 'Project Management'
                ? 'Find the right Project Manager course for you'
                : 'Find the right online course for you'
              : 'Find the right online course for you'}
          </h2>

          <p className={'text-center mt-5 w-100 w-md-70 mx-auto'}>
            {this.state.courseCategory !== undefined &&
            this.state.courseCategory !== null ? (
              this.state.courseCategory.name === 'Management & Leadership' ? (
                'Brighton School of Business and Management are delighted to be able to offer a wide range of CMI leadership and management courses to help you, or any members of your business, learn and experience the skills required in the workplace. Our training is particularly useful for managers looking to further their training in leadership and management. Take a look at our management courses below.'
              ) : this.state.courseCategory.name === 'HNC & HND' ? (
                'Brighton School of Business and Management have a wide range of Higher National Diploma and Higher National Certificate Courses for you to study and further your education. Many of our subject courses are a great access course onto higher education such a degree.'
              ) : this.state.courseCategory.name === 'Coaching & Mentoring' ? (
                'Our coaching and mentoring courses are CMI accredited and have been created to further your coaching skills and professional development. The training you will receive on these courses will help you attain a qualification whilst also building upon your understanding of what it takes to become an effective mentor or coach.'
              ) : null
            ) : (
              <p>
                For many individuals looking to further their career, the next
                steps can often involve additional education. Brighton School of
                Business and Management have a range of online management,
                leadership and business courses which provide the flexibility to
                learn at a time and pace that suits you. We provide all of the
                information you require to pass your chosen course and achieve
                an internationally recognised qualification.
                <br />
                View our range of courses, book online and take the next step in
                your career.
              </p>
            )}
          </p>
          <p className={'text-center w-100 w-md-70 mx-auto'}>
            {this.state.courseCategory !== undefined &&
            this.state.courseCategory !== null
              ? this.state.courseCategory.name === 'HNC & HND'
                ? 'If you are looking for further educational development and to enhance your future educational and career prospects then take a look at our range of courses below. There are a number of different subjects that we provide courses for and all come with the appropriate training, teaching and information you require to be successful.'
                : this.state.courseCategory.name === 'Coaching & Mentoring'
                ? 'These courses are great for anyone looking to attain further qualifications to advance their careers but are particularly useful for individuals in leadership positions who maybe responsible for the coaching and mentoring of other employees.'
                : null
              : null}
          </p>

          <div className="row justify-content-center align-items-center course-selections">
            <div className="col-12 col-md-4">
              <Select
                name="course-category"
                value={this.state.courseCategory}
                onChange={value => this.handleCourseCategory(value)}
                placeholder="Choose Category"
                labelKey={'name'}
                valueKey={'slug'}
                options={this.props.courseCategorys}
              />
            </div>
            {this.state.courseCategory && this.state.courseCategory.levels && (
              <div className="col-12 col-md-4">
                <Select
                  name="course-level"
                  value={this.state.courseLevel}
                  onChange={value => this.handleCourseLevel(value)}
                  placeholder={`${
                    this.state.courseCategory === null
                      ? 'Choose a category first'
                      : 'Choose a Level'
                  }`}
                  labelKey={'name'}
                  valueKey={'id'}
                  options={
                    this.state.courseCategory
                      ? this.state.courseCategory.levels
                      : []
                  }
                  disabled={!this.state.courseCategory}
                />
              </div>
            )}
          </div>
          {/*<img*/}
          {/*src="/images/circles/topRightBlue.svg"*/}
          {/*height={700}*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
        </PageHeader>
        <span
          className={
            'homepage left blue-15 clockwise-8 topHalf d-none d-md-inline'
          }
        />
        <section className="course-list container">
          {this.state.courses !== null &&
          this.state.courses.data.length === 0 ? (
            <h3 className={'text-center'}>No Courses Found</h3>
          ) : this.state.courseCategory !== undefined &&
            this.state.courseCategory !== null ? (
            this.state.courseCategory.name === 'Management & Leadership' ? (
              <h2 className={'text-center pb-5'}>Our CMI Courses</h2>
            ) : this.state.courseCategory.name === 'HNC & HND' ? (
              <h2 className={'text-center pb-5'}>
                Our Higher National Diploma & Certificate Courses
              </h2>
            ) : this.state.courseCategory.name === 'Coaching & Mentoring' ? (
              <h2 className={'text-center pb-5'}>Our Courses</h2>
            ) : this.state.courseCategory.name === 'Project Management' ? (
              <h2 className={'text-center pb-5'}>
                Our CMI Project Management Courses
              </h2>
            ) : null
          ) : null}

          {this.state.courses !== null ? (
            this.state.courses.data.map((course, index) => (
              <Course course={course} index={index} key={index} />
            ))
          ) : (
            <Loading />
          )}
        </section>
        {/* {this.state.courseCategory !== undefined &&
        this.state.courseCategory !== null && (
              <div className="page-section chatbot">
                <ScriptTag
                  isHydrating={true}
                  type="text/javascript"
                  src="https://bot.leadoo.com/bot/inpage.js?code=JXBNgDPo#seamless,"
                />
              </div>
        )} */}
        {this.state.courseCategory !== undefined &&
        this.state.courseCategory !== null ? (
          this.state.courseCategory.name === 'Management & Leadership' ? (
            <div>
              <div className="page-section chatbot">
                <ScriptTag
                  isHydrating={true}
                  type="text/javascript"
                  src="https://bot.leadoo.com/bot/inpage.js?code=JXBNgDPo#seamless,"
                />
              </div>
              <div className="page-section q-and-a">
                <div className="container q-a">
                  <div className="row pt-5 justify-content-center">
                    <div className="col-12 spacer-50" data-aos="fade-up">
                      <p className={`title text-white spacer-100`}>
                        Management & Leadership course FAQs
                      </p>
                    </div>
                  </div>

                  <div className="row spacer-100">
                    {this.state.courseCategory.category_faqs && (
                      <QandA data={this.state.courseCategory.category_faqs} />
                    )}
                  </div>
                </div>

                <div className="container button-container">
                  <a href={'/faq'}>
                    <Button
                      width={190}
                      height={60}
                      btnColor="rgba(77, 199, 117, 1)"
                      boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                      textColor="white"
                      fontSize={14}>
                      SEE MORE
                    </Button>
                  </a>

                  <div
                    className="col text-center spacer-100"
                    data-aos="fade-up">
                    <a
                      className={`cant-find text-white`}
                      href="/contact"
                      style={{ fontSize: 24 }}>
                      Can’t find what you are looking for?{' '}
                      <span style={{ fontWeight: 700 }}>Get in Touch</span>
                    </a>
                    <p
                      className={`cant-find text-white`}
                      style={{ fontSize: 24 }}>
                      Or give us a call on{' '}
                      <span style={{ fontWeight: 700 }}>
                        +44 (0)1273 704 463
                      </span>
                    </p>
                  </div>
                </div>
                <img
                  src="/images/circles/test.svg"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    zIndex: -1
                  }}
                />
              </div>
            </div>
          ) : this.state.courseCategory.name === 'HNC & HND' ? (
            <div>
              <div>
                <div className="page-section chatbot">
                  <ScriptTag
                    isHydrating={true}
                    type="text/javascript"
                    src="https://bot.leadoo.com/bot/inpage.js?code=doOQQ4o3#seamless,noscroll"
                  />
                </div>
                <div className="page-section q-and-a">
                  <div className="container q-a">
                    <div className="row pt-5 justify-content-center">
                      <div className="col-12 spacer-50" data-aos="fade-up">
                        <p className={`title text-white spacer-100`}>
                          HNC & HND Course FAQs
                        </p>
                      </div>
                    </div>

                    <div className="row spacer-100">
                      {this.state.courseCategory.category_faqs && (
                        <QandA data={this.state.courseCategory.category_faqs} />
                      )}
                    </div>
                  </div>

                  <div className="container button-container">
                    <a href={'/faq'}>
                      <Button
                        width={190}
                        height={60}
                        btnColor="rgba(77, 199, 117, 1)"
                        boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                        textColor="white"
                        fontSize={14}>
                        SEE MORE
                      </Button>
                    </a>

                    <div
                      className="col text-center spacer-100"
                      data-aos="fade-up">
                      <a
                        className={`cant-find text-white`}
                        href="/contact"
                        style={{ fontSize: 24 }}>
                        Can’t find what you are looking for?{' '}
                        <span style={{ fontWeight: 700 }}>Get in Touch</span>
                      </a>
                      <p
                        className={`cant-find text-white`}
                        style={{ fontSize: 24 }}>
                        Or give us a call on{' '}
                        <span style={{ fontWeight: 700 }}>
                          +44 (0)1273 704 463
                        </span>
                      </p>
                    </div>
                  </div>
                  <img
                    src="/images/circles/test.svg"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      zIndex: -1
                    }}
                  />
                </div>
              </div>
            </div>
          ) : this.state.courseCategory.name === 'Coaching & Mentoring' ? (
            <div>
              <div className="page-section chatbot">
                <ScriptTag
                  isHydrating={true}
                  type="text/javascript"
                  src="https://bot.leadoo.com/bot/inpage.js?code=JXBNgDPo#seamless,noscroll"
                />
              </div>
              <div
                className="page-section q-and-a mb-0"
                style={{
                  backgroundImage: 'url(/images/circles/test.svg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}>
                <div className="container q-a">
                  <div className="row pt-5 justify-content-center">
                    <div className="col-12 spacer-50" data-aos="fade-up">
                      <p className={`title text-white spacer-100`}>
                        What is a CMI Qualification?
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 20,
                          marginBottom: 0,
                          color: 'white',
                          textAlign: 'center'
                        }}>
                        The CMI (chartered Management Institute) are an
                        accredited body of management professionals in the UK.
                        CMI courses broach a wide range of topics and can help
                        managers, project management professionals, marketing
                        managers, and other employees in supervisory roles in
                        advancing their managerial skillset with more advanced
                        strategies. Our range of courses are available to take
                        online meaning you can do them in your own time, and we
                        provide all the information, data and resources required
                        to get the results you need to make the grade.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row spacer-100">
                  {this.state.courseCategory.category_faqs && (
                    <QandA data={this.state.courseCategory.category_faqs} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="page-section chatbot">
                <ScriptTag
                  isHydrating={true}
                  type="text/javascript"
                  src="https://bot.leadoo.com/bot/inpage.js?code=JXBNgDPo#seamless,"
                />
              </div>
              <div>
                {this.state.courseCategory.name === 'Professional Management' ||
                this.state.courseCategory.name === 'Project Management' ? (
                  <div>
                    <div className="page-section q-and-a">
                      <div className="container q-a">
                        <div className="row spacer-100">
                          {this.state.courseCategory.category_faqs && (
                            <QandA
                              data={this.state.courseCategory.category_faqs}
                            />
                          )}
                        </div>
                      </div>

                      <div className="container button-container">
                        <a href={'/faq'}>
                          <Button
                            width={190}
                            height={60}
                            btnColor="rgba(77, 199, 117, 1)"
                            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                            textColor="white"
                            fontSize={14}>
                            SEE MORE
                          </Button>
                        </a>

                        <div
                          className="col text-center spacer-100"
                          data-aos="fade-up">
                          <a
                            className={`cant-find text-white`}
                            href="/contact"
                            style={{ fontSize: 24 }}>
                            Can’t find what you are looking for?{' '}
                            <span style={{ fontWeight: 700 }}>
                              Get in Touch
                            </span>
                          </a>
                          <p
                            className={`cant-find text-white`}
                            style={{ fontSize: 24 }}>
                            Or give us a call on{' '}
                            <span style={{ fontWeight: 700 }}>
                              +44 (0)1273 704 463
                            </span>
                          </p>
                        </div>
                      </div>
                      <img
                        src="/images/circles/test.svg"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: '50%',
                          zIndex: -1
                        }}
                      />
                    </div>
                  </div>
                ) : this.state.courseCategory.category_faqs &&
                  this.state.courseCategory.category_faqs.length > 0 ? (
                  <>
                    <div className="page-section q-and-a">
                      <div className="container q-a">
                        <div className="row spacer-100">
                          {this.state.courseCategory.category_faqs && (
                            <QandA
                              data={this.state.courseCategory.category_faqs}
                            />
                          )}
                        </div>
                      </div>

                      <div className="container button-container">
                        <a href={'/faq'}>
                          <Button
                            width={190}
                            height={60}
                            btnColor="rgba(77, 199, 117, 1)"
                            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                            textColor="white"
                            fontSize={14}>
                            SEE MORE
                          </Button>
                        </a>

                        <div
                          className="col text-center spacer-100"
                          data-aos="fade-up">
                          <a
                            className={`cant-find text-white`}
                            href="/contact"
                            style={{ fontSize: 24 }}>
                            Can’t find what you are looking for?{' '}
                            <span style={{ fontWeight: 700 }}>
                              Get in Touch
                            </span>
                          </a>
                          <p
                            className={`cant-find text-white`}
                            style={{ fontSize: 24 }}>
                            Or give us a call on{' '}
                            <span style={{ fontWeight: 700 }}>
                              +44 (0)1273 704 463
                            </span>
                          </p>
                        </div>
                      </div>
                      <img
                        src="/images/circles/test.svg"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: '50%',
                          zIndex: -1
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        ) : (
          <BottomContact />
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    state: state,
    isLoading: state.courses.isLoading,
    courseCategory: state.courses.courseCategory,
    courses: state.courses.coursesToShow,
    courseLevels: state.courses.courseLevels,
    courseCategorys: state.courses.courseCategorys
  };
};

export default connect(mapStateToProps)(Courses);
