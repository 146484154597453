import { push } from 'react-router-redux';
import { reset } from 'redux-form'
import Http from '../constants/Http';

export const SUBMIT_START = 'apply/SUBMIT_START';
export const SUBMIT_SUCCESS = 'apply/SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'apply/SUBMIT_ERROR';
export const RESET = 'apply/RESET';

const initialState = {
  error: false,
  success: false,
  submitting: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_START: {
      return {
        ...state,
        submitting: true
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        success: true,
        submitting: false
      };
    }
    case SUBMIT_ERROR: {
      return {
        ...state,
        success: false,
        error: true,
        submitting: false
      };
    }
    case RESET: {
      return {
        ...state,
        error: false,
        success: false,
        submitting: false
      }
    }
    default:
      return {
        ...state
      };
  }
};

export const submitStart = () => dispatch => {
  dispatch({
    type: SUBMIT_START
  });
};

export const submitSuccess = () => dispatch => {
  dispatch({
    type: SUBMIT_SUCCESS
  });
};

export const submitError = () => dispatch => {
  dispatch({
    type: SUBMIT_ERROR
  });
};

export const courseApply = (values, courseName) => dispatch => {
  dispatch(submitStart());
  Http.post(`/apply/${courseName}`, values)
    .then(res => {
      dispatch(submitSuccess());
      dispatch(push('/application-success'));
      dispatch(reset('wizard'));
    })
    .catch(err => {
      dispatch(submitError());
    });
};

export const courseEnquire = (values, courseName) => dispatch => {
  dispatch(submitStart());
  Http.post(`/enquire/${courseName}`, values)
    .then(res => {
      dispatch(submitSuccess());
      dispatch(reset('wizard'))
    })
    .catch(err => {
      dispatch(submitError());
    })
};