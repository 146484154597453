import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import Button from '../../Stuff/Button';

import './index.scss';
import { getCategorys } from '../../../modules/courses';
import connect from 'react-redux/es/connect/connect';

class NavDefault extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getCategorys());
  }

  state = {
    isOpen: false
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { isTop, desktop } = this.props;

    return (
      <Navbar
        expand="lg"
        className={`fixed-top ${
          isTop ? 'nav-solid' : desktop ? '' : 'nav-solid'
        }`}>
        <NavbarBrand>
          <a href="/">
            <img src="/images/Logo.svg" alt="" />
          </a>
        </NavbarBrand>
        <NavbarToggler
          onClick={this.toggle}
          className={isTop ? 'nav-solid' : desktop ? '' : 'nav-solid'}
        />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar className="ml-5">
            <div className={'courses-dropdown'}>
              <NavItem className="link">
                <NavLink>
                  <a href="/courses">COURSES</a>
                </NavLink>
              </NavItem>
              <div className={'courses-dropdown-menu'}>
                <div className={'square'} />
                {this.props.courseCategories.map((cat, index) => (
                  <a
                    href={cat.id === 3 ? '/ba-hons' : '/courses/' + cat.slug}
                    key={index}
                    style={{
                      fontSize: 16,
                      width: '100%',
                      display: 'block',
                      fontWeight: 300,
                      marginBottom: 5
                    }}>
                    {cat.name}
                  </a>
                ))}
              </div>
            </div>
            <NavItem className="link">
              <NavLink>
                <a href="/about">ABOUT</a>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <a href="/news">NEWS</a>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <a href="/contact">CONTACT</a>
              </NavLink>
            </NavItem>
            <NavItem className={'link'}>
              <NavLink href={'tel:01273704463'}>
                <i className="fas fa-phone" /> +44 (0)1273 704 463
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className="ml-auto ml-xs-5 nav-white">
            <NavItem>
              <NavLink className="navBtn text-white">
                <Button
                  width={90}
                  height={35}
                  btnColor="rgba(255, 255, 255, 0.2)"
                  onClick={() => (window.location.href = '/login')}>
                  LOG IN
                </Button>
              </NavLink>
              <NavLink className="loginText ml-5">LOG IN</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    courseCategories: state.courses.courseCategorys
  };
};

export default connect(mapStateToProps)(NavDefault);
