import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Nav, NavItem, NavLink } from 'reactstrap';
import './index.scss';
import { connect } from 'react-redux';
import { getCategorys } from '../../../modules/courses';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '25px',
    height: '17px',
    right: '20px',
    top: '31px'
  },
  bmBurgerBars: {
    background: '#093e5f'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  // bmMenuWrap: {
  //   pointerEvents: 'none'
  // },
  bmMenu: {
    background: 'white',
    padding: '2.3em .7em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.6em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

class NavMobile extends React.Component {
  state = {
    isOpen: false
  };

  componentWillMount() {
    this.props.dispatch(getCategorys());
  }

  isMenuOpen = isOpen => {
    // if (document.getElementsByClassName("bm-menu-wrap")[0].style.opacity === '1') {
    //   document.getElementsByClassName("bm-menu-wrap")[0].style.opacity = 0;
    //   document.getElementsByClassName("bm-menu-wrap")[0].style.pointerEvents = 'none';
    // } else {
    //   document.getElementsByClassName("bm-menu-wrap")[0].style.opacity = 1;
    //   document.getElementsByClassName("bm-menu-wrap")[0].style.pointerEvents = 'auto';
    // }

    var ua = navigator.userAgent.toLowerCase();
    console.log(ua);
    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
      if (isOpen.isOpen) {
        document.body.style.overflowX = 'unset';
      } else {
        document.body.style.overflowX = 'hidden';
      }
    }
  };

  componentDidMount() {
    if (window.innerWidth < 1200) {
      window.setTimeout(() => {
        const el = document.getElementsByClassName('bm-overlay')[0]
          .parentElement;
        el.style.position = 'fixed';
        el.style.top = 0;
        el.style.zIndex = 999;
      }, 1000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            height: 80,
            width: '100%',
            backgroundColor: 'white',
            zIndex: 998
          }}>
          &nbsp;
        </div>
        <img src={'/images/Logo.svg'} className="mobile-logo" style={{}} />
        <Menu
          isOpen={this.state.isOpen}
          onStateChange={test => this.isMenuOpen(test)}
          right
          styles={styles}>
          <Nav navbar className="nav-white text-white">
            <NavItem className="link">
              <NavLink>
                <a
                  href="/courses"
                  style={{
                    fontSize: 18,
                    color: '#093e5f',
                    display: 'block',
                    width: '100%',
                    fontWeight: 700,
                    marginBottom: 10
                  }}>
                  COURSES
                </a>
                {this.props.courseCategories.map((cat, index) => (
                  <a
                    href={'/courses/' + cat.slug}
                    style={{
                      fontSize: 16,
                      width: '100%',
                      display: 'block',

                      fontWeight: 300,
                      marginBottom: 5
                    }}>
                    {cat.name}
                  </a>
                ))}
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <a
                  href="/about"
                  style={{ fontSize: 16, color: '#093e5f', fontWeight: 700 }}>
                  ABOUT US
                </a>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <a
                  href="/contact"
                  style={{ fontSize: 16, color: '#093e5f', fontWeight: 700 }}>
                  CONTACT US
                </a>
              </NavLink>
            </NavItem>
            <NavItem className="link">
              <NavLink>
                <a
                  href="/news"
                  style={{ fontSize: 16, color: '#093e5f', fontWeight: 700 }}>
                  NEWS
                </a>
              </NavLink>
            </NavItem>
            <NavItem className={'link'}>
              <NavLink>
                <p style={{ fontSize: 18, color: '#093e5f', fontWeight: 700 }}>
                  <i className="fas fa-phone" /> +44 (0)1273 704 463
                </p>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className="ml-auto ml-xs-5 nav-white">
            {/*<NavItem>*/}
            {/*<NavLink className="navBtn"><Button width={90} height={35} btnColor="rgba(255, 255, 255, 0.2)">LOG IN</Button></NavLink>*/}
            {/*<NavLink className="loginText">LOG IN</NavLink>*/}
            {/*</NavItem>*/}
          </Nav>
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    courseCategories: state.courses.courseCategorys
  };
};

export default connect(mapStateToProps)(NavMobile);
