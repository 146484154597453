import React from 'react';
import { reduxForm, Form, Field, Fields } from 'redux-form';
import Button from '../../../components/Stuff/Button';

import RenderField from '../../../components/Fields/renderField';
import RenderSelect from '../../../components/Fields/renderSelect';
import RenderAllErrors from '../../../components/RenderAllErrors';

import * as validations from '../../../Validation';

const Page2 = props => {
  const { handleSubmit, prevPage } = props;

  const fieldNames = [
    'address1',
    'address2',
    'city',
    'postcode',
    'state-province-region',
    'country'
  ];
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="address1"
          type="text"
          component={RenderField}
          label="Address Line 1 *"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="address2"
          type="text"
          component={RenderField}
          label="Address Line 2"
        />
      </div>
      <div className="form-group">
        <Field
          name="city"
          type="text"
          component={RenderField}
          label="City *"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="postcode"
          type="text"
          component={RenderField}
          label="Postcode *"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="state-province-region"
          type="text"
          component={RenderField}
          label="County / Province"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <Field
          name="country"
          type="text"
          component={RenderSelect}
          options={props.options}
          labelKey={'name'}
          valueKey={'id'}
          label="Country *"
          validate={validations.required}
        />
      </div>
      <div className="row">
        <div className="order-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            onClick={() => props.prevPage()}
            btnColor="rgba(0, 0, 0, 0.2)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="rgba(0,0,0,0.6)"
            fontSize={14}>
            <i className="fas fa-chevron-left" /> BACK
          </Button>
        </div>
        <div className="order-1 order-md-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={14}>
            NEXT <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
      {!props.valid && props.submitFailed && (
        <Fields names={fieldNames} component={RenderAllErrors} />
      )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page2);
