import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import Disqus from 'disqus-react';
import Bubble from '../../components/Bubble';
import Http from '../../constants/Http';
import { Link } from 'react-router-dom';
import moment from 'moment';

import NewsItem from './NewsItem';
import * as validations from '../../Validation';
import Loading from '../../components/Loading';

import RenderField from '../../components/Fields/renderField';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Meta from '../../components/Meta';
import GreyCircleBottom from '../../components/Circles/greyBottomCircle';
import QandA from '../../components/QandA';
import Button from '../../components/Stuff/Button';

import { emailSignup } from '../../modules/contact';

import './index.scss';

class NewsList extends React.Component {
  state = {
    data: null
  };

  componentWillMount() {
    this.getNews('/articles');
  }

  submit = values => {
    this.props.dispatch(emailSignup(values));
  };

  loadMore = url => {
    const data = this.state.data.data;
    Http.get(url)
      .then(res => {
        this.setState({
          data: {
            ...res.data,
            data: [...data, ...res.data.data]
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  getNews = url => {
    Http.get(url)
      .then(res => {
        this.setState({
          data: res.data
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    const disqusShortname = 'brigtonsbmtest';
    const disqusConfig = {
      url: '/news/1',
      identifier: 1,
      title: 'The New You for the New Year'
    };
    return (
      <Page classnames="news-page">
        <Meta title="News and Updates" type={'website'} />
        <section className={'page-header'}>
          <div className={'container'}>
            <PageHeader>
              <div className={'row justify-content-center w-100'}>
                <div className={'col-12 col-md-8 pl-0'}>
                  <h1>Insights, ideas and stories</h1>
                  {props.match.params.category ? (
                    <h2>
                      Category:{' '}
                      {props.match.params.category.charAt(0).toUpperCase()}
                      {props.match.params.category.substr(1)}
                    </h2>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </PageHeader>

            <div className={'d-none d-lg-inline'}>
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={500}
                delay={'-8s'}
                backgroundColor={'#0887E0'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'8s'}
                right={150}
                widthHeight={500}
                delay={'-10s'}
                backgroundColor={'#93CCFD'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={200}
                widthHeight={300}
                delay={'-13s'}
                backgroundColor={'#50DCF6'}
                animations={{
                  WebkitAnimation: 'move-up-right 15s linear infinite',
                  MozAnimation: 'move-up-right 15s linear infinite',
                  msAnimation: 'move-up-right 15s linear infinite',
                  OAnimation: 'move-up-right 15s linear infinite',
                  animation: 'move-up-right 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={200}
                delay={'-3s'}
                backgroundColor={'#4097F8'}
                animations={{
                  WebkitAnimation: 'move-up-left 15s linear infinite',
                  MozAnimation: 'move-up-left 15s linear infinite',
                  msAnimation: 'move-up-left 15s linear infinite',
                  OAnimation: 'move-up-left 15s linear infinite',
                  animation: 'move-up-left 15s linear infinite'
                }}
              />
            </div>
            <div className={'d-lg-none'}>
              <span className={'homepage topRight blue-15 twoThirds'} />
            </div>
          </div>
        </section>
        <span
          className={
            'homepage left blue-15 clockwise-8 topHalf d-none d-md-inline'
          }
        />
        <div className="container">
          {this.state.data !== null ? (
            this.state.data.data.length !== 0 ? (
              this.state.data.data.map((a, i) => <NewsItem a={a} i={i} />)
            ) : (
              <h3 className={'text-center'}>No Articles Found</h3>
            )
          ) : (
            <Loading />
          )}
          {this.state.data !== null && this.state.data.links.next !== null ? (
            <div className="col-12 text-center spacer-150 spacer-bottom-150">
              <Button
                width={280}
                height={60}
                btnColor="rgba(77, 199, 117, 1)"
                boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                textColor="white"
                fontSize={14}
                onClick={() => this.loadMore(this.state.data.links.next)}>
                SHOW MORE
              </Button>
            </div>
          ) : null}
        </div>
        {false && (
          <GreyCircleBottom>
            <div className="container pt-5">
              <div className="row pt-5 justify-content-center text-center">
                <div className="col-12 spacer-150">
                  <h4>Subscribe</h4>
                </div>
                <div className="col-12 col-md-6 spacer-50">
                  <p>
                    Free emails every month, and we promise never to give your
                    details to anyone else.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-6 spacer-75">
                  {/*// Begin Mailchimp Signup Form*/}
                  {/*<link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />*/}
                  <div
                    id="mc_embed_signup"
                    style={{
                      clear: 'left',
                      font: '14px Helvetica,Arial,sans-serif',
                      width: '100%'
                    }}>
                    <form
                      action="https://gbscorporate.us20.list-manage.com/subscribe/post?u=83dedf655d85b38f7cfdc3184&amp;id=d2ec9efda5"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_blank"
                      novalidate>
                      <div id="mc_embed_signup_scroll">
                        <input
                          type="email"
                          name="EMAIL"
                          class="email"
                          id="mce-EMAIL"
                          placeholder="email address"
                          required
                        />

                        <div
                          style={{
                            position: 'absolute',
                            left: -5000
                          }}
                          aria-hidden="true">
                          <input
                            type="text"
                            name="b_83dedf655d85b38f7cfdc3184_d2ec9efda5"
                            tabindex="-1"
                            value=""
                          />
                        </div>
                        <Button
                          width={100}
                          style={{ display: 'inline' }}
                          height={60}
                          btnColor="rgba(77, 199, 117, 1)"
                          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                          textColor="white"
                          fontSize={14}>
                          SIGN UP
                        </Button>
                      </div>
                    </form>
                  </div>

                  {/*<Form onSubmit={props.handleSubmit((values) => this.submit(values))}>*/}
                  {/*  <Field*/}
                  {/*    name="email"*/}
                  {/*    type="email"*/}
                  {/*    defaultValue={""}*/}
                  {/*    placeholder={'Your Email Address'}*/}
                  {/*    component={RenderField}*/}
                  {/*    validate={validations.required}*/}
                  {/*  />*/}
                  {/*  <Button*/}
                  {/*    width={100}*/}
                  {/*    style={{ display: 'inline' }}*/}
                  {/*    height={60}*/}
                  {/*    btnColor="rgba(77, 199, 117, 1)"*/}
                  {/*    boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"*/}
                  {/*    textColor="white"*/}
                  {/*    fontSize={14}>*/}
                  {/*    SIGN UP*/}
                  {/*  </Button>*/}
                  {/*</Form>*/}
                </div>
              </div>
            </div>
          </GreyCircleBottom>
        )}
      </Page>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'subForm'
  })(NewsList)
);
