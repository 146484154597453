import React from 'react';
import { reduxForm, Form, Field, Fields } from 'redux-form';
import { Alert, Label } from 'reactstrap';
import Button from '../../../components/Stuff/Button';
import * as validations from '../../../Validation';
import RenderSelect from '../../../components/Fields/renderSelect';
import RenderAllErrors from '../../../components/RenderAllErrors';

const Page4 = props => {
  const { handleSubmit, prevPage } = props;

  const fieldNames = ['education'];
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <p>
          Please give details of all examinations passed and professional
          qualifications obtained, including dates taken, subject, level, the
          name of the examining organisation, and grade obtained.
        </p>
        <Field
          name="education"
          type="text"
          component="textarea"
          validate={validations.required}
        />
      </div>

      <div className="row">
        <div className="order-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            onClick={() => props.prevPage()}
            btnColor="rgba(0, 0, 0, 0.2)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="rgba(0,0,0,0.6)"
            fontSize={14}>
            <i className="fas fa-chevron-left" /> BACK
          </Button>
        </div>
        <div className="order-1 order-md-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={14}>
            NEXT <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
      {!props.valid && props.submitFailed && (
        <Fields names={fieldNames} component={RenderAllErrors} />
      )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page4);
