import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Button } from 'reactstrap';

import renderField from '../../../../components/Fields/renderField';
import RenderSelect from '../../../../components/Fields/renderSelect';
import * as validations from '../../../../Validation';

// const renderSelect = props => (
//   <div>
//     <select {...props.input}>{props.children}</select>
//     {props.touched && props.error && <div className="error">{props.error}</div>}
//   </div>
// );

const PaymentDetails = props => (
  <div className={'mt-5'}>
    <FormGroup className={'col-8 mt-4'}>
      {props.paymentMethod !== null &&
        <React.Fragment>
      <Label>Payment Method</Label>
      <Field
        name={'payment_method'}
        component={RenderSelect}
        options={props.paymentMethod}
        valueKey={"id"}
        labelKey={"name"}
        validate={validations.required}
        />
        </React.Fragment>
      }
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <Label>Discount Code</Label>
      <Field name={'promo_code'} component={renderField} noLabel />
    </FormGroup>
    <FormGroup className={"col-8 mt-4"}>
      <Button className={'btn btn-dashboard float-left mb-4'}>Update</Button>
    </FormGroup>
  </div>
);

export default PaymentDetails;
