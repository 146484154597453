import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field, formValueSelector, change } from 'redux-form';
import { Alert, Label } from 'reactstrap';
import Button from '../../../components/Stuff/Button';
import * as validations from '../../../Validation';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({document: reader.result, name: `${new Date()}_${file.name}` });
    reader.onerror = error => reject(error);
  })
};

const uploadFile = (event, props) => {
  getBase64(event.target.files[0]).then(res => {
    props.dispatch(change('wizard', 'document', res));
  })
};

const Page7 = props => {
  const { handleSubmit, prevPage, coursePDF, submitting } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="radio-wrapper">
          <div className="radio-containter">
            <Field
              name="terms1"
              component="input"
              type="checkbox"
              value="terms1"
              className="radio"
              validate={validations.required}
            />
          </div>
          <div className="text">
            By ticking this box you are in agreement with the choice of payment
            method and the{' '}
            <a href="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </a>{' '}
            listed on the website. <br />{' '}
            <strong>Tick the box to confirm the above statement</strong>
          </div>
        </label>
      </div>
      <div className="form-group">
        <label className="radio-wrapper">
          <div className="radio-containter">
            <Field
              name="terms2"
              component="input"
              type="checkbox"
              value="terms2"
              className="radio"
              validate={validations.required}
            />
          </div>
          <div className="text">
            All data collected by completing this form will be processed in line
            with the General Data Protection Regulations (GDPR) and will only be
            shared with your accrediting body. <br />{' '}
            <strong>Tick the box to confirm the above statement</strong>
          </div>
        </label>
      </div>
      
      {/*<div className={"form-group"}>*/}
      {/*  <Label className={"file-upload-label"} htmlFor={"file-upload"}>Upload ID Document</Label>*/}
      {/*  <input className={"file-upload"} accept={'application/pdf, images/*'} id={"file-upload"} type={'file'} onChange={(event) => uploadFile(event, props)} hidden />*/}
      {/*  <Field*/}
      {/*    name={"document"}*/}
      {/*    component={"input"}*/}
      {/*    hidden*/}
      {/*  />*/}
      {/*  {coursePDF === null || coursePDF === undefined || coursePDF === "" ?*/}
      {/*    <p><b style={{ fontWeight: 800, fontSize: 18 }}>(Optional)</b> Please upload ID, e.g. Passport, Driving Licence etc</p>*/}
      {/*    :*/}
      {/*    <div className={"course-pdf"}>*/}
      {/*      <div className={"file-name"}>{coursePDF && coursePDF.name}</div>*/}
      {/*      <div className={"cross"} onClick={() => {*/}
      {/*        props.dispatch(change('wizard', 'document', null ));*/}
      {/*        document.getElementById("file-upload").value = "";*/}
      {/*      }}><i className={"fas fa-times"} /></div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*</div>*/}
      <div
        className="row">
        <div className="order-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            onClick={() => props.prevPage()}
            btnColor="rgba(0, 0, 0, 0.2)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="rgba(0,0,0,0.6)"
            fontSize={14}>
            <i className="fas fa-chevron-left" /> BACK
          </Button>
        </div>
        <div className="order-1 order-md-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            disabled={submitting}
            fontSize={14}>
            {submitting ? 'Submitting...' : 'SUBMIT APPLICATION'} <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
      {!props.valid && props.submitFailed && (
        <Alert color="danger" className="form-errors">
          There are errors in your form. Please check you have completed all
          required fields.
        </Alert>
      )}
    </Form>
  );
};

const selector = formValueSelector('wizard');

const mapStateToProps = state => ({
  terms2: selector(state, 'terms2'),
  terms1: selector(state, 'terms1'),
  coursePDF: selector(state, 'document'),
  submitting: state.apply.submitting,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Page7)
);
