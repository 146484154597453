import React from 'react';
import { reduxForm, Form, Field, change, Fields } from 'redux-form';
import { Alert, Label } from 'reactstrap';
import Button from '../../../components/Stuff/Button';
import RenderSelect from '../../../components/Fields/renderSelect';
import renderField from '../../../components/Fields/renderField';
import * as validations from '../../../Validation';
import DatePicker from 'react-datepicker/es/index';
import { getAwardType } from '../../../modules/courses';
import RenderAllErrors from '../../../components/RenderAllErrors';

const sourceOptions = [
  {
    value: 1,
    label: 'Reed'
  },
  {
    value: 4,
    label: 'Twitter'
  },
  {
    value: 10,
    label: 'CoursesOnline'
  },
  {
    value: 6,
    label: 'Google'
  },
  {
    value: 3,
    label: 'Facebook'
  },
  {
    value: 5,
    label: 'LinkedIn'
  },
  {
    value: 7,
    label: 'Friend referral'
  },
  {
    value: 8,
    label: 'GBS blog'
  },
  {
    value: 9,
    label: 'Other'
  }
];

const DatePickerField = props => {
  return (
    <DatePicker
      selected={props.input.value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat={'DD / MM / YYYY'}
      onChange={props.input.onChange}
    />
  );
};

const Page5 = props => {
  const { handleSubmit, prevPage } = props;

  const fieldNames = [
    'work-experience',
    'employed_from-dd',
    'employed_from-mm',
    'employed_to-yyyy',
    'current_job_role'
  ];
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <p>
          Please give details of your employment experience, including
          Employers' names and addresses, your job title and main duties, dates
          and reasons for leaving (where applicable).
        </p>
        <Field
          name="work-experience"
          type="text"
          component="textarea"
          validate={validations.required}
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-12 mb-0">
            <Label>Currently employed?</Label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>
              <Field
                name="employed"
                component="input"
                type="checkbox"
                value="employed"
                className="radio"
              />
              Yes
            </label>
          </div>
        </div>
      </div>
      <div className={'form-group'}>
        <div className="row">
          <div className="col-12 date-of-birth-custom mb-3">
            <div className="row">
              <div className="col-12 mb-0">
                <Label>Employed From</Label>
              </div>
              <div className="col">
                <Field
                  name="employed_from-dd"
                  component={renderField}
                  noLabel
                  placeholder={'DD'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="employed_from-mm"
                  component={renderField}
                  noLabel
                  placeholder={'MM'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="employed_from-yyyy"
                  component={renderField}
                  noLabel
                  placeholder={'YYYY'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
            </div>
          </div>
          <div className="col-12 date-of-birth-custom mb-3">
            <div className="row">
              <div className="col-12 mb-0">
                <Label>Employed To</Label>
              </div>
              <div className="col">
                <Field
                  name="employed_to-dd"
                  component={renderField}
                  noLabel
                  placeholder={'DD'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="employed_to-mm"
                  component={renderField}
                  noLabel
                  placeholder={'MM'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="employed_to-yyyy"
                  component={renderField}
                  noLabel
                  placeholder={'YYYY'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              name={'current_job_role'}
              label={'Current Job Role'}
              component={renderField}
              type={'text'}
              validate={validations.required}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <Label>Where did you hear about us?</Label>
        <Field
          name="source"
          label="Where did you hear about us?"
          component={RenderSelect}
          options={sourceOptions}
          validate={validations.required}
        />
      </div>
      <div className="row">
        <div className="order-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            onClick={() => props.prevPage()}
            btnColor="rgba(0, 0, 0, 0.2)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="rgba(0,0,0,0.6)"
            fontSize={14}>
            <i className="fas fa-chevron-left" /> BACK
          </Button>
        </div>
        <div className="order-1 order-md-2 col-12 col-md-6">
          <Button
            width={'100%'}
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={14}>
            NEXT <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
      {!props.valid && props.submitFailed && (
        <Fields names={fieldNames} component={RenderAllErrors} />
      )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onChange: (values, dispatch, props, prevValues) => {
    dispatch(
      change(
        'wizard',
        'employed_from',
        `${values['employed_from-yyyy']}/${values['employed_from-mm']}/${values['employed_from-dd']}`
      )
    );
    dispatch(
      change(
        'wizard',
        'employed_to',
        `${values['employed_to-yyyy']}/${values['employed_to-mm']}/${values['employed_to-dd']}`
      )
    );
  }
})(Page5);
