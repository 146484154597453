import axios from 'axios';
// if (window.location.hostname === 'localhost') {
//   axios.defaults.baseURL = 'https://bsbmapi.stagelab.co.uk';
// }
// if (window.location.hostname === 'bsbm.stagelab.co.uk') {
//   axios.defaults.baseURL = 'https://bsbmapi.stagelab.co.uk';
// }
// if (window.location.hostname === 'www.brightonsbm.com' || window.location.hostname === "brightonsbm.com") {
axios.defaults.baseURL = 'https://api.brightonsbm.com';
// }

if (window.localStorage.getItem('token') !== undefined) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${window.localStorage.getItem('token')}`;
}

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common[
  'Link'
] = `<https://brightonsbm.com${window.location.pathname}>; rel="canonical"`;

export default axios;
