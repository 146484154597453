import React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

import './index.scss';

class Accordion extends React.Component {
  state = {
    isOpen: 0
  };

  changeOpen = num => {
    if (this.state.isOpen !== num) {
      this.setState({
        isOpen: num
      });
    } else {
      this.setState({
        isOpen: 9999999
      });
    }
  };

  render() {
    const { isOpen } = this.state;
    let awardName = '';
    if (this.props.category == 'hnc-hnd') {
      awardName = '';
    } else if (this.props.category == 'degree') {
      awardName = '';
    }
    return (
      <div className="accordion-wrapper">
        {this.props.data.award.length > 0 && (
          <React.Fragment>
            <Card>
              <CardHeader
                style={{ backgroundColor: '#248BF8', color: 'white' }}
                onClick={() => this.changeOpen(0)}>
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10">
                    <h5 className="text-white" style={{ fontSize: 24 }}>
                      Award in {this.props.data.course_name}
                    </h5>
                    <p>
                      {this.props.data.tut_time ?
                        `Learners must complete at least one unit to a minimum of ${this.props.data.award[0].tut_time} TUT (Total unit time) hours to achieve this qualification. `
                        :
                        `Consists of ${this.props.data.award[0].modules.length}${' '}
                      unit(s) and has ${this.props.data.award[0].credits === 0 ? "no transferable credit value" :  `a transferable credit value of a minimum
                      of ${this.props.data.award[0].credits} credits.`}`
                      }
                    </p>
                  </div>
                  <div className="col text-center">
                    {isOpen === 0 ? (
                      <img
                        src="/images/icons/arrow-up-white.svg"
                        alt="close arrow"
                      />
                    ) : (
                      <img
                        src="/images/icons/arrow-down-white.svg"
                        alt="open arrow"
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen === 0}>
                <Card body>
                  <div className="course-content-box">
                    <p>
                      COURSE CONTENT ({this.props.data.award[0].modules.length}{' '}
                      UNIT
                      {this.props.data.award[0].modules.length > 1 ? 'S' : ''})
                    </p>
                  </div>
                  {this.props.data.award[0].modules.map((m, i) => (
                    <div className="course-unit-box">
                      <a
                        href={`/courses/${this.props.match.params.category}/${
                          this.props.match.params.course
                        }/unit/${m.slug}`}
                        target="_blank">
                        {m.name}
                      </a>
                    </div>
                  ))}
                </Card>
              </Collapse>
            </Card>
          </React.Fragment>
        )}
        {this.props.data.certificate.length > 0 && (
          <React.Fragment>
            <Card>
              <CardHeader
                style={{ backgroundColor: '#248BF8', color: 'white' }}
                onClick={() => this.changeOpen(1)}>
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10">
                    <h5 className="text-white" style={{ fontSize: 24 }}>
                      Certificate in {this.props.data.course_name}
                    </h5>
                    <p>
                      {this.props.data.tut_time ?
                        `Learners must complete at least one unit to a minimum of ${this.props.data.certificate[0].tut_time} TUT (Total unit time) hours to achieve this qualification. `
                        :
                        `Consists of ${this.props.data.certificate[0].modules.length}${' '}
                      unit(s) and has ${this.props.data.certificate[0].credits === 0 ? "no transferable credit value" :  `a transferable credit value of a minimum
                      of ${this.props.data.certificate[0].credits} credits.`}`
                      }
                    </p>
                  </div>
                  <div className="col text-center">
                    {isOpen === 1 ? (
                      <img
                        src="/images/icons/arrow-up-white.svg"
                        alt="close arrow"
                      />
                    ) : (
                      <img
                        src="/images/icons/arrow-down-white.svg"
                        alt="open arrow"
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen === 1}>
                <Card body>
                  <div className="course-content-box">
                    <p>
                      COURSE CONTENT (
                      {this.props.data.certificate[0].modules.length} UNIT
                      {this.props.data.certificate[0].modules.length > 1
                        ? 'S'
                        : ''}
                      )
                    </p>
                  </div>
                  {this.props.data.certificate[0].modules.map((m, i) => (
                    <div className="course-unit-box">
                      <a
                        href={`/courses/${this.props.match.params.category}/${
                          this.props.match.params.course
                        }/unit/${m.slug}`}
                        target="_blank">
                        {m.name}
                      </a>
                    </div>
                  ))}
                </Card>
              </Collapse>
            </Card>
          </React.Fragment>
        )}
        {this.props.data.diploma.length > 0 && (
          <React.Fragment>
            <Card>
              <CardHeader
                style={{ backgroundColor: '#248BF8', color: 'white' }}
                onClick={() => this.changeOpen(2)}>
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10">
                    <h5 className="text-white" style={{ fontSize: 24 }}>
                      Diploma in {this.props.data.course_name}
                    </h5>
                    <p>
                      {this.props.data.tut_time ?
                        `Learners must complete at least one unit to a minimum of ${this.props.data.diploma[0].tut_time} TUT (Total unit time) hours to achieve this qualification. `
                        :
                        `Consists of ${this.props.data.diploma[0].modules.length}${' '}
                      unit(s) and has ${this.props.data.diploma[0].credits === 0 ? "no transferable credit value" :  `a transferable credit value of a minimum
                      of ${this.props.data.diploma[0].credits} credits.`}`
                      }
                    </p>
                  </div>
                  <div className="col text-center">
                    {isOpen === 2 ? (
                      <img
                        src="/images/icons/arrow-up-white.svg"
                        alt="close arrow"
                      />
                    ) : (
                      <img
                        src="/images/icons/arrow-down-white.svg"
                        alt="open arrow"
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen === 2}>
                <Card body>
                  <div className="course-content-box">
                    <p>
                      COURSE CONTENT (
                      {this.props.data.diploma[0].modules.length} UNIT
                      {this.props.data.diploma[0].modules.length > 1 ? 'S' : ''}
                      )
                    </p>
                  </div>
                  {this.props.data.diploma[0].modules.map((m, i) => (
                    <div className="course-unit-box">
                      <a
                        href={`/courses/${this.props.match.params.category}/${
                          this.props.match.params.course
                        }/unit/${m.slug}`}
                        target="_blank">
                        {m.name}
                      </a>
                    </div>
                  ))}
                </Card>
              </Collapse>
            </Card>
          </React.Fragment>
        )}
        {this.props.data.extended_diploma.length > 0 && (
          <React.Fragment>
            <Card>
              <CardHeader
                style={{ backgroundColor: '#248BF8', color: 'white' }}
                onClick={() => this.changeOpen(3)}>
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10">
                    <h5 className="text-white" style={{ fontSize: 24 }}>
                      Extended Diploma in {this.props.data.course_name}
                    </h5>
                    <p>
                      {this.props.data.tut_time ?
                        `Learners must complete at least one unit to a minimum of ${this.props.data.extended_diploma[0].tut_time} TUT (Total unit time) hours to achieve this qualification. `
                        :
                        `Consists of ${this.props.data.extended_diploma[0].modules.length}${' '}
                      unit(s) and has ${this.props.data.extended_diploma[0].credits === 0 ? "no transferable credit value" :  `a transferable credit value of a minimum
                      of ${this.props.data.extended_diploma[0].credits} credits.`}`
                      }
                    </p>
                  </div>
                  <div className="col text-center">
                    {isOpen === 3 ? (
                      <img
                        src="/images/icons/arrow-up-white.svg"
                        alt="close arrow"
                      />
                    ) : (
                      <img
                        src="/images/icons/arrow-down-white.svg"
                        alt="open arrow"
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen === 3}>
                <Card body>
                  <div className="course-content-box">
                    <p>
                      COURSE CONTENT (
                      {this.props.data.extended_diploma[0].modules.length} UNIT
                      {this.props.data.extended_diploma[0].modules.length > 1
                        ? 'S'
                        : ''}
                      )
                    </p>
                  </div>
                  {this.props.data.extended_diploma[0].modules.map((m, i) => (
                    <div className="course-unit-box">
                      <a
                        href={`/courses/${this.props.match.params.category}/${
                          this.props.match.params.course
                        }/unit/${m.slug}`}
                        target="_blank">
                        {m.name}
                      </a>
                    </div>
                  ))}
                </Card>
              </Collapse>
            </Card>
          </React.Fragment>
        )}
        {this.props.data.other.length > 0 && (
          <React.Fragment>
            <Card>
              <CardHeader
                style={{ backgroundColor: '#248BF8', color: 'white' }}
                onClick={() => this.changeOpen(0)}>
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10">
                    <h2 className="text-white" style={{ fontSize: 24 }}>
                      {awardName} {this.props.data.course_name}
                    </h2>
                    <p>
                      {this.props.data.tut_time ?
                        `Learners must complete at least one unit to a minimum of ${this.props.data.other[0].tut_time} TUT (Total unit time) hours to achieve this qualification. `
                        :
                        `Consists of ${this.props.data.other[0].modules.length}${' '}
                      unit(s) and has ${this.props.data.other[0].credits === 0 ? "no transferable credit value" :  `a transferable credit value of a minimum
                      of ${this.props.data.other[0].credits} credits.`}`
                      }
                    </p>
                  </div>
                  <div className="col text-center">
                    {isOpen === 0 ? (
                      <img
                        src="/images/icons/arrow-up-white.svg"
                        alt="close arrow"
                      />
                    ) : (
                      <img
                        src="/images/icons/arrow-down-white.svg"
                        alt="open arrow"
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen === 0}>
                <Card body>
                  <div className="course-content-box">
                    <p>
                      COURSE CONTENT ({this.props.data.other[0].modules.length}{' '}
                      UNIT
                      {this.props.data.other[0].modules.length > 1 ? 'S' : ''})
                    </p>
                  </div>
                  {this.props.data.other[0].modules.map((m, i) => (
                    <div className="course-unit-box">
                      <a
                        href={`/courses/${this.props.match.params.category}/${
                          this.props.match.params.course
                        }/unit/${m.slug}`}
                        target="_blank">
                        {m.name}
                      </a>
                    </div>
                  ))}
                </Card>
              </Collapse>
            </Card>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Accordion;
