import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';

import NavAbout from '../../components/Navbar/navAbout';

import './index.css';
import NavMobile from '../../components/Navbar/navMobile';

const inlineField = ({
  label,
  input,
  type,
  placeholder,
  meta: { touched, error }
}) => (
  <div className={'row justify-content-center align-items-center'}>
    <div className={'col-4'}>
      <Label>{label}</Label>
    </div>
    <div className={'col'}>
      <input {...input} placeholder={placeholder} type={type} />
    </div>
    {touched && error && <span>{error}</span>}
  </div>
);

class CreatePassword extends React.Component {
  state = {
    isTop: true,
    desktop: true
  };

  componentWillMount() {
    this.checkScroll();
    this.checkSize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this));
    window.addEventListener('scroll', this.checkScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize());
    window.removeEventListener('scroll', this.checkScroll());
  }

  checkScroll = () => {
    const scroll = window.scrollY > 100;
    if (scroll !== this.state.isTop) {
      this.setState({
        isTop: scroll
      });
    }
  };

  checkSize = () => {
    const width = window.innerWidth > 992;
    if (width !== this.state.desktop) {
      this.setState({
        desktop: width
      });
    }
  };

  onSubmit = values => {
  };

  render() {
    const { isTop, desktop } = this.state;
    const { props } = this;
    const { handleSubmit } = props;
    return (
      <div className={'pageWrapper createPasswordWrapper'}>
        {!!!desktop ? (
          <NavMobile isTop={isTop} desktop={desktop} />
        ) : (
          <NavAbout isTop={isTop} desktop={desktop} />
        )}
        <div className={'container p-spacer-200'}>
          <div className={'row justify-content-center align-items-center'}>
            <div className={'col-8 text-center'}>
              <p className={'title'}>Create your password</p>
              <Form onSubmit={handleSubmit(this.onSubmit)}>
                <div
                  className={
                    'row p-spacer-100 justify-content-center align-items-center'
                  }>
                  <FormGroup className={'col-10'}>
                    <Field
                      name={'password'}
                      label={'Password'}
                      component={inlineField}
                      type={'password'}
                    />
                  </FormGroup>
                  <FormGroup className={'col-10 mt-2'}>
                    <Field
                      name={'passwordConfirm'}
                      label={'Confirm Password'}
                      component={inlineField}
                      type={'password'}
                    />
                  </FormGroup>
                </div>
                <div className={'row justify-content-center pt-4 pb-4'}>
                  <FormGroup className={'col-4 justify-content-center'}>
                    <Button
                      className={
                        'btn btn-dashboard btn-block clearfix mx-auto'
                      }>
                      Submit
                    </Button>
                  </FormGroup>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'passwordForm'
  })(CreatePassword)
);
