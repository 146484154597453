import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReactGA from 'react-ga'

import NavMinimal from '../../components/Navbar/navMinimal';

class Success extends React.Component {
  state = {
    isTop: true,
    desktop: true
  };

  componentWillMount() {
    this.checkScroll();
    this.checkSize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this));
    window.addEventListener('scroll', this.checkScroll.bind(this));
    window.setTimeout(() => {
      this.props.dispatch(push('/'));
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize());
    window.removeEventListener('scroll', this.checkScroll());
  }

  checkScroll = () => {
    const scroll = window.scrollY > 100;
    if (scroll !== this.state.isTop) {
      this.setState({
        isTop: scroll
      });
    }
  };

  checkSize = () => {
    const width = window.innerWidth > 992;
    if (width !== this.state.desktop) {
      this.setState({
        desktop: width
      });
    }
  };

  render() {
    const { isTop, desktop } = this.state;
    return (
      <div className="successWrapper pageWrapper">
        <NavMinimal isTop={isTop} desktop={desktop} />
        <script dangerouslySetInnerHTML={{ __html: '<script async src="https://www.googletagmanager.com/gtag/js?id=AW-1071775920"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'AW-1071775920\'); </script><script> gtag(\'event\', \'conversion\', {\'send_to\': \'AW-1071775920/LzqqCOfw6ZQBELCBiP8D\'}); </script>' }}></script>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-12 spacer-250">
              <h3>All Done!</h3>
            </div>
            <div className="col-12 mt-3 spacer-bottom-250">
              <p>
                Thank you for your application. We will be in touch shortly.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Success);
