import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { FormGroup, Label, Button } from 'reactstrap';

import renderField from '../../../../components/Fields/renderField';

const FileArray = ({ fields }) =>
  fields.map((file, i) => (
    <div className={'row'}>
      <div className={'col'}>
        <i className={'fa fa-file-pdf-o'} />
      </div>
      <div className={"col-8"}>
        <h5>{fields.get(i).fileName}</h5>
        <p>{fields.get(i).uploadDate}</p>
      </div>
      <div className={"col"}>
        <Button onClick={() => fields.remove(i)}><i className={'fa fa-close'} /></Button>
      </div>
    </div>
  ));

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({document: reader.result, name: `${new Date()}_${file.name}` });
    reader.onerror = error => reject(error);
  })
};

const uploadFile = (event, props) => {
  getBase64(event.target.files[0]).then(res => {
    props.dispatch(change('applicationDetailsForm', 'document', res));
  })
};

const Files = props => (
  <div className={'mt-5'}>
    <div className="row">
    <FormGroup className={'col-8 mt-4'}>
      <FieldArray name={'files'} component={FileArray} />
    </FormGroup>
    <FormGroup className={'col-8 mt-4'}>
      <label htmlFor={"fileupload"} className={"btn btn-dashboard float-left mb-4 pt-2"} >Upload File</label>
      <input type={"file"} hidden id={'fileupload'} accept={'application/pdf, images/*'} onChange={(event) => uploadFile(event, props)} />
    </FormGroup>
    </div>
    <div className="row">
    <FormGroup className="col-12 mt-4">
      {props.document === null || props.document === undefined || props.document === "" ?
        <p>Please upload ID, e.g. Passport, Driving Licence etc</p>
        :
        <div className={"course-pdf w-100"} style={{ height: 'auto' }}>
          <div className={"file-name"}>{props.document && props.document.name}</div>
          <div className={"cross"} onClick={() => {
            props.dispatch(change('applicationDetailsForm', 'document', null ));
            document.getElementById("fileupload").value = "";
          }}><i className={"fas fa-times"} /></div>
        </div>
      }
    </FormGroup>
      <FormGroup className={'col-8 mt-4'}>
      {props.document === null || props.document === undefined || props.document === "" ? null :
          <Button className={'btn btn-dashboard float-left mb-4'}>Update</Button>
      }
      </FormGroup>
    </div>
  </div>
);

const selector = formValueSelector('applicationDetailsForm');

const mapStateToProps = state => ({
  document: selector(state, 'document')
});

export default connect(mapStateToProps)(Files);
