import React from 'react';
import { Link } from 'react-router-dom';

import { Card, UncontrolledCollapse } from 'reactstrap';

import './index.scss';
import Button from '../Stuff/Button';

const QandAHomepage = props => (
  <div className="container q-a">
    <div className="row pt-5 justify-content-center">
      <div className="col-12 spacer-50" data-aos="fade-up">
        <p className={`title ${props.dark ? '' : 'text-white'} spacer-100`}>
          In case you're wondering
        </p>
      </div>
    </div>
    <div className="row spacer-100">
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq8"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                What are online business courses?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq8">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  Online business courses are a great way to expand and enhance
                  your qualifications which can help in kick-starting your
                  career. They have the added benefit of flexibility, allowing
                  you to do the course(s) in your own time. Many companies will
                  pay for their employees to take online business courses in a
                  variety of disciplines to improve their employees’ skill sets
                  and make them more valuable members of their team. The
                  flexibility online courses gives you means that you can fit
                  them around your life, allowing you to learn at your own pace.
                  They are a superb way of gaining new knowledge or building
                  upon your existing understanding of a discipline.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq9"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                What courses can I do online?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq9">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  With our range of online business courses there is sure to be
                  a course right for you. We have online courses in project
                  management, finance, finance and accounting, leadership, human
                  resources, marketing (including Social Media) and much more
                  besides. You are able to access all the tools, resources and
                  information you require at any time and are also given a
                  personal tutor to help guide you with your course(s). Our
                  online courses are cheaper than the traditional route through
                  higher education, or by attending a business school, and our
                  courses are fully accredited with the same status that will
                  equip you, or your employees, with the professional skills
                  needed to succeed in the business world.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq6"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                Are qualifications recognised in the UK and Internationally?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq6">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  Yes, both the CMI and Pearson qualifications are recognised in
                  the UK and internationally by universities and colleges. They
                  are regulated qualifications on the RQF (Regulated
                  Qualifications Framework) regulated by Ofqual.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faqpage16"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <p
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                When can I start?
              </p>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faqpage16">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  You can start your online course on any Monday throughout the
                  year (excluding Bank Holidays).
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq7"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                What are the differences between an award, certificate, diploma
                and extended diploma?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq7">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  The difference is the number of credit points and the number
                  of assignments required for completion. See specific course
                  outlines for this information.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq1"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                Do I need to attend any classes?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq1">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  All our courses are self-study online distance learning
                  courses. No classroom attendance is required.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq3"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <p
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                Can my employer pay my fees?
              </p>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq3">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  We have a number of Employer funded students and all we
                  require is the name of the sponsor on your application form
                  and the contact details of your accounts department which you
                  will need to send to the admissions team via email to{' '}
                  <a
                    href="mailto:admissions@brightonsbm.com"
                    style={{ fontWeight: 600 }}>
                    admissions@brightonsbm.com
                  </a>{' '}
                  together with any purchase order number to be stated on the
                  invoice.
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq4"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <h5
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                Can I complete a Top-Up CMI Qualification with Brighton School
                of Business and Management?
              </h5>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq4">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  Yes. If you have studied with another organisation, and now
                  want to complete a “top-up” course with BSBM please email the
                  enquiries team at{' '}
                  <a href="mailto:enquiries@brightonsbm.com">
                    enquiries@brightonsbm.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
      {/*<div className="col-12 col-md-10 ml-auto mr-auto mt-4">*/}
      {/*<Card body>*/}
      {/*<div*/}
      {/*className="row align-items-center"*/}
      {/*id="faq2"*/}
      {/*style={{ cursor: 'pointer' }}>*/}
      {/*<div className="col-1">*/}
      {/*<p*/}
      {/*style={{*/}
      {/*fontSize: 32,*/}
      {/*fontWeight: 900,*/}
      {/*opacity: 0.25,*/}
      {/*marginBottom: 0,*/}
      {/*textAlign: 'right'*/}
      {/*}}>*/}
      {/*Q*/}
      {/*</p>*/}
      {/*</div>*/}
      {/*<div className="col">*/}
      {/*<h5*/}
      {/*className="text-left section-heading pl-3"*/}
      {/*style={{ fontSize: 16, marginBottom: 0 }}>*/}
      {/*Which organisation funds the loan?*/}
      {/*</h5>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*<UncontrolledCollapse toggler="#faq2">*/}
      {/*<div className="row">*/}
      {/*<div className="col-1">*/}
      {/*<p*/}
      {/*style={{*/}
      {/*fontSize: 32,*/}
      {/*fontWeight: 900,*/}
      {/*opacity: 0.25,*/}
      {/*marginBottom: 0,*/}
      {/*textAlign: 'right'*/}
      {/*}}>*/}
      {/*A*/}
      {/*</p>*/}
      {/*</div>*/}
      {/*<div className="col-10 mt-2">*/}
      {/*<p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>*/}
      {/*Brighton School of Business and Management Ltd, in partnership*/}
      {/*with Deko, are able to offer you interest free loans as an*/}
      {/*alternative method of payment for all our courses and*/}
      {/*qualifications. Founded in 2008, Deko is the UK’s fastest*/}
      {/*growing provider of point of sale consumer finance and*/}
      {/*authorised and regulated by the Financial Conduct Authority*/}
      {/*(Company Reference Number: 728646). Registered offices are at*/}
      {/*33 St Mary Axe, London, EC3A 8AG. For more information please*/}
      {/*refer to{' '}*/}
      {/*<a href="https://www.dekopay.com/support/" target="_blank">*/}
      {/*https://www.dekopay.com/support/*/}
      {/*</a>*/}
      {/*.*/}
      {/*</p>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</UncontrolledCollapse>*/}
      {/*</Card>*/}
      {/*</div>*/}
      <div className="col-12 col-md-10 ml-auto mr-auto mt-4">
        <Card body>
          <div
            className="row align-items-center"
            id="faq5"
            style={{ cursor: 'pointer' }}>
            <div className="col-1">
              <p
                style={{
                  fontSize: 32,
                  fontWeight: 900,
                  opacity: 0.25,
                  marginBottom: 0,
                  textAlign: 'right'
                }}>
                Q
              </p>
            </div>
            <div className="col">
              <p
                className="text-left section-heading pl-3"
                style={{ fontSize: 16, marginBottom: 0 }}>
                Are fees refundable?
              </p>
            </div>
          </div>
          <UncontrolledCollapse toggler="#faq5">
            <div className="row">
              <div className="col-1">
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 900,
                    opacity: 0.25,
                    marginBottom: 0,
                    textAlign: 'right'
                  }}>
                  A
                </p>
              </div>
              <div className="col-10 mt-2">
                <p className="pl-3" style={{ fontSize: 16, marginBottom: 0 }}>
                  All fees are non-refundable outside of the 14 day cancellation
                  period. For more information please view our{' '}
                  <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                  .
                </p>
              </div>
            </div>
          </UncontrolledCollapse>
        </Card>
      </div>
    </div>
  </div>
);

export default QandAHomepage;
