import React from 'react';

import { Card, UncontrolledCollapse } from 'reactstrap';

import './index.scss';

const QandA = props => (
  <div className="container q-a">
    <div className="row pt-5 justify-content-center">
      <div className="col-12 spacer-50" data-aos="fade-up">
        <p className={`title ${props.dark ? '' : 'text-white'} spacer-100`}>
          In case you're wondering
        </p>
      </div>
    </div>

    <div className="row spacer-100">
      {props.data &&
        props.data.length > 0 &&
        props.data.map((faq, index) => {
          return (
            <div
              className="col-12 col-md-10 ml-auto mr-auto mt-4"
              key={`faqcard_${index}`}>
              <Card body>
                <div
                  className="row align-items-center"
                  id={`faqcard_${index}`}
                  style={{ cursor: 'pointer' }}>
                  <div className="col-1">
                    <p
                      style={{
                        fontSize: 32,
                        fontWeight: 900,
                        opacity: 0.25,
                        marginBottom: 0,
                        textAlign: 'right'
                      }}>
                      Q
                    </p>
                  </div>
                  <div className="col">
                    <h5
                      className="text-left section-heading pl-3"
                      style={{ fontSize: 16, marginBottom: 0 }}>
                      {faq.question}
                    </h5>
                  </div>
                </div>
                <UncontrolledCollapse toggler={`faqcard_${index}`}>
                  <div className="row">
                    <div className="col-1">
                      <p
                        style={{
                          fontSize: 32,
                          fontWeight: 900,
                          opacity: 0.25,
                          marginBottom: 0,
                          textAlign: 'right'
                        }}>
                        A
                      </p>
                    </div>
                    <div className="col-10 mt-2">
                      <div
                        className="pl-3"
                        style={{ fontSize: 16, marginBottom: 0 }}
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                    </div>
                  </div>
                </UncontrolledCollapse>
              </Card>
            </div>
          );
        })}
    </div>
  </div>
);

export default QandA;
