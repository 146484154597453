import React from 'react';
import { Link } from 'react-router-dom';
import Disqus from 'disqus-react';

import moment from 'moment';

class NewsItem extends React.Component {
  render() {
    const { a, i } = this.props;
    const disqusShortname = 'brigtonsbmtest';
    const disqusConfig = {
      url: `/article/${a.slug}`,
      identifier: a.slug,
      title: a.title
    };
    return (
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-md-10 spacer-bottom-25 pb-5">
          <a href={`/news/${a.slug}`}>
            <div className={'row'}>
              {a.main_image !== null &&
                a.main_image.length !== 0 && (
                  <div className={'col-12 col-lg-4'}>
                    <img
                      src={a.main_image[0].secure_url}
                      alt={`Brighton School of Business and Management | ${a.title}`}
                      className={'img-fluid mb-3 mb-lg-0'}
                    />
                  </div>
                )}
              <div
                className={
                  a.main_image.length !== 0 && a.main_image !== null
                    ? 'col-12 col-lg-8'
                    : 'col-12'
                }>
                <h4
                  style={{ fontSize: 32, textAlign: 'left' }}
                  className="spacer-bottom-25">
                  {a.title}
                </h4>
                <span>
                  {a.published_date !== null && a.published_date !== undefined
                    ? moment(a.published_date.date).format('DD MMMM Y')
                    : moment(a.created_at.date).format('DD MMMM Y')}{' '}
                  by {a.author}
                </span>
                <p
                  className={`mt-3 mb-4`}
                  style={{ color: '#093e5f' }}
                  dangerouslySetInnerHTML={{
                    __html: `${a.content.slice(0, 150)}... Read More`
                  }}
                />
              </div>
            </div>
          </a>
          <div className="row text-center">
            <div className="col-3 d-inline-block">
              {/*<img*/}
              {/*src="/images/icons/newsMsg.svg"*/}
              {/*className="d-inline"*/}
              {/*alt="3 comments"*/}
              {/*/>{' '}*/}
              {/*<Disqus.CommentCount*/}
              {/*shortname={disqusShortname}*/}
              {/*config={disqusConfig}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsItem;
