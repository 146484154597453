import React from 'react';

const BottomCircleBlue = props => (
  <div
    className="h-100"
    style={{
      background: 'url("/images/circles/greyBottomCircle.svg") no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundPositionY: 100,
      paddingBottom: 150
    }}>
    {props.children}
  </div>
);

export default BottomCircleBlue;
