import React from 'react';

const Button = props => (
  <button
    className={props.className}
    onClick={props.onClick}
    style={{
      backgroundColor: props.btnColor,
      boxShadow: props.boxShadow,
      borderRadius: 3,
      fontSize: props.fontSize,
      border: 'none',
      width: props.width,
      height: props.height,
      color: props.textColor,
      letterSpacing: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }}>
    {props.children}
  </button>
);

export default Button;
