import React, { PropTypes } from 'react';
import { Link, withRouter } from 'react-router-dom';
import InnerBlock from './InnerBlock';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import Button from '../../components/Stuff/Button';
import Http from '../../constants/Http';
import b64toblob from 'b64-to-blob';

const getPDF = (slug) => {
  Http.get('/course/pdf/' + slug)
    .then(res => {
      let contentType = 'application/pdf';
      let b64Data = res.data.document;
      let blob = b64toblob(b64Data, contentType);

      let blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    })
    .catch(err => {
      console.log(err);
    });
};

const SideInfo = props => (
  <aside className="col-12 col-lg-4 order-lg-3">
    <div className="course-image d-flex position-relative align-items-center pb-0">
      {props.data.course_name !==
        'CMI Level 3 Diploma Team Leader/Supervisor Apprenticeship Standard' &&
        props.data.course_name !==
        'CMI Level 5 Diploma in Operations/Departmental Manager Apprenticeship Standard' && (
          <img
            src={props.data !== null ? props.data.exam_boards.logo : ''}
            width={130}
          />
        )}
    </div>
    <div className={'course-image d-flex position-relative align-items-center'}>
      {props.data.course_name ===
        'CMI Level 3 Diploma Team Leader/Supervisor Apprenticeship Standard' && (
          <React.Fragment>
            <img
              src={props.data !== null ? props.data.exam_boards.logo : ''}
              width={130}
            />
            <img
              src={'/images/ncfe.jpg'}
              width={130}
              style={{
                marginTop: 15
              }}
            />
          </React.Fragment>
        )}
      {props.data.course_name ===
        'CMI Level 5 Diploma in Operations/Departmental Manager Apprenticeship Standard' && (
          <React.Fragment>
            <img
              src={props.data !== null ? props.data.exam_boards.logo : ''}
              width={130}
            />
            <img
              src={'/images/ncfe.jpg'}
              width={130}
              style={{
                marginTop: 15
              }}
            />
          </React.Fragment>
        )}
    </div>
    <div className="course-side">
      <div className="block">
        <div className="title">Course Duration</div>
        {props.data.award.length > 0 && (
          <InnerBlock data={props.data.award} title="Award" />
        )}
        {props.data.certificate.length > 0 && (
          <InnerBlock data={props.data.certificate} title="Certificate" />
        )}
        {props.data.diploma.length > 0 && (
          <InnerBlock data={props.data.diploma} title="Diploma" />
        )}
        {props.data.extended_diploma.length > 0 && (
          <InnerBlock
            data={props.data.extended_diploma}
            title="Extended Diploma"
          />
        )}

        {props.data.other.length > 0 && (
          <InnerBlock data={props.data.other} title="Other" />
        )}
      </div>

      <div className="block">
        <div className="title">Start Date (All Levels)</div>

        {props.category !== 'ba-hons-top-up' ? (
          <p>
            Our courses are taken via online distance learning so you can start
            your course on the Monday following your successful application
          </p>
        ) : (
            <p>
              These Top-Up courses are taken via online distance learning. The
            enrolment dates are as follows: <br />
              <br />
              October
            <br />
              January
            <br />
              April
            <br />
              August
            <br />
            </p>
          )}
      </div>

      <div className="block">
        <div className="title">Entry Criteria - All Levels</div>

        {props.data.entry_criteria !== null ? (
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.entry_criteria
            }}
          />
        ) : (
            <ul>
              <li>Be 19 years of age and over</li>
              <li>Be new to a team leader role</li>
              <li>Have some team leader experience or similar job role</li>
            </ul>
          )}
      </div>

      <div className="block">
        <div className="title">Price</div>
        {props.data.award.length > 0 && (
          <div className="inner-block">
            <p>
              <strong>Award</strong> in {props.data.course_name}
            </p>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;
              {props.data.award[0].price} GBP Course Fee
            </span>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;{props.data.award[0].reg_fee} GBP Registration Fee
            </span>
            <span className="highlight-price">
              &pound;
              {(
                parseFloat(props.data.award[0].reg_fee) +
                parseFloat(props.data.award[0].price)
              ).toFixed(2)}{' '}
              GBP Total Cost
            </span>
          </div>
        )}
        {props.data.certificate.length > 0 && (
          <div className="inner-block">
            <p>
              <strong>Certificate</strong> in {props.data.course_name}
            </p>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;
              {props.data.certificate[0].price} GBP Course Fee
            </span>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;{props.data.certificate[0].reg_fee} GBP Registration Fee
            </span>
            <span className="highlight-price">
              &pound;
              {(
                parseFloat(props.data.certificate[0].reg_fee) +
                parseFloat(props.data.certificate[0].price)
              ).toFixed(2)}{' '}
              GBP Total Cost
            </span>
          </div>
        )}
        {props.data.diploma.length > 0 && (
          <div className="inner-block">
            <p>
              <strong>Diploma</strong> in {props.data.course_name}
            </p>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;
              {props.data.diploma[0].price} GBP Course Fee
            </span>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;{props.data.diploma[0].reg_fee} GBP Registration Fee
            </span>
            <span className="highlight-price">
              &pound;
              {(
                parseFloat(props.data.diploma[0].reg_fee) +
                parseFloat(props.data.diploma[0].price)
              ).toFixed(2)}{' '}
              GBP Total Cost
            </span>
          </div>
        )}
        {props.data.extended_diploma.length > 0 && (
          <div className="inner-block">
            <p>
              <strong>Extended Diploma</strong> in {props.data.course_name}
            </p>
            <span
              className="highlight-price"
              style={{ fontWeight: 500 }}
              style={{ fontWeight: 500 }}>
              &pound;
              {props.data.extended_diploma[0].price} GBP Course Fee
            </span>
            <span className="highlight-price" style={{ fontWeight: 500 }}>
              &pound;{props.data.extended_diploma[0].reg_fee} GBP Registration
              Fee
            </span>
            <span className="highlight-price">
              &pound;
              {(
                parseFloat(props.data.extended_diploma[0].reg_fee) +
                parseFloat(props.data.extended_diploma[0].price)
              ).toFixed(2)}{' '}
              GBP Total Cost
            </span>
          </div>
        )}
        {props.data.other.length > 0 && (
          <div className="inner-block">
            <p>{props.data.course_name}</p>
            {props.data.other[0].price != '0.00' && (
              <span className="highlight-price" style={{ fontWeight: 500 }}>
                &pound;
                {props.data.other[0].price} GBP Course Fee
              </span>
            )}
            {props.data.other[0].reg_fee != '0.00' && (
              <span className="highlight-price" style={{ fontWeight: 500 }}>
                &pound;{props.data.other[0].reg_fee} GBP Registration Fee
              </span>
            )}
            {props.data.other[0].price != '0.00' && (
              <span className="highlight-price">
                &pound;
                {(
                  parseFloat(props.data.other[0].reg_fee) +
                  parseFloat(props.data.other[0].price)
                ).toFixed(2)}{' '}
                GBP Total Cost
              </span>
            )}
            {props.data.id === 42 && (
              <span className="highlight-price">
                This Apprenticeship standard has been allocated a maximum
                funding cap of £4,500, which is the anticipated full cost for
                delivering this standard and the end point assessment.
              </span>
            )}
            {props.data.id === 43 && (
              <span className="highlight-price">
                This Apprenticeship standard has been allocated a maximum
                funding cap of £7,000, which is the anticipated full cost for
                delivering this standard and the end point assessment.
              </span>
            )}
          </div>
        )}
      </div>
      {props.data.payment_description && (
        <div className="payment-description block">
          <p
            dangerouslySetInnerHTML={{
              __html: props.data.payment_description
            }}
          />
        </div>
      )}
      {props.data.course_type.name !== 'BA Hons Top-Up' ?
        <div className="apply-button block">
          <Button
            className="btn-block"
            height={60}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={24}
            onClick={() => props.dispatch(push(`/application-form?course=${props.match.params.course}`))}>
            APPLY
        </Button>
        </div>
        :
        <div className="pdf-button block">
          <a download href={props.data.alternative_application} target={"_blank"}>
            <i className="far fa-file-alt" />
            Download Application Form
        </a>
        </div>
      }
      {/*onClick={() => getPDF(props.match.params.course)}*/}
      <div className="pdf-button block">
        <a download href={"https://api.brightonsbm.com/documents/" + props.match.params.course + '.pdf'} target={"_blank"}>
          <i className="far fa-file-alt" />
          Download Course PDF
        </a>
      </div>

      <div className="contact block">
        <a
          className={'cant-find'}
          style={{
            color: '#002847',
            cursor: 'pointer'
          }}
          onClick={() => props.enquire()}>
          Can’t find what you are looking for? <strong>Get in Touch</strong>
        </a>
        <p
          className={'cant-find'}
          style={{
            color: '#002847'
          }}>
          Or give us a call on <strong>+44 (0)1273 704 463</strong>
        </p>
      </div>
    </div>
    <div className="similar-block text-left">
      <h5 className={'heading'}>Related Courses</h5>
      <hr />
      {props.data.related && props.data.related.map((course, i) => (
        <React.Fragment>
          <Link
            to={`/courses/${course.course_type_slug}/${course.course_slug}`}>
            <div className={'block'}>
              <p className={'mb-0 title'}>
                {course.course_name} <span>Read More...</span>
              </p>
            </div>
          </Link>
          <hr />
        </React.Fragment>
      ))}
      <Link
        className={'course-category'}
        to={'/courses/' + props.match.params.category}>
        See All {props.data.course_type.name} Courses
      </Link>
    </div>
  </aside>
);

export default withRouter(connect()(SideInfo));
