import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
class Footer extends React.Component {
  
  componentDidMount() {
    this.setPCI();
  }
  
  setPCI = () => {
    const s  = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://sealserver.trustwave.com/seal.js?code=b3ed3c162d084db6ab51e83d960e1e46";
    this.instance.appendChild(s);
    
    // const el = document.getElementById("pci-div");
    // const script = document.createElement('script');
    // script.type = 'text/javscript';
    // script.src = "https://sealserver.trustwave.com/seal.js?code=b3ed3c162d084db6ab51e83d960e1e46";
    //
    // el.appendChild(script);
  };
  
  render () {
    const { props } = this;
    return (
  <React.Fragment>
    <div className={`footer ${props.sticky ? 'sticky' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5">
            <div className="link-wrapper">
              <ul>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/courses">Courses</a>
                </li>
                <li>
                  <a href="/news">News</a>
                </li>
                <li>
                  <a href="/testimonials">Testimonials</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>

              <ul>
                <li>
                  {/*<a href="/privacy-policy">Privacy Policy</a>*/}
                </li>
                {/*<li>*/}
                  {/*<a href="/fees-and-funding">Fees &amp; Funding</a>*/}
                {/*</li>*/}
                <li>
                  <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href={'/application-form'}>Apply</a>
                </li>
                <li>
                  <div className={'row social-icons'}>
                    <div>
                      <a
                        href={'https://www.facebook.com/brightonschoolbm'}
                        target={'_blank'}>
                        <i className="fab fa-facebook" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={
                          'https://www.linkedin.com/school/brighton-school-of-business-and-management/'
                        }
                        target={'_blank'}>
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-2" id={"pci-div"} ref={el => (this.instance = el)} >
            <img id="trustwaveSealImage"
                 src="https://sealserver.trustwave.com/seal_image.php?customerId=b3ed3c162d084db6ab51e83d960e1e46&amp;size=105x54&amp;style="
                 border="0" style={{ cursor: "pointer" }}
                 onClick={() => {window.open('https://sealserver.trustwave.com/cert.php?customerId=b3ed3c162d084db6ab51e83d960e1e46&amp;size=105x54&amp;style=', 'c_TW', 'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'); return false;}}
                 onContextMenu="javascript:alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'); return false;"
                 alt="This site is protected by Trustwave's Trusted Commerce program"
                 title="This site is protected by Trustwave's Trusted Commerce program" />
          </div>
          <div className="col-12 col-lg-5 mb-3 footer-location">
            <p>
              Company Registered in the United Kingdom. No: 5919690
              <br /> VAT No: 899935332
            </p>
            <p>
              Sussex Innovation Centre, Science Park Square, University of
              Sussex Campus, Falmer, Brighton, East Sussex, BN1 9SB, United
              Kingdom.
            </p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col">
            <p style={{ marginTop: '1em', marginBottom: '1em', fontSize: 12 }}>
              &copy; 2018 Brighton School of Business and Management
            </p>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
    )
  }
}

export default Footer;
