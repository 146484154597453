import React, { PropTypes } from 'react';

const InnerBlock = props => (
  <div className="inner-block">
    {props.data[0].total_time > 0 ? (
      <React.Fragment>
        <div className="inner-title" style={{ minWidth: 150 }}>
          {props.title}
        </div>{' '}
        <div className="data">{props.data[0].total_time} Weeks</div>
      </React.Fragment>
    ) : (
      'See Description'
    )}
  </div>
);

export default InnerBlock;
