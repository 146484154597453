import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import NavAbout from '../../components/Navbar/navAbout';
import { initialize } from 'redux-form';
import moment from 'moment';
import MyAccount from './components/MyAccount';
import MyCourses from './components/MyCourses';
import MyCoursesView from './components/MyCoursesView';

import './index.scss';
import MyApplications from './components/MyApplications';
import MyApplicationsView from './components/MyApplicationsView';
import NavMobile from '../../components/Navbar/navMobile';
import Http from '../../constants/Http';

class Dashboard extends React.Component {
  state = {
    isTop: true,
    desktop: true,
    paymentMethod: null,
    data: null,
  };

  componentWillMount() {
    this.checkSize();
    
    if (window.localStorage.getItem('token') === undefined) {
      window.location.href = '/login';
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this));
  
    this.getPaymentMethods();
  
    console.log(this.props);
    console.log(window.location.pathname.includes(`/my-applications/${window.location.pathname.split('/')[3]}`));
    if (window.location.pathname.includes(`/my-applications/${window.location.pathname.split('/')[3]}`) && this.state.data === null) {
      Http.get(`/account/applications/${window.location.pathname.split('/')[3]}`)
        .then(res => {
          this.setState({
            data: res.data.data
          });
          this.props.dispatch(initialize('applicationDetailsForm',
            {
              ...res.data.data,
              user: {
                ...res.data.data.user,
                date_of_birth: moment(res.data.data.user.date_of_birth.date)
              }
            }
          ))
        })
        .catch(err => {
          console.error(err);
        })
    }
    console.log(this.props);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize());
  }
  
  getPaymentMethods = () => {
    Http.get('/payment-methods')
      .then(res => {
        console.log(res.data.data);
        this.setState({
          paymentMethod: res.data.data
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  checkSize = () => {
    const width = window.innerWidth > 992;
    if (width !== this.state.desktop) {
      this.setState({
        desktop: width
      });
    }
  };

  render() {
    const { isTop, desktop, paymentMethod } = this.state;
    const { props } = this;
    const { match } = props;
    if (window.location.pathname === '/dashboard') {
      return <Redirect to={`${match.url}/my-account`} />;
    }
    return (
      <div className={'pageWrapper DashboardWrapper wrapper'}>
        {!!!desktop ? (
          <NavMobile desktop={desktop} />
        ) : (
          <NavAbout desktop={desktop} />
        )}
        <div className={'container p-spacer-150'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-3 dashMenu mb-lg-0 mb-5'}>
              <Link to={`${match.url}/my-applications`}>
                <div
                  className={`col-12 d-flex align-items-center dashMenuItem ${window
                    .location.pathname === '/dashboard/my-applications' &&
                    'active'}`}>
                  <i className={'fa fa-list'} />
                  &nbsp;&nbsp;My Applications
                </div>
              </Link>
              <Link to={`${match.url}/my-courses`}>
                <div
                  className={`col-12 d-flex align-items-center dashMenuItem ${window
                    .location.pathname === '/dashboard/my-courses' &&
                    'active'}`}>
                  <i className={'fa fa-book'} />
                  &nbsp;&nbsp;My Courses
                </div>
              </Link>
              <Link to={`${match.url}/my-account`}>
                <div
                  className={`col-12 d-flex align-items-center dashMenuItem ${window
                    .location.pathname === '/dashboard/my-account' &&
                    'active'}`}>
                  <i className={'fa fa-user'} />
                  &nbsp;&nbsp;My Account
                </div>
              </Link>
            </div>
            <div className={'col-12 col-lg-9'}>
              <Route
                exact
                path={`${match.url}/my-account`}
                component={MyAccount}
              />
              <Route
                exact
                path={`${match.url}/my-courses`}
                component={MyCourses}
              />
              <Route
                exact
                path={`${match.url}/my-courses/:id`}
                component={MyCoursesView}
              />
              <Route
                exact
                path={`${match.url}/my-applications`}
                component={MyApplications}
              />
              <Route
                exact
                path={`${match.url}/my-applications/:id`}
                component={(props) => <MyApplicationsView {...props} paymentMethod={paymentMethod} />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Dashboard);
