import React from 'react';
import { connect } from 'react-redux';

import Http from '../../constants/Http';

import './index.scss';
import { Link } from 'react-router-dom';
import { handleCourseCategory, updateCourse } from '../../modules/courses';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import PageLoading from '../../components/Loading/pageLoading';
import Breadcrumbs from '../../components/Breadcrumbs';
import Meta from '../../components/Meta';
import Bubble from '../../components/Bubble';

class Unit extends React.Component {
  state = {
    data: null,
    isLoading: true
  };

  componentWillMount() {
    this.getUnitData();
  }

  getUnitData = () => {
    Http.get(`/module/${this.props.match.params.course}/${this.props.match.params.unit}`)
      .then(res => {
        this.setState({
          data: res.data.data,
          isLoading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { props } = this;

    if (this.state.isLoading) {
      return <PageLoading />;
    }

    return (
      <Page classnames="course-page">
        <Meta
          type={"website"}
          title={this.state.data.meta_title ? this.state.data.meta_title : this.state.data.name}
          description={this.state.data.meta_description ? this.state.data.meta_description : this.state.data.sub_title}
        />
        <PageHeader>
          <span className="unit-label">Course Module</span>
          <h1>{this.state.data.name}</h1>
          {!this.state.data.tut_time &&
          <h2>
            Credit Value: <strong>{this.state.data.credits} credits</strong>
          </h2>
          }

          <a
            href={
              '/courses/' +
              this.props.match.params.category +
              '/' +
              this.props.match.params.course
            }
            className="course-link">
            <i className="fas fa-chevron-left" /> Return to course overview
          </a>

          {/*<img*/}
            {/*src="/images/circles/topRightBlue.svg"*/}
            {/*height={550}*/}
            {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <div className={"d-none d-lg-inline"}>
            <Bubble speed={"17s"} right={100} widthHeight={500} delay={"-8s"} backgroundColor={"#0887E0"} animations={{ 'WebkitAnimation': 'move-up 15s linear infinite',
              'MozAnimation': 'move-up 15s linear infinite',
              'msAnimation': 'move-up 15s linear infinite',
              'OAnimation': 'move-up 15s linear infinite',
              'animation': 'move-up 15s linear infinite' }}  />
            <Bubble speed={"8s"} right={150} widthHeight={500} delay={"-10s"} backgroundColor={"#93CCFD"} animations={{ 'WebkitAnimation': 'move-up 15s linear infinite',
              'MozAnimation': 'move-up 15s linear infinite',
              'msAnimation': 'move-up 15s linear infinite',
              'OAnimation': 'move-up 15s linear infinite',
              'animation': 'move-up 15s linear infinite' }}  />
            <Bubble speed={"17s"} right={200} widthHeight={300} delay={"-13s"} backgroundColor={"#50DCF6"} animations={{ 'WebkitAnimation': 'move-up-right 15s linear infinite',
              'MozAnimation': 'move-up-right 15s linear infinite',
              'msAnimation': 'move-up-right 15s linear infinite',
              'OAnimation': 'move-up-right 15s linear infinite',
              'animation': 'move-up-right 15s linear infinite' }} />
            <Bubble speed={"17s"} right={100} widthHeight={200} delay={"-3s"} backgroundColor={"#4097F8"} animations={{ 'WebkitAnimation': 'move-up-left 15s linear infinite',
              'MozAnimation': 'move-up-left 15s linear infinite',
              'msAnimation': 'move-up-left 15s linear infinite',
              'OAnimation': 'move-up-left 15s linear infinite',
              'animation': 'move-up-left 15s linear infinite' }} />
          </div>
          <div className={"d-lg-none"}>
            <span className={"homepage topRight blue-15 twoThirds"} />
          </div>
        </PageHeader>
        <section className="course-detail container">
          <div className="row">
            <div className="col-12 col-lg-7 order-lg-1 course-main-content">
              <div className="content-block">
                <div className="title">Unit Aim</div>
                <div
                  id={'UnitAimUnit'}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.unit_aim
                  }}
                />
              </div>
  
              {this.state.data && this.state.data.learning_outcome.length >= 20 &&
              <div className="content-block">
                <div className="title">Learning Outcomes</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.learning_outcome
                  }}
                />
              </div>
              }
  
              {this.state.data && this.state.data.assessment_criteria.length >= 20 &&
              <div className="content-block">
                <div className="title">Assessment Criteria</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.assessment_criteria
                  }}
                  className={'mb-5 pb-5'}
                />
              </div>
              }
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default connect()(Unit);
