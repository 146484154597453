import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field, change, Fields } from 'redux-form';
import DatePicker from 'react-datepicker';
import { Label } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

import * as validations from '../../../Validation';
import Button from '../../../components/Stuff/Button';
import renderField from '../../../components/Fields/renderField';
import RenderSelect from '../../../components/Fields/renderSelect';
import { getAwardType, getCourses } from '../../../modules/courses';
import RenderAllErrors from '../../../components/RenderAllErrors';

const sexOptions = [
  {
    value: 1,
    label: 'Male'
  },
  {
    value: 2,
    label: 'Female'
  },
  {
    value: 3,
    label: 'Other'
  }
];

const disabilityStatuses = [
  { value: 1, label: 'Not Disabled' },
  { value: 2, label: 'Disabled But Not Registered' },
  { value: 3, label: 'Registered Disabled' }
];

const DatePickerField = props => {
  return (
    <DatePicker
      selected={props.input.value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat={'DD / MM / YYYY'}
      onChange={props.input.onChange}
    />
  );
};

class Page1 extends React.Component {
  componentWillMount() {
    this.props.dispatch(getCourses()).then(res => {
      this.selectCourse(res);
    });
  }

  selectCourse = data => {
    // window.setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const course = urlParams.get('course');
    console.log(data, course);
    this.props.dispatch(
      change(
        'wizard',
        'course',
        data.data[data.data.findIndex(item => item.slug === course)]
      )
    );
    // }, 1000);
  };

  render() {
    const { props } = this;
    const { handleSubmit } = props;

    const isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      }
    };

    const fieldNames = [
      'course',
      'award_type',
      'firstName',
      'lastName',
      'dob-dd',
      'dob-mm',
      'dob-yyyy',
      'gender',
      'disability_status'
    ];

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <div className={'form-group'}>
            <Label>Select the course *</Label>
            <Field
              name={'course'}
              component={RenderSelect}
              clearable={false}
              labelKey={'course_name'}
              valueKey={'id'}
              options={props.courses !== null ? props.courses.data : []}
              validate={validations.required}
            />
          </div>

          {props.awardType !== null && props.awardType.length > 0 && (
            <div className={'form-group'}>
              <Label>Select the award level *</Label>
              <Field
                name={'award_type'}
                component={RenderSelect}
                clearable={false}
                labelKey={'label'}
                valueKey={'id'}
                options={props.awardType}
                validate={validations.required}
              />
            </div>
          )}

          <div className="form-group">
            <Field
              name="firstName"
              type="text"
              autofocus
              component={renderField}
              label="First Name *"
              validate={validations.required}
            />
          </div>
          <div className="form-group">
            <Field
              name="lastName"
              type="text"
              component={renderField}
              label="Last Name *"
              validate={validations.required}
            />
          </div>
          <div className="form-group date-of-birth-custom">
            <div className="row">
              <div className="col-12 mb-0">
                <Label>Date of birth *</Label>
              </div>
              <div className="col">
                <Field
                  name="dob-dd"
                  component={renderField}
                  noLabel
                  placeholder={'DD'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="dob-mm"
                  component={renderField}
                  noLabel
                  placeholder={'MM'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
              <div className="col">
                <Field
                  name="dob-yyyy"
                  component={renderField}
                  noLabel
                  placeholder={'YYYY'}
                  type={`number`}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  validate={validations.required}
                />
              </div>
            </div>
          </div>
          <div className={'form-group row'}>
            <div className={'col-6'}>
              <Label>Gender *</Label>
              <Field
                name="gender"
                label="Gender *"
                component={RenderSelect}
                options={sexOptions}
                validate={validations.required}
              />
            </div>
            <div className={'col-6'}>
              <Label>Disability *</Label>
              <Field
                name={'disability_status'}
                label={'Disability *'}
                component={RenderSelect}
                options={disabilityStatuses}
                validate={validations.required}
              />
            </div>
          </div>
          <div className="form-group text-center">
            <Button
              width={'100%'}
              height={60}
              style={{ width: '100%' }}
              btnColor="rgba(77, 199, 117, 1)"
              boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
              textColor="white"
              fontSize={14}>
              NEXT
            </Button>
          </div>
          {!props.valid && props.submitFailed && (
            <Fields names={fieldNames} component={RenderAllErrors} />
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses.courses,
  awardType: state.courses.awardType !== null ? state.courses.awardType : null
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onChange: (values, dispatch, props, prevValues) => {
      dispatch(
        change(
          'wizard',
          'dob',
          `${values['dob-yyyy']}/${values['dob-mm']}/${values['dob-dd']}`
        )
      );
      if (JSON.stringify(values.course) !== JSON.stringify(prevValues.course)) {
        dispatch(getAwardType(values.course.slug));
      }
    }
  })(Page1)
);
