import Http from '../constants/Http';
import { push } from 'react-router-redux';

export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';
export const LOGOUT = 'auth/LOGOUT';

const initialState = {
  token: null,
  loggedIn: false,
  Uname: null,
  errorMsg: null,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.token,
        loggedIn: true,
        errorMsg: null,
      }
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        token: null,
        loggedIn: false,
        Uname: null,
        errorMsg: action.errorMsg
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export const login = (Uname, Pword) => dispatch => {
  // axios login here
  Http.post(`/auth/login`, {
    email: Uname,
    password: Pword
  })
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        token: res.data.success.token,
      });
      window.localStorage.setItem('token', res.data.success.token);
      window.setTimeout(() => {
        dispatch(push('/dashboard'))
      }, 500);
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAILED,
        errorMsg: "Incorrect email or password"
      })
    });
};

export const logout = () => dispatch => {
  // axios logout here
  dispatch({
    type: LOGOUT,
  })
};
