import React, { Component, PropTypes } from 'react';

import NavMobile from '../Navbar/navMobile';
import NavDefault from '../Navbar/navDefault';

import './Page.scss';

export default class NewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isTop: true, desktop: true, messageRead: true };
  }

  componentWillMount() {
    this.checkScroll();
    this.checkSize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this));
    window.addEventListener('scroll', this.checkScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize());
    window.removeEventListener('scroll', this.checkScroll());
  }

  checkScroll = () => {
    const scroll = window.scrollY > 25;
    if (scroll !== this.state.isTop) {
      this.setState({
        isTop: scroll
      });
    }
  };

  checkSize = () => {
    const width = window.innerWidth >= 1200;
    if (width !== this.state.desktop) {
      this.setState({
        desktop: width
      });
    }
  };
  
  render() {
    const { desktop, isTop } = this.state;
    return (
      <section className={'page-wrapper ' + this.props.classnames}>


        {/*{this.state.messageRead &&*/}
        {/*  <div className="global-message">*/}
        {/*  <span onClick={() => this.setState({messageRead: false})}>x</span>*/}
        {/*  To protect our staff from the Covid-19 virus we are temporarily closing down our office. We will continue supporting you as normal and if you have any questions then leave us a message on <a href="mailto:enquiries@brightonsbm.com">enquiries@brightonsbm.com</a> and we will respond as soon as we can. We will be unable to take phone calls. Keep safe and well.*/}
        {/*</div>}*/}


        {!!!desktop ? (
          <NavMobile isTop={isTop} desktop={desktop} />
        ) : (
          <NavDefault isTop={isTop} desktop={desktop} />
        )}
        {new Date() < new Date("08 / 19 / 2019") && (window.location.pathname !== '/success' && window.location.pathname !== '/application-form' && window.location.pathname !== '/payment-success') && window.sessionStorage.getItem("promo-summer19-dismissed") !== "true" &&
          <div className="promo-banner container position-relative">
            <div className="col-12">
              <div className="cross" onClick={e => {
                window.sessionStorage.setItem("promo-summer19-dismissed", "true");
                e.target.parentElement.parentElement.remove();
              }}>
                <i className="fa fa-times"/>
              </div>
              <p className={"font-weight-bold"}>LIMITED OFFER</p>
              10% discount off course fees if you enrol on any course (except BA Hons courses) between now and 26th August
              2019. Quote ‘Summer19’. Discount excludes registrations fees.
            </div>
          </div>
        }
        {this.props.children}
      </section>
    );
  }
}
