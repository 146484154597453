import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { Alert, Label } from 'reactstrap';
import Button from '../../../../components/Stuff/Button';
import RenderSelect from '../../../../components/Fields/renderSelect';
import * as validations from '../../../../Validation';
import renderField from '../../../../components/Fields/renderField';
import DatePicker from 'react-datepicker/es/index';

const DatePickerField = props => {
  return (
    <DatePicker
      selected={props.input.value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat={'DD / MM / YYYY'}
      onChange={props.input.onChange}
    />
  );
};

const sourceOptions = [
  {
    value: 10,
    label: 'CoursesOnline'
  },
  {
    value: 6,
    label: 'Google'
  },
  {
    value: 3,
    label: 'Facebook'
  },
  {
    value: 1,
    label: 'FindCourses.co.uk'
  },
  {
    value: 2,
    label: 'The Independent'
  },
  {
    value: 4,
    label: 'Twitter'
  },
  {
    value: 5,
    label: 'LinkedIn'
  },
  {
    value: 7,
    label: 'Friend referral'
  },
  {
    value: 8,
    label: 'GBS blog'
  },
  {
    value: 9,
    label: 'Other'
  }
];

const Page5 = props => {
  const { handleSubmit, prevPage } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <p>
          Please give details of your employment experience, including
          Employers' names and addresses, your job title and main duties, dates
          and reasons for leaving (where applicable).
        </p>
        <Field
          name="work-experience"
          type="text"
          component="textarea"
          validate={validations.required}
        />
      </div>
      <div className={"form-group"}>
        <div className="row">
          <div className="col-12 col-lg-6 date-of-birth-custom">
            
            <Label>Employed From</Label>
            <Field
              name={"employed_from"}
              label="Employed From"
              component={DatePickerField}
              validate={validations.required}
            />
          </div>
          <div className="col-12 col-lg-6 date-of-birth-custom">
            <Label>Employed To</Label>
            <Field
              name={"employed_to"}
              label="Employed To"
              component={DatePickerField}
              validate={validations.required}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              name={"current_job_role"}
              label={"Current Job Role"}
              component={renderField}
              type={"text"}
              validate={validations.required}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <Label>Where did you hear about us?</Label>
        <Field
          name="source"
          label="Where did you hear about us?"
          component={RenderSelect}
          options={sourceOptions}
          validate={validations.required}
        />
      </div>
      <div
        className="form-group"
        style={{ justifyContent: 'space-between', display: 'flex' }}>
        <Button
          width={280}
          height={60}
          onClick={() => props.prevPage()}
          btnColor="rgba(0, 0, 0, 0.2)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="rgba(0,0,0,0.6)"
          fontSize={14}>
          <i className="fas fa-chevron-left" /> BACK
        </Button>
        <Button
          width={280}
          height={60}
          btnColor="rgba(77, 199, 117, 1)"
          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
          textColor="white"
          fontSize={14}>
          NEXT <i className="fas fa-chevron-right" />
        </Button>
      </div>
      {!props.valid &&
        props.submitFailed && (
          <Alert color="danger" className="form-errors">
            There are errors in your form. Please check you have completed all
            required fields.
          </Alert>
        )}
    </Form>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page5);
