import React from 'react'

const Loading = props => (
  <div className={'container'}>
    <div className={'col-12'}>
      <div className={'row d-flex justify-content-center'}>
        <img src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: 75, height: 75 }} />
      </div>
    </div>
  </div>
);

export default Loading
