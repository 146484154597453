import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';

import ReactGA from 'react-ga';

import Router from '../../router';

import Footer from '../../components/Footer';
import { Route, Switch } from 'react-router-dom';
import withTracker from './withTracker';
import Home from '../Home';
import ApplicationForm from '../ApplicationForm';
import FAQ from '../Faq';
import About from '../About';
import Courses from '../Courses';
import CharteredManager from '../CharteredManager';
import BAHons from '../BAHons';
import Elcas from '../Elcas';
import TermsAndConditions from '../TermsAndConditions';
import Apprenticeships from '../Apprenticeships';
import Course from '../Course';
import Unit from '../Course/Unit';
import Success from '../Success';
import applySuccess from '../Success/applySuccess';
import contactSuccess from '../Success/contactSuccess';
import Testimonials from '../Testimonials';
import FeesAndFunding from '../FeesAndFunding';
import NewsList from '../News';
import Article from '../News/Article';
import Contact from '../Contact';
import PrivacyPolicy from '../PrivacyPolicy';
import Login from '../Login';
import CreatePassword from '../CreatePassword';
import Dashboard from '../Dashboard';
import Error404 from '../Errors/error404';
import PaymentPage from '../PaymentPage';
import PaymentSuccess from '../Success/PaymentSuccess';
import OnlineDistanceLearning from '../OnlineDistanceLearning';

class App extends React.Component {
  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentWillMount() {
    ReactGA.initialize('UA-7301422-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { props } = this;
    return (
      <div className="wrapper">
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />
          <Route exact path="/application-form" component={withTracker(ApplicationForm)} />
          <Route exact path="/faq" component={withTracker(FAQ)} />
          <Route exact path="/about" component={withTracker(About)} />
          <Route exact path="/online-distance-learning" component={withTracker(OnlineDistanceLearning)} />
          <Route exact path="/courses" component={withTracker(Courses)} />
          <Route exact path="/courses/:category" component={withTracker(Courses)} />
          <Route
            exact
            path={'/chartered-manager'}
            component={withTracker(CharteredManager)}
          />
          <Route exact path={'/ba-hons'} component={withTracker(BAHons)} />
          <Route exact path={'/elcas-scheme'} component={withTracker(Elcas)} />
          <Route
            exact
            path={'/terms-and-conditions'}
            component={withTracker(TermsAndConditions)}
          />
          <Route exact path={'/apprenticeships'} component={withTracker(Apprenticeships)} />
          <Route exact path="/courses/:category/:course" component={withTracker((props) => <Course timestamp={new Date().toString()} {...props} />)} />
          <Route
            exact
            path="/courses/:category/:course/apply"
            component={withTracker(Course)}
          />
          <Route
            exact
            path="/courses/:category/:course/unit/:unit"
            component={withTracker(Unit)}
          />
          <Route exact path="/success" component={withTracker(Success)} />
          <Route exact path={'/application-success'} component={withTracker(applySuccess)} />
          <Route exact path="/contact-success" component={withTracker(contactSuccess)} />
          <Route exact path="/testimonials" component={withTracker(Testimonials)} />
          <Route exact path="/fees-and-funding" component={withTracker(FeesAndFunding)} />
          <Route exact path="/news" component={withTracker(NewsList)} />
          <Route exact path={'/news/article/:article'} render={(props) => <Redirect to={`/news/${props.match.params.article}`} />} />
          <Route exact path="/news/:article/" component={withTracker(Article)} />
          <Route exact path={'/news/:article/*'} render={() => <Redirect to={'/news'} />} />
          <Route exact path="/contact" component={withTracker(Contact)} />
          <Route exact path="/privacy-policy" component={withTracker(PrivacyPolicy)} />
          {/*<Route exact path="/payment" component={withTracker(PaymentPage)} /> */}
          {/*<Route exact path={"/payment-success"} component={withTracker(PaymentSuccess)} />*/}
          <Route exact path="/login" component={withTracker(Login)} />
          <Route exact path="/login/:hash" component={withTracker(CreatePassword)} />

          <Route path="/dashboard" component={withTracker(Dashboard)} />
          <Route path={'/*'} component={withTracker(Error404)} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location
  };
};

export default withRouter(connect(mapStateToProps)(App));
