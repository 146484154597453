import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import ScriptTag from 'react-script-tag';

import Disqus from 'disqus-react';

import Http from '../../constants/Http';

import RenderField from '../../components/Fields/renderField';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Meta from '../../components/Meta';
import EdgeCircle from '../../components/Circles/edgeCircle';
import GreyCircleBottom from '../../components/Circles/greyBottomCircle';
import Button from '../../components/Stuff/Button';
import PageLoading from '../../components/Loading/pageLoading';
import { emailSignup } from '../../modules/contact';
import Bubble from '../../components/Bubble';

import './index.scss';
import Loading from '../../components/Loading';

class NewsList extends React.Component {
  state = {
    disqusConfig: {},
    data: null,
    isLoading: true
  };

  componentWillMount() {
    this.getArticle();
  }

  getArticle = () => {
    Http.get(`/articles/${this.props.match.params.article}`)
      .then(res => {
        this.setState({
          data: res.data.data,
          isLoading: false
        });
        this.setState({
          disqusConfig: {
            url: `/news/${this.props.match.params.article}`,
            identifier: this.props.match.params.article,
            title: res.data.data.title
          }
        });
      })
      .catch(err => {
        console.error(err);
        window.location.replace('/news');
      });
  };

  submit = values => {
    values.preventDefault();
    this.props.dispatch(emailSignup(values));
  };

  setContent = () => {
    document.getElementsByClassName('newsContent')[0].innerHTML =
      this.state.data.content;
  };

  render() {
    const { disqusConfig } = this.state;
    const { props } = this;
    const disqusShortname = 'brigtonsbmtest';

    if (this.state.isLoading) {
      return <PageLoading />;
    }

    return (
      <Page classnames="news-page">
        <Meta
          type={'article'}
          title={`${
            this.state.data.meta_title
              ? this.state.data.meta_title
              : `${this.state.data.title} | News & Updates`
          }`}
          description={`${
            this.state.data.meta_description
              ? this.state.data.meta_description
              : ``
          }`}
        />
        <section className={'page-header'}>
          <div className={'container'}>
            <PageHeader>
              <h1 className={'mt-5 pt-5'}>{this.state.data.title}</h1>
            </PageHeader>

            <div className={'d-none d-lg-inline'}>
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={500}
                delay={'-8s'}
                backgroundColor={'#0887E0'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'8s'}
                right={150}
                widthHeight={500}
                delay={'-10s'}
                backgroundColor={'#93CCFD'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={200}
                widthHeight={300}
                delay={'-13s'}
                backgroundColor={'#50DCF6'}
                animations={{
                  WebkitAnimation: 'move-up-right 15s linear infinite',
                  MozAnimation: 'move-up-right 15s linear infinite',
                  msAnimation: 'move-up-right 15s linear infinite',
                  OAnimation: 'move-up-right 15s linear infinite',
                  animation: 'move-up-right 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={200}
                delay={'-3s'}
                backgroundColor={'#4097F8'}
                animations={{
                  WebkitAnimation: 'move-up-left 15s linear infinite',
                  MozAnimation: 'move-up-left 15s linear infinite',
                  msAnimation: 'move-up-left 15s linear infinite',
                  OAnimation: 'move-up-left 15s linear infinite',
                  animation: 'move-up-left 15s linear infinite'
                }}
              />
            </div>
            <div className={'d-lg-none'}>
              <span className={'homepage topRight blue-15 twoThirds'} />
            </div>
          </div>
        </section>
        <span
          className={
            'homepage left blue-15 clockwise-8 topHalf d-none d-md-inline'
          }
        />
        <div className="container">
          <div className="row justify-content-center pt-0">
            {this.state.data.main_image !== null &&
              this.state.data.main_image.length !== 0 && (
                <div className={'col-12 col-md-8 mb-5'}>
                  <img
                    src={this.state.data.main_image[0].secure_url}
                    className={'img-fluid'}
                    alt={`Brighton School of Business and Management | ${this.state.data.title}`}
                  />
                </div>
              )}
            <div className="col-12 col-md-8">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${this.state.data.content}`
                }}
              />
              <div className="row text-center">
                <div className="col-3 d-inline-block">
                  {/*<img*/}
                  {/*src="/images/icons/newsMsg.svg"*/}
                  {/*className="d-inline"*/}
                  {/*alt="3 comments"*/}
                  {/*/>{' '}*/}
                  {/*<Disqus.CommentCount*/}
                  {/*shortname={disqusShortname}*/}
                  {/*config={disqusConfig}*/}
                  {/*/>*/}
                </div>
              </div>
              <div className={'row text-center spacer-bottom-0 pl-1'}>
                <div className={'col-12 mt-5'}>
                  {/*<Disqus.DiscussionEmbed*/}
                  {/*shortname={disqusShortname}*/}
                  {/*config={disqusConfig}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <ScriptTag isHydrating={true} type="text/javascript" src="https://bot.leadoo.com/bot/inpage.js?code=ouQugl9y#seamless," />
        </div> */}
        {false && (
          <GreyCircleBottom>
            <div className="container pt-5">
              <div className="row pt-5 justify-content-center text-center">
                <div className="col-12 spacer-150">
                  <h4>Subscribe</h4>
                </div>
                <div className="col-12 col-md-6 spacer-50">
                  <p>
                    Free (and awesome) emails every month, and we promise never
                    to give your details to anyone else.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-6 spacer-75">
                  {/*// Begin Mailchimp Signup Form*/}
                  {/*<link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />*/}
                  <div
                    id="mc_embed_signup"
                    style={{
                      clear: 'left',
                      font: '14px Helvetica,Arial,sans-serif',
                      width: '100%'
                    }}>
                    <form
                      action="https://gbscorporate.us20.list-manage.com/subscribe/post?u=83dedf655d85b38f7cfdc3184&amp;id=d2ec9efda5"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      target="_blank"
                      noValidate>
                      <div id="mc_embed_signup_scroll">
                        <input
                          type="email"
                          name="EMAIL"
                          className="email"
                          id="mce-EMAIL"
                          placeholder="email address"
                          required
                        />

                        <div
                          style={{
                            position: 'absolute',
                            left: -5000
                          }}
                          aria-hidden="true">
                          <input
                            type="text"
                            name="b_83dedf655d85b38f7cfdc3184_d2ec9efda5"
                            tabIndex="-1"
                            value=""
                          />
                        </div>
                        <Button
                          width={100}
                          style={{ display: 'inline' }}
                          height={60}
                          btnColor="rgba(77, 199, 117, 1)"
                          boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                          textColor="white"
                          fontSize={14}>
                          SIGN UP
                        </Button>
                      </div>
                    </form>
                  </div>

                  {/*<Form onSubmit={props.handleSubmit((values) => this.submit(values))}>*/}
                  {/*  <Field*/}
                  {/*    name="email"*/}
                  {/*    type="email"*/}
                  {/*    defaultValue={""}*/}
                  {/*    placeholder={'Your Email Address'}*/}
                  {/*    component={RenderField}*/}
                  {/*    validate={validations.required}*/}
                  {/*  />*/}
                  {/*  <Button*/}
                  {/*    width={100}*/}
                  {/*    style={{ display: 'inline' }}*/}
                  {/*    height={60}*/}
                  {/*    btnColor="rgba(77, 199, 117, 1)"*/}
                  {/*    boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"*/}
                  {/*    textColor="white"*/}
                  {/*    fontSize={14}>*/}
                  {/*    SIGN UP*/}
                  {/*  </Button>*/}
                  {/*</Form>*/}
                </div>
              </div>
            </div>
          </GreyCircleBottom>
        )}
      </Page>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'subForm'
  })(NewsList)
);
