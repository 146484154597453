import React from 'react';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import GreyCircleBottom from '../../components/Circles/greyBottomCircle';
import QandA from '../../components/QandA';
import Meta from '../../components/Meta';
import './index.scss';
import QandAHomepage from '../../components/QandAHomepage';

class FeesAndFunding extends React.Component {
  render() {
    const { props } = this;
    return (
      <Page classnames="fees-page">
        <Meta
          title="Fees and Funding"
          description="Brighton School of Business and Management Ltd, Fees and Funding"
          type={'website'}
        />
        <PageHeader>
          <h1>Interest free credit available</h1>
          <img
            src="/images/circles/topRightBlue.svg"
            height={450}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}
          />
        </PageHeader>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <p>
                Brighton School of Business and Management Ltd, in partnership
                with Deko, are able to offer you interest free loans as an
                alternative method of payment for all our courses and
                qualifications.
              </p>
              <p>
                The cost of education has risen sharply over the last few years.
                It is understandable that due to individual circumstances not
                everybody has the ability to pay for a course in full up front.
                We can now offer our learners the chance to spread the cost with
                a 0% loan which will open the door to many new learners who, due
                to financial constraints, have been unable to even consider
                further education.
              </p>
              <p>
                Loans available for UK students (subject to status) – minimum
                10% deposit of complete fee then 0% APR over 6, 10 or 12 months.
              </p>
              <p>To be eligible for a loan, you must be:</p>
              <ul>
                <li>Over 18 years old</li>
                <li>Earn over £12,000/year</li>
                <li>Have a good credit history</li>
                <li>
                  A permanent UK resident with 3 years of UK address history
                </li>
              </ul>
              <p>
                Monthly repayments for the loan start a month after your course
                start date and are taken by Direct Debit.
              </p>
              <h4
                className="mt-5 mb-4"
                style={{ fontSize: 32, textAlign: 'left' }}>
                Loan Example
              </h4>
              <ul>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>Cost of Course:</div>{' '}
                    <div className={'col'}>£1559.60</div>
                  </div>
                </li>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>
                      10% Deposit Payment of:
                    </div>{' '}
                    <div className={'col'}>£155.96</div>
                  </div>
                </li>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>
                      12 Monthly Payments of:
                    </div>{' '}
                    <div className={'col'}>£116.97</div>
                  </div>
                </li>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>Total Repayments:</div>{' '}
                    <div className={'col'}>£1559.60</div>
                  </div>
                </li>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>
                      Duration of Agreement:
                    </div>{' '}
                    <div className={'col'}>12 Months</div>
                  </div>
                </li>
                <li>
                  <div className={'row'}>
                    <div className={'col-8 col-lg-6'}>Rate of Interest:</div>{' '}
                    <div className={'col'}>0%</div>
                  </div>
                </li>
              </ul>
              <h4
                className="mt-5 mb-4"
                style={{ fontSize: 32, textAlign: 'left' }}>
                How to apply for an interest free loan
              </h4>
              <ul>
                <li>Complete and submit an application</li>
                <li>
                  Once you have received an offer to study the course, you can
                  opt to pay for the course with the Deko loan option by
                  choosing this option on the Choice of Payment form, which will
                  be emailed to you
                </li>
                <li>
                  You are then sent an email confirming the loan criteria, as
                  above
                </li>
                <li>
                  On receipt of confirmation – we will request the following
                  information:
                </li>
                <ul>
                  <li>Loan duration (6,10 or 12 months)</li>
                  <li>Deposit Amount (10% to 50%)</li>
                  <li>Mobile Phone Number</li>
                </ul>

                <li>
                  This loan information is entered into the Deko website and a
                  link to apply for the loan will be sent directly to you
                </li>
                <li>
                  You will then be notified by email if you have been accepted
                  or declined
                </li>
                <li>
                  If the loan is declined we will send an email with alternative
                  payment options
                </li>
                <li>
                  If the loan is accepted, you have 30 days to complete the
                  deposit payment. If payment is not made within this time then
                  you must re-apply
                </li>
                <li>
                  On your enrolment date, you will be sent a Confirmation of
                  Enrolment form to confirm that you have started the course –
                  you must complete and return this to us. We then upload the
                  document to the Deko website and the funds are transferred to
                  us within 3 to 5 days
                </li>
              </ul>
              <h4
                className="mt-5 mb-4"
                style={{ fontSize: 32, textAlign: 'left' }}>
                About Deko
              </h4>
              <p>
                Founded in 2008, Deko is the UK’s fastest growing provider of
                point of sale consumer finance. Deko is authorised and regulated
                by the Financial Conduct Authority (firm reference number
                728646) and more information can be found at
                <a href="https://www.dekopay.com/support/" target="_blank">
                  {' '}
                  https://www.dekopay.com/support/
                </a>
              </p>
            </div>
          </div>
        </div>
        <GreyCircleBottom>
          <QandAHomepage dark />
        </GreyCircleBottom>
      </Page>
    );
  }
}

export default FeesAndFunding;
