import React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/Page/PageContent';
import PageBlock from '../../components/Page/PageBlock';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import Meta from '../../components/Meta';
import './index.scss';
import Bubble from '../../components/Bubble';
import QandA from '../../components/QandA';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import {
  handleCourseCategory,
  handleCourseLevel,
  getCourses,
  getCoursesInCategory,
  getLevels,
  UPDATE_COURSE_LEVEL,
  getCategorys
} from '../../modules/courses';

class BAHons extends React.Component {
  state = {
    courseLevel: null,
    courseCategory: null,
    courses: null,
    script_url: ''
  };

  componentWillMount() {
    //this.props.dispatch(getCategorys());
  }

  componentDidMount() {
    this.props.dispatch(getCategorys());
  }

  render() {
    const baHons =
      this.props.courseCategorys &&
      this.props.courseCategorys.find(cat => cat.slug === 'ba-hons-top-up');

    return (
      // <Page classnames="bahons-page">
      <Page classnames="courses-page">
        <Meta
          type={'website'}
          title="Business Management Top Up Degree | Distance Learning BA Hons Course Online | UK"
          description="We are able to offer business management top up degree courses in conjunction with Arden University across a number of business disciplines. View online and enrol today."
        />
        <section className={'page-header'}>
          <div className={'container'}>
            <PageHeader>
              <div className={'row d-flex justify-content-center'}>
                <div className={'col-12 col-md-12'}>
                  <h1> Business Management Top Up Degree - Online</h1>
                  <h2>
                    We are pleased to announce that we can now refer you through
                    Arden University who can offer a BA Hons (Top-Up) in the
                    following Business Subjects.
                  </h2>
                  <img
                    src={'/images/courses/arden.png'}
                    style={{ width: 125, marginLeft: -10, marginBottom: 50 }}
                  />
                </div>
              </div>
            </PageHeader>
            <div className={'d-none d-lg-inline'}>
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={500}
                delay={'-8s'}
                backgroundColor={'#0887E0'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'8s'}
                right={150}
                widthHeight={500}
                delay={'-10s'}
                backgroundColor={'#93CCFD'}
                animations={{
                  WebkitAnimation: 'move-up 15s linear infinite',
                  MozAnimation: 'move-up 15s linear infinite',
                  msAnimation: 'move-up 15s linear infinite',
                  OAnimation: 'move-up 15s linear infinite',
                  animation: 'move-up 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={200}
                widthHeight={300}
                delay={'-13s'}
                backgroundColor={'#50DCF6'}
                animations={{
                  WebkitAnimation: 'move-up-right 15s linear infinite',
                  MozAnimation: 'move-up-right 15s linear infinite',
                  msAnimation: 'move-up-right 15s linear infinite',
                  OAnimation: 'move-up-right 15s linear infinite',
                  animation: 'move-up-right 15s linear infinite'
                }}
              />
              <Bubble
                speed={'17s'}
                right={100}
                widthHeight={200}
                delay={'-3s'}
                backgroundColor={'#4097F8'}
                animations={{
                  WebkitAnimation: 'move-up-left 15s linear infinite',
                  MozAnimation: 'move-up-left 15s linear infinite',
                  msAnimation: 'move-up-left 15s linear infinite',
                  OAnimation: 'move-up-left 15s linear infinite',
                  animation: 'move-up-left 15s linear infinite'
                }}
              />
            </div>
            <div className={'d-lg-none'}>
              <span className={'homepage topRight blue-15 twoThirds'} />
            </div>
          </div>
        </section>
        <PageContent>
          <div className={'row d-flex pl-2'}>
            <div className={'col-12 col-md-12'}>
              <PageBlock>
                <p className="course-link">
                  <span>
                    <i className="fas fa-eye" />
                  </span>{' '}
                  BA (Hons) Business (Finance) Top-Up
                </p>
                <p className="course-link">
                  <span>
                    <i className="fas fa-eye" />
                  </span>{' '}
                  BA (Hons) Business (HRM) Top-Up
                </p>
                <p className="course-link">
                  <span>
                    <i className="fas fa-eye" />
                  </span>{' '}
                  BA (Hons) Business (Marketing) Top-Up
                </p>
                <p className="course-link" style={{ marginBottom: 35 }}>
                  <span>
                    <i className="fas fa-eye" />
                  </span>{' '}
                  BA (Hons) Business Top-Up
                </p>
              </PageBlock>
              {/* <PageBlock>
                <h4>About the course</h4>
                <p>
                  Innovative professionals who are equipped with the skill set
                  required to identify opportunities and make bold decisions
                  that will differentiate their organisation from competitors,
                  drive today’s dynamic business environment.
                </p>
                <p>
                  The BA (Hons) in the above Business Top-Up programmes are
                  designed to encourage the development of these essential
                  skills. You will learn how to critically evaluate key business
                  models and apply them to a real-life problem at a strategic
                  level by conducting your very own research project. The
                  subjects we offer in our business management top-up degree
                  courses have been chosen to provide a wide range of options,
                  including human resource management. The courses have been
                  designed to improve your critical and strategic management
                  skills helping you to further your career.
                </p>
              </PageBlock> */}
              <PageBlock>
                <h4>Degree benefits</h4>
                <ul>
                  <li>
                    The degree top up course has a good balance between academic
                    and world of work. It delivers a good grounding in
                    management theory, whilst also preparing participants for a
                    successful career.
                  </li>
                  <li>
                    A degree qualification can lead to increased earning
                    potential, a wider range of opportunities, and a more
                    rewarding career.
                  </li>
                  <li>
                    One of the key benefits is that a business management top-up
                    degree course through Brighton SBM is considerably cheaper
                    than the tuition fees and additional costs associated with
                    doing an honours degree at university.
                  </li>
                </ul>
              </PageBlock>
              <PageBlock>
                <h4>Accreditation Body</h4>
                <p>Enrolment and Accreditation is with Arden University</p>
              </PageBlock>

              <PageBlock>
                <p>Entry Requirements</p>
                <ul>
                  <li>
                    a HND in Business (or equivalent) – providing your learning
                    is consistent with the Level 5 functional areas of business
                    (Finance, Marketing, HRM and Operations)
                  </li>
                </ul>
                <p>
                  For students whose prior learning was not taught in English.
                </p>
                <ul>
                  <li>
                    IELTS 6.0 or equivalent (no less than 5.5 in any element)
                  </li>
                </ul>
              </PageBlock>
              <PageBlock>
                <p style={{ fontWeight: '500', marginTop: 10 }}>
                  Please contact student services for more information
                </p>
              </PageBlock>
            </div>
          </div>
          <section className="course-list container"></section>
        </PageContent>
        {baHons && baHons.category_faqs && baHons.category_faqs.length > 0 && (
          <>
            <div className="page-section q-and-a">
              <div className="container q-a">
                <div className="row spacer-100">
                  {baHons &&
                    baHons.category_faqs &&
                    baHons.category_faqs.length > 0 && (
                      <QandA data={baHons.category_faqs} />
                    )}
                </div>
              </div>

              <div className="container button-container">
                <a href={'/faq'}>
                  <Button
                    width={190}
                    height={60}
                    btnColor="rgba(77, 199, 117, 1)"
                    boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
                    textColor="white"
                    fontSize={14}>
                    SEE MORE
                  </Button>
                </a>

                <div className="col text-center spacer-100" data-aos="fade-up">
                  <a
                    className={`cant-find text-white`}
                    href="/contact"
                    style={{ fontSize: 24 }}>
                    Can’t find what you are looking for?{' '}
                    <span style={{ fontWeight: 700 }}>Get in Touch</span>
                  </a>
                  <p
                    className={`cant-find text-white`}
                    style={{ fontSize: 24 }}>
                    Or give us a call on{' '}
                    <span style={{ fontWeight: 700 }}>+44 (0)1273 704 463</span>
                  </p>
                </div>
              </div>
              <img
                src="/images/circles/test.svg"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  zIndex: -1
                }}
              />
            </div>
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    state: state,
    isLoading: state.courses.isLoading,
    courseCategory: state.courses.courseCategory,
    courses: state.courses.coursesToShow,
    courseLevels: state.courses.courseLevels,
    courseCategorys: state.courses.courseCategorys
  };
};

export default connect(mapStateToProps)(BAHons);
