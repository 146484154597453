import React from 'react';
import { Label, Alert } from 'reactstrap';

const RenderAllErrors = fields => {
  console.log(fields);
  console.log('done');

  const upperCaseArray = input => {
    let result =
      input.charAt(0).toUpperCase() +
      input
        .slice(1)
        .replace(/([A-Z]+)/g, ',$1')
        .replace(/^,/, ',')
        .replace('_', ' ')
        .replace('-', ' ');

    result = result.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

    return result;
  };

  return (
    <Alert color="danger" className="form-errors">
      These fields must be filled in
      <ul>
        {Object.keys(fields).map(key => {
          let error = null;

          if (
            fields[key].meta &&
            typeof fields[key].meta.error !== 'undefined'
          ) {
            error = fields[key].meta.error;
          }

          let keyClean = upperCaseArray(key);
          return error ? (
            <li key={key}>
              {keyClean}: {error}
            </li>
          ) : undefined;
        })}
      </ul>
    </Alert>
  );
};

export default RenderAllErrors;
