import React from 'react';
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';
import { reduxForm, formValueSelector, Form, Field } from 'redux-form';

import { courseApply, courseEnquire } from '../../../modules/apply';

import Http from '../../../constants/Http';
import Loading from '../../../components/Loading';

import './index.scss';
import * as validations from '../../../Validation';
import RenderSelect from '../../../components/Fields/renderSelect';

const sourceOptions = [
  {
    value: 10,
    label: 'CoursesOnline'
  },
  {
    value: 6,
    label: 'Google'
  },
  {
    value: 3,
    label: 'Facebook'
  },
  {
    value: 1,
    label: 'FindCourses.co.uk'
  },
  {
    value: 2,
    label: 'The Independent'
  },
  {
    value: 4,
    label: 'Twitter'
  },
  {
    value: 5,
    label: 'LinkedIn'
  },
  {
    value: 7,
    label: 'Friend referral'
  },
  {
    value: 8,
    label: 'GBS blog'
  },
  {
    value: 9,
    label: 'Other'
  }
];

const renderTextArea = field => (
  <div className={'input-row'}>
    <textarea
      {...field.input}
      className={field.meta.touched && field.meta.error && 'error-input'}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

const renderField = field => (
  <div className="input-row">
    <input
      {...field.input}
      type="text"
      placeholder={field.placeholder}
      className={field.meta.touched && field.meta.error && 'error-input'}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

class Apply extends React.Component {
  submit = values => {
    this.props.dispatch(courseEnquire(values, this.props.courseSlug));
  };
  
  render() {
    const { handleSubmit, error, pristine, submitting } = this.props;
    
    if (this.props.hasSubmitted) {
      return (
        <div
          style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}
          className="apply-submission-status">
          <i className="fas fa-checkmark-circle" />
          <div className="title">All Done!</div>
          <p>
            Your enquiry has been received and we will be in touch shortly.
          </p>
          <div className="close" onClick={() => this.props.toggle()}>
            Close Window
          </div>
          <img
            src="/images/circles/topRightBlue.svg"
            height={250}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
          />
          <img
            src="/images/circles/leftLightBlue.svg"
            height={300}
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
          />
        </div>
      );
    }

    if (this.props.hasError) {
      return (
        <div
          style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}
          className="apply-submission-status">
          <i className="fas fa-times-circle" />
          <div className="title">Something went wrong!</div>
          <p>There has been a problem submitting your enquiry.</p>
          <p>
            Please call a member of our team on +44 (0)1273 704 463 for
            assistance.
          </p>
          <div className="close" onClick={() => this.props.toggle()}>
            Close Window
          </div>
          <img
            src="/images/circles/topRightBlue.svg"
            height={250}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
          />
          <img
            src="/images/circles/leftLightBlue.svg"
            height={300}
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
          />
        </div>
      );
    }
    
    return (
      <div className="apply-wrapper applyWrapper">
        <div className="wrapper">
          <div className="container" />
          <div className="form-header">
            <div className="apply-tag">Enquiry Form</div>
            <div className="course-title">{this.props.courseName}</div>
          </div>
          <Form onSubmit={handleSubmit((values) => this.submit(values))}>
            <div className="form-group">
              <div
                className={
                  'row justify-content-center align-items-center'
                }>
                <div className="col-12 spacer-50">
                  <Field
                    name="first_name"
                    component={renderField}
                    type="text"
                    placeholder="First Name"
                    validate={validations.required}
                  />
                </div>
              </div>
              <div
                className={
                  'row justify-content-center align-items-center'
                }>
                <div className="col-12 spacer-25">
                  <Field
                    name="last_name"
                    component={renderField}
                    type="text"
                    placeholder="Last Name"
                    validate={validations.required}
                  />
                </div>
              </div>
              <div
                className={
                  'row justify-content-center align-items-center'
                }>
                <div className="col-12 spacer-25">
                  <Field
                    name="phone"
                    component={renderField}
                    type="phone"
                    placeholder="Phone Number"
                    validate={validations.required}
                  />
                </div>
              </div>
              <div
                className={
                  'row justify-content-center align-items-center'
                }>
                <div className="col-12 spacer-25">
                  <Field
                    name="email"
                    component={renderField}
                    type="email"
                    placeholder="Email"
                    validate={[
                      validations.email,
                      validations.required
                    ]}
                  />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className={"col-12 spacer-25"}>
                  <Field
                    name={'award_type'}
                    placeholder={"Select the award level"}
                    component={RenderSelect}
                    clearable={false}
                    options={this.props.awardTypes}
                    defaultValue={this.props.awardTypes.length === 1 ? this.props.awardTypes[0] : null}
                    validate={validations.required}
                  />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className={"col-12 spacer-25"}>
                  <Field
                    name="source"
                    placeholder="Where did you hear about us?"
                    component={RenderSelect}
                    options={sourceOptions}
                    validate={validations.required}
                  />
                </div>
              </div>
              <div
                className={
                  'row justify-content-center align-items-center'
                }>
                <div className="col-12 spacer-25">
                  <Field
                    name="additional_info"
                    component={renderTextArea}
                    type="textarea"
                    validate={validations.required}
                  />
                </div>
              </div>
              {error && (
                <strong className="text-danger">{error}</strong>
              )}
              <div className={'col-12 justify-content-center align-items-center d-flex'}>
                <button
                  style={{
                    width: 280,
                    height: 43,
                    letterSpacing: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 3,
                    border: 'none',
                    backgroundColor: 'rgba(77, 199, 117, 1)',
                    fontSize: 14,
                    boxShadow: '0 20px 24px 0 rgba(77,199,117,0.20)',
                    color: 'white'
                  }}
                  type="submit"
                  disabled={submitting}>
                  {submitting ? 'Submitting...' : 'SUBMIT'}
                </button>
              </div>
            </div>
          </Form>
        </div>
        <i
          className="fas fa-times"
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
            fontSize: 18,
            zIndex: 9999,
            cursor: 'pointer',
          }}
          onClick={() => this.props.toggle()}
        />
        
        <img
          src="/images/circles/topRightBlue.svg"
          height={250}
          className="topRight"
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 500 }}
        />
        <img
          src="/images/circles/leftLightBlue.svg"
          height={300}
          className="topLeft"
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 500 }}
        />
      </div>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Apply)
);
