import React from 'react';

import './index.scss';

const RenderField = ({
  input,
  noLabel,
  placeholder,
  label,
  type,
  min,
  max,
  onChange,
  meta: { touched, error }
}) => (
  console.log(input),
  <div className="input-row">
    {!noLabel && <label>{label}</label>}
    <div>
      <input min={min} max={max} {...input} value={input.value} placeholder={placeholder} type={type} />
      {touched && error && <div className="error" style={ type === "email" ? {
        width: '100%',
        position: 'absolute',
        left: 15,
        top: 60
      } : {} }>{error}</div>}
    </div>
  </div>
);

export default RenderField;
