import React from 'react';
import { reduxForm, Form, Field, Fields } from 'redux-form';
import DatePicker from 'react-datepicker';
import { Label, Alert } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../../../components/Stuff/Button';
import renderField from '../../../../components/Fields/renderField';
import RenderSelect from '../../../../components/Fields/renderSelect';
import * as validations from '../../../../Validation';

const sexOptions = [
  {
    value: 1,
    label: 'Male'
  },
  {
    value: 2,
    label: 'Female'
  },
  {
    value: 3,
    label: 'Other'
  }
];

const disabilityStatuses = [
  { value: 1, label: 'Not Disabled' },
  { value: 2, label: 'Disabled But Not Registered' },
  { value: 3, label: 'Registered Disabled' }
];

const DatePickerField = props => {
  return (
    <DatePicker
      selected={props.input.value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat={'DD / MM / YYYY'}
      onChange={props.input.onChange}
    />
  );
};

const Page1 = props => {
  const fieldNames = [
    'firstName',
    'lastName',
    'dob',
    'gender',
    'disability_status',
    'award_type'
  ];

  const renderAllErrors = fields => 
    (
   
    <ul>
      {Object.keys(fields).map(key => {
        const {
          meta: { touched, error }
        } = fields[key];
        return touched && error ? (
          <li key={key}>
            {key}: {error}
          </li>
        ) : undefined;
      })}
    </ul>
  );
  

  const { handleSubmit } = props;
  return (
    <>
      <Fields names={fieldNames} component={renderAllErrors} />
      <Form onSubmit={handleSubmit}>
        <div className="form-group">
          <Field
            name="firstName"
            type="text"
            autofocus
            component={renderField}
            label="First Name *"
            validate={validations.required}
          />
        </div>
        <div className="form-group">
          <Field
            name="lastName"
            type="text"
            component={renderField}
            label="Last Name *"
            validate={validations.required}
          />
        </div>
        <div className="form-group date-of-birth-custom">
          <Label>Date of Birth</Label>
          <Field
            name="dob"
            label="Date of Birth *"
            component={DatePickerField}
          />
        </div>
        <div className={'form-group row'}>
          <div className={'col-6'}>
            <Label>Gender</Label>
            <Field
              name="gender"
              label="Gender *"
              component={RenderSelect}
              options={sexOptions}
            />
          </div>
          <div className={'col-6'}>
            <Label>Disability</Label>
            <Field
              name="disability_status"
              label={'Disability *'}
              component={RenderSelect}
              options={disabilityStatuses}
              validate={validations.required}
            />
          </div>
        </div>
        <div className={'form-group'}>
          <Label>Select the award level</Label>
          <Field
            name="award_type"
            component={RenderSelect}
            clearable={false}
            options={props.awardTypes}
            defaultValue={
              props.awardTypes.length === 1 ? props.awardTypes[0] : null
            }
          />
        </div>
        <div className="form-group text-center">
          <Button
            width={'100%'}
            height={60}
            style={{ width: '100%' }}
            btnColor="rgba(77, 199, 117, 1)"
            boxShadow="0 20px 24px 0 rgba(77,199,117,0.20)"
            textColor="white"
            fontSize={14}>
            NEXT
          </Button>
        </div>
        {!props.valid && props.submitFailed && (
          <Alert color="danger" className="form-errors">
            There are errors in your form. Please check you have completed all
            required fields.
          </Alert>
        )}
      </Form>
    </>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Page1);
