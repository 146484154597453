import React from 'react';
import { getCourses } from '../../modules/courses';
import Page from '../../components/Page';
import PageContent from '../../components/Page/PageContent';
import PageBlock from '../../components/Page/PageBlock';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import Meta from '../../components/Meta';
import './index.scss';
import Bubble from '../../components/Bubble';

class CharteredManager extends React.Component {
  render() {
    return (
      <Page classnames="chartered-page">
        <Meta
          type={'website'}
          title="CMI Management Qualifications | Chartered Manager Course"
          description="We are pleased to announce our partnership with the Chartered Management Institute (CMI) to qualify professionals."
        />
        <PageHeader>
          <div className={'row d-flex pl-2 justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <img
                src={'/images/courses/CMI_assessment_center.svg'}
                style={{ width: 170 }}
              />
              <h1>Chartered Manager</h1>
            </div>
          </div>
          {/*<img*/}
          {/*src="/images/circles/topRightBlue.svg"*/}
          {/*className="main"*/}
          {/*height={500}*/}
          {/*style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}*/}
          {/*/>*/}
          <div className={'d-none d-lg-inline'}>
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={500}
              delay={'-8s'}
              backgroundColor={'#0887E0'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'8s'}
              right={150}
              widthHeight={500}
              delay={'-10s'}
              backgroundColor={'#93CCFD'}
              animations={{
                WebkitAnimation: 'move-up 15s linear infinite',
                MozAnimation: 'move-up 15s linear infinite',
                msAnimation: 'move-up 15s linear infinite',
                OAnimation: 'move-up 15s linear infinite',
                animation: 'move-up 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={200}
              widthHeight={300}
              delay={'-13s'}
              backgroundColor={'#50DCF6'}
              animations={{
                WebkitAnimation: 'move-up-right 15s linear infinite',
                MozAnimation: 'move-up-right 15s linear infinite',
                msAnimation: 'move-up-right 15s linear infinite',
                OAnimation: 'move-up-right 15s linear infinite',
                animation: 'move-up-right 15s linear infinite'
              }}
            />
            <Bubble
              speed={'17s'}
              right={100}
              widthHeight={200}
              delay={'-3s'}
              backgroundColor={'#4097F8'}
              animations={{
                WebkitAnimation: 'move-up-left 15s linear infinite',
                MozAnimation: 'move-up-left 15s linear infinite',
                msAnimation: 'move-up-left 15s linear infinite',
                OAnimation: 'move-up-left 15s linear infinite',
                animation: 'move-up-left 15s linear infinite'
              }}
            />
          </div>
          <div className={'d-lg-none'}>
            <span className={'homepage topRight blue-15 twoThirds'} />
          </div>
        </PageHeader>

        <PageContent>
          <div className={'row d-flex pl-2'}>
            <div className={'col-12 col-md-8 offset-md-1'}>
              <PageBlock>
                <h3 className="page-summary">
                  Brighton School of Business and Management are pleased to
                  announce our partnership with the Chartered Management
                  Institute (CMI) to qualify professionals.
                </h3>
                <p>Demonstrate your ability to manage and lead.</p>
                <p>
                  As a Chartered Manager Assessment Centre for the CMI, BSBM are
                  now able to provide learners with the option of becoming a
                  Chartered Manager through the exemption route, amongst the
                  range of leadership and management courses.
                </p>
              </PageBlock>
              <PageBlock>
                <p>
                  Becoming a Chartered Manager is an excellent way of providing
                  an independent endorsement of your ability to manage and lead.
                  It demonstrates that you have a specific range of transferable
                  skills that prove your managerial competence and being a
                  Chartered Manager is the highest status that can be achieved
                  in the management profession.
                </p>
              </PageBlock>
              <PageBlock>
                <h4>Who is it for?</h4>
                <p>
                  The exemption route is a quick and efficient way of becoming a
                  Chartered Manager and is for those who have completed the CMI
                  Level 5 Diploma in Management and Leadership (or above – Level
                  6 or Level 7 Diploma) and have a minimum of 3 years managerial
                  experience.
                </p>
              </PageBlock>
              <PageBlock>
                <h4>How is it assessed?</h4>
                <p>
                  The assessment is in the form of a written submission which
                  details the learning gained, the improvement in your
                  management practice and the positive measurable contribution
                  you have made to your organisation. The Chartered Management
                  Institute will then validate your assessment and award the
                  Chartered Manager status if your submission is approved.
                </p>
              </PageBlock>
              <PageBlock>
                <h4>Benefits of the exemption route</h4>
                <ul>
                  <li>
                    Convenient, timely and an efficient route to becoming
                    Chartered
                  </li>
                  <li>Cost-effective</li>
                  <li>
                    Recognition of the competence demonstrated through gaining
                    the CMI Level 5 Diploma (or higher)
                  </li>
                </ul>
              </PageBlock>
              <PageBlock>
                <h4>When can you become a Chartered Manager?</h4>
                <p>
                  As soon as you have completed, and been certificated for your
                  chosen course, you can then apply to start the process to
                  become a Chartered Manager.
                </p>
                <p>
                  Alternatively, we now offer a bundle package where you can
                  apply for one of the courses below which will include
                  enrolment onto the Chartered Manager Award as soon as you have
                  been certificated. Please see available bundle courses and
                  prices via the links below:
                </p>
                <p>
                  <a
                    href={
                      '/courses/management-leadership/cmi-level-5-in-management-and-leadership'
                    }>
                    CMI Level 5 Diploma/Extended Diploma in Management and
                    Leadership Bundle
                  </a>
                </p>
                <p>
                  <a
                    href={
                      '/courses/management-leadership/cmi-level-6-in-professional-management-and-leadership-practice'
                    }>
                    CMI Level 6 Diploma in Management and Leadership Practice
                    Bundle
                  </a>
                </p>
                <p>
                  <a
                    href={
                      '/courses/management-leadership/cmi-level-7-in-strategic-management-and-leadership-practice'
                    }>
                    CMI Level 7 Diploma in Strategic Management and Leadership
                    Practice Bundle
                  </a>
                </p>
              </PageBlock>
              <PageBlock>
                <h4>Fees (Standalone Chartered Manager Award)</h4>
                <p>
                  <b>
                    The cost of the exemption route is a one off assessment fee
                    which includes a 12 month membership*.
                  </b>
                </p>
                <p>
                  Exemption Route - £380 inc VAT (includes full membership for
                  12 months
                </p>
                <p>
                  *Please note that you will need to renew your membership every
                  12 months and also keep an ongoing, up to date CPD record.
                </p>
              </PageBlock>
            </div>
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default CharteredManager;
