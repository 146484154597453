import React, { PropTypes } from 'react';

import { Card } from 'reactstrap';

import styles from './CourseBucket.module.scss';

const CourseBucket = props => (
  // <div className="col-12 mb-3 col-md-6 col-lg-4">
  <div style={{ width: '90%', margin: '18px' }}>
    <a href={props.link} style={{ textDecoration: 'none' }}>
      <Card className={styles.courseBucket}>
        <img
          src={props.image}
          height={props.imageHeight}
          className={styles.bucketImage}
          alt={props.name}
        />
        <a href={props.link} style={{ textDecoration: 'none' }}>
          <h4 className={styles.bucketTitle}>{props.name}</h4>
        </a>
      </Card>
    </a>
  </div>
);

export default CourseBucket;
