import React from 'react';
import Page from '../../components/Page';
import AboutCircle from '../../components/Circles/aboutCircle';
import PageHeader from '../../components/PageHeader';
import Meta from '../../components/Meta';

import './Privacy.scss';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Page classnames="privacy-page">
        <Meta title="Privacy Policy" type={"website"} />
        <PageHeader>
          <h1>Privacy Policy</h1>
        </PageHeader>

        <img
          src="/images/circles/topRightBlue.svg"
          height={500}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}
        />
        <AboutCircle>
          <div className={'container h-100 spacer-bottom-150'}>
            <div
              className={
                'row h-100 justify-content-center align-content-center'
              }>
              <div className={'col-12 col-md-8 spacer-100 pt-lg-5'}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque cursus vulputate sapien. Pellentesque dui arcu,
                  iaculis quis turpis non, rutrum scelerisque odio. In hac
                  habitasse platea dictumst. Nam finibus eget lorem id sodales.
                  Sed elementum libero a arcu sodales, vitae placerat sem
                  lobortis. Nulla tristique purus vitae turpis congue imperdiet.
                  In quis dolor sed turpis pulvinar lacinia. Maecenas venenatis
                  leo eu dolor facilisis, at tincidunt libero porta.
                </p>

                <p>
                  Vivamus congue diam ex, ut sollicitudin sem fermentum non.
                  Pellentesque vehicula neque vitae nunc bibendum, eget rutrum
                  erat porta. Sed consequat tincidunt mattis. Nam cursus libero
                  eu augue volutpat venenatis. In ornare consectetur rhoncus.
                  Duis faucibus tellus at gravida porta. In sit amet facilisis
                  quam. Cras fringilla dolor semper laoreet porttitor. In et
                  metus vulputate leo bibendum malesuada a quis metus. Sed
                  vulputate consectetur ex a cursus. Nulla semper ante orci, non
                  dictum risus hendrerit ut.
                </p>

                <p>
                  Ut consequat, elit sed ultricies imperdiet, est justo
                  ultricies nunc, vel consequat dui est sit amet arcu. Donec
                  convallis leo et consectetur egestas. Vivamus dolor nunc,
                  varius a elementum vitae, hendrerit eget ex. Class aptent
                  taciti sociosqu ad litora torquent per conubia nostra, per
                  inceptos himenaeos. Etiam ac massa dapibus, placerat nibh et,
                  auctor eros. In sollicitudin, purus nec interdum ornare, diam
                  diam facilisis diam, a vehicula ipsum erat id odio. Duis vel
                  mi eget sem mattis semper a ac eros. Sed imperdiet metus odio,
                  at euismod turpis lobortis id. Pellentesque sed fringilla
                  metus. Cras pharetra neque porttitor, convallis ligula et,
                  rutrum arcu. Phasellus lectus lectus, condimentum accumsan
                  sollicitudin at, placerat sit amet libero. Nullam eu
                  scelerisque mi. Suspendisse mi tellus, pellentesque in
                  interdum pulvinar, feugiat non enim. Aenean semper, lacus sed
                  placerat tincidunt, erat justo fringilla ipsum, placerat
                  pulvinar purus elit id dui. Maecenas in ultrices justo, at
                  mollis lorem. Maecenas id lectus porttitor, sagittis nisi eu,
                  suscipit turpis.
                </p>

                <p>
                  Maecenas faucibus tortor justo, id placerat libero tincidunt
                  at. Phasellus feugiat blandit porta. Duis elit nisl, tempor ut
                  scelerisque a, malesuada et nunc. Vestibulum laoreet
                  pellentesque justo, quis tristique sapien feugiat sed.
                  Suspendisse nec condimentum est. Nulla aliquam neque ut
                  iaculis efficitur. In in congue urna. Donec sed eleifend
                  dolor. Praesent a nibh neque.
                </p>

                <p>
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Donec sodales venenatis
                  auctor. Duis scelerisque condimentum augue, a malesuada orci
                  rutrum congue. Duis elit nulla, ullamcorper id arcu at,
                  egestas sagittis tellus. Vivamus molestie elit purus, ac
                  auctor nibh dictum non. In in vestibulum sapien. Pellentesque
                  vitae lectus ac nulla vestibulum maximus non eu mi. Sed
                  posuere metus ac dolor semper, vitae varius purus interdum.
                  Morbi blandit, felis nec consectetur gravida, ex diam faucibus
                  felis, vel commodo nunc ex nec mauris. Nunc sed posuere nisi.
                  Nunc eros lectus, luctus vel blandit eget, laoreet ac quam.
                  Sed id nunc eget justo lacinia fringilla. Aliquam bibendum
                  odio accumsan luctus molestie. Mauris ac lacus imperdiet,
                  condimentum est eu, ullamcorper eros.
                </p>
              </div>
            </div>
          </div>
        </AboutCircle>
      </Page>
    );
  }
}

export default PrivacyPolicy;
